import React from "react";
import * as S from "../../styles/ezio-styles/AboutPage-styles"

type AboutPageProps = {
    user: { 
        token: string;
        userSettings: {
            currency_symbol: string
        }
     }
    settingsKwhRate: any,
    kwhRates: any
}

type RateObject = {
    nickname: string,
    kwhRate: number,
    dbname?: string,
    fridays?: boolean,
    mondays?: boolean,
    pkid?: number,
    saturdays?: boolean,
    startDayOfMonth?: number,
    startHour?: number,
    startMinute?: number,
    startMonth?: number,
    stopDate?: number,
    stopDayOfMonth?: number,
    stopHour?: number,
    stopMinute?: number,
    stopMonth?: number,
    sundays?: boolean,
    thursdays?: boolean,
    tuesdays?: boolean,
    wednesdays?: boolean,
    startTimestamp?: string,
    stopTimestamp?: string
}

export default function AboutPage({ user, settingsKwhRate, kwhRates, }: AboutPageProps) {
    const currencySymbol = user.userSettings.currency_symbol;

    function createRateDates(rateObj: RateObject) {
        if (rateObj.startMonth == null || rateObj.stopMonth === null || rateObj.startMonth === undefined || rateObj.stopMonth === undefined)
            return "All Selected";
        if (rateObj.startDayOfMonth === null || rateObj.stopDayOfMonth === null || rateObj.startDayOfMonth === undefined || rateObj.stopDayOfMonth === undefined) {
            return `${rateObj.startMonth + " - " + rateObj.stopMonth}`;
        } else {
            return `${humanFriendlyMonth(rateObj.startMonth)} ${(rateObj.startDayOfMonth + 1)} - ${humanFriendlyMonth(rateObj.stopMonth)} ${(rateObj.stopDayOfMonth + 1)}`;
        }
    }

    function createRateDOW(rateObj: any) {
        //   this should be type RateObject - breaks accessing rateObj via string. Need to impliment explicit type casting
        if (rateObj.mondays === null) return "All Selected";
        let selectedDays: Array<string> = [];
        const dowArray: Array<string> = [
            "mondays",
            "tuesdays",
            "wednesdays",
            "thursdays",
            "fridays",
            "saturdays",
            "sundays",
        ];
        dowArray.forEach((d: string) => {
            if (rateObj[d] === true) {
                d = d.charAt(0).toUpperCase() + d.slice(1, 3);
                selectedDays.push(d);
            }
        });
        if (selectedDays.length > 0) {
            return selectedDays.join(', ');
        } else {
            return "All Selected";
        }
    }

    function humanFriendlyMonth(mnth: number) {
        switch (mnth) {
            case 0: return 'January';
            case 1: return 'February';
            case 2: return 'March';
            case 3: return 'April';
            case 4: return 'May';
            case 5: return 'June';
            case 6: return 'July';
            case 7: return 'August';
            case 8: return 'September';
            case 9: return 'October';
            case 10: return 'November';
            case 11: return 'December';
            default: return "-";
        }
    }

    function createRateTOU(rateObj: RateObject) {
        if (rateObj.startHour === null || rateObj.stopHour === null || rateObj.startHour === undefined || rateObj.stopHour === undefined ||
            rateObj.startMinute === null || rateObj.stopMinute === null || rateObj.startMinute === undefined || rateObj.stopMinute === undefined)
            return "All Selected";
        return `${rateObj.startTimestamp} - ${rateObj.stopTimestamp}`;
    }

    return (
        <>
                <S.AboutLayout>
                    <S.AboutTitle>
                        About
                    </S.AboutTitle>
                    <S.AboutCopy>
                        ezIO is designed to help fleet managers, sustainability teams, and facility staff understand their fleet's projected EV charging demand. Users can see where their vehicles are parked for extended parking periods to determine where charging infrastructure will be needed to support fleet electrification. The detailed analytics shows the projected peak charging demand each month and the daily demand curves at each location.
                    </S.AboutCopy>


                    <S.AboutSubtitle>
                        Assumptions
                    </S.AboutSubtitle>

                    <S.AboutTableTitle>
                        Electricity Rates:
                    </S.AboutTableTitle>

                    {kwhRates.length > 0 &&
                        <>
                            <S.AboutTable>
                                <S.AboutTableHead>
                                    <S.AboutTableTr>
                                        <S.AboutTableTh>
                                            Rate Name
                                        </S.AboutTableTh>
                                        <S.AboutTableTh>
                                            Rate
                                        </S.AboutTableTh>
                                        <S.AboutTableTh>
                                            Date Duration
                                        </S.AboutTableTh>
                                        <S.AboutTableTh>
                                            Active Days
                                        </S.AboutTableTh>
                                        <S.AboutTableTh>
                                            Active Times
                                        </S.AboutTableTh>
                                    </S.AboutTableTr>
                                </S.AboutTableHead>
                                <S.AboutTableBody>
                                    {kwhRates.map((r, idx: number) => {
                                        return (
                                            <React.Fragment key={idx}>
                                                <S.AboutTableTr>
                                                    <S.AboutTableTd>
                                                        {r.nickname}
                                                    </S.AboutTableTd>
                                                    <S.AboutTableTd>
                                                        {`${currencySymbol}${r.kwhRate.toFixed(2)}`}
                                                    </S.AboutTableTd>
                                                    <S.AboutTableTd>
                                                        {createRateDates(r)}
                                                    </S.AboutTableTd>
                                                    <S.AboutTableTd>
                                                        {createRateDOW(r)}
                                                    </S.AboutTableTd>
                                                    <S.AboutTableTd>
                                                        {createRateTOU(r)}
                                                    </S.AboutTableTd>
                                                </S.AboutTableTr>
                                            </React.Fragment>
                                        )
                                    })}
                                </S.AboutTableBody>
                            </S.AboutTable>

                            <S.AboutCopy>
                                If none of the above rates apply for a given time period, your fleet's default kWh rate of {currencySymbol}{settingsKwhRate.toFixed(2)} is used.
                            </S.AboutCopy>
                        </>
                    }

                    {kwhRates.length < 1 &&
                        <S.AboutCopy>
                            Your fleet's default kWh rate is {currencySymbol}{settingsKwhRate.toFixed(2)}. If you have a seasonal, time-of-use, or other electricity rate to include, please contact us at <S.EmailLink href="mailto:info@sawatchlabs.com">info@sawatchlabs.com</S.EmailLink>.
                        </S.AboutCopy>
                    }

                    <S.AboutCopy>
                        For additional information, please reach out to the Sawatch team at <S.EmailLink href="mailto:info@sawatchlabs.com">info@sawatchlabs.com</S.EmailLink>.
                    </S.AboutCopy>
                </S.AboutLayout>
        </>
    )
}