import React from 'react';
import HeaderSelection from './HeaderSelection';
import { LinkBack } from './Links';
import { withRouter } from 'react-router-dom';
import CandidatesTableRenderer from './CandidatesTableRenderer'
import { FormatCommas } from './FormatAttributes';
import { conformPostRequest } from './utils/ConformUnits';
import { Roles } from "../core-components/utility-components/UserUtils";


class CandidateSelection extends React.Component {
  constructor(props){
    super(props);
    const user = this.props.user;
    if (user.role >= Roles.PartnerAdmin) {
      const sortedCandidates = props.candidates.sort((a, b) => {
        if(a.ymm < b.ymm) { return -1; }
        if(a.ymm > b.ymm) { return 1; }
        return 0;
      })
      this.state = {
        candidates: sortedCandidates,
      }
      this.selectAllCandidates = this.selectAllCandidates.bind(this);
      this.deselectAllCandidates = this.deselectAllCandidates.bind(this);
      this.clickCandidate = this.clickCandidate.bind(this);
      this.processCandidateSelection = this.processCandidateSelection.bind(this);
    }
  }
  
  runAnalytics = (db) => {
    const url = `${this.props.apiURL}runAnalytics`;
    fetch(url, 
        { method: 'POST', 
          body: JSON.stringify({'dbName': db}),
          headers: {
            'Authorization':`Bearer ${this.props.user.token}`,
            'Content-Type': 'application/json'
          }
        })
      .then(res => res.json())
      .then(() => {
        this.props.getAnalyticsProcessingState(this.props.db,true);
      })
      .catch((err) => {
        console.error(`Request to run analytics failed: ${err}`);
      });
  }

  clickCandidate(pkid) {
    var cands = this.state.candidates;
    cands.map(c => {
      if(c.pkid === pkid){c.is_selected = (!c.is_selected)}
      return c
    })
    this.setState({candidates: cands});
  }

  selectAllCandidates() {
    var cands = this.state.candidates;
    cands.map(c => {return c.is_selected = true;})
    this.setState({candidates: cands});
  }

  deselectAllCandidates() {
    var cands = this.state.candidates;
    cands.map(c => {return c.is_selected = false;})
    this.setState({candidates: cands});
  }

  processCandidateSelection = () => {
    var cands = this.state.candidates
    if(cands.some(c => {return c.is_selected})){
      // Deep copy cands object before modifying values so that state is preserved
      let formattedCands = conformPostRequest(this.props.userSettings, JSON.parse(JSON.stringify(cands)));
      const body = {"dbName": this.props.db.toLowerCase(), "vehicles": formattedCands};
      const url = `${this.props.apiURL}updateCandidates`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Authorization':`Bearer ${this.props.user.token}`,
          'Content-Type': 'application/json'
        }
      })
        .then((res) => res.json())
        .then((data) => {
          this.runAnalytics(this.props.db);
          window.alert('Updates to Candidate Vehicles may take some time to process.');

        })
        .catch((err)=>{
          console.error(`ezEV Update Candidates Call failed: ${err}`);
          window.alert('Authorization Error', 'An error occured while validating.', 'warning');
        })
    }
  }

  sortCandidates = (type) => {
    if (type === 'BEV'){
      var BEV = this.state.candidates.filter(c =>{return c.is_bev === true})
      BEV.map(v =>{
        var rprice = `$${v.net_price}`
        v['rprice'] = FormatCommas(rprice, this.props.userSettings)
        v['table'] = 'candidates'
        v['admin'] = 'true';
        return v
      })
      return BEV
    }
    if (type === 'PHEV'){
      var PHEV = this.state.candidates.filter(c =>{return c.is_phev === true})
      PHEV.map(v =>{
      var rprice = `$${v.net_price}`
      v['rprice'] = FormatCommas(rprice, this.props.userSettings)
      v['table'] = 'candidates'
      v['admin'] = 'true';
      return v
    })
      return PHEV
    }
};

  render(){
    const user = this.props.user;
    return (
      <div>
        <HeaderSelection text='Select Candidate Vehicles for Suitability Assessment' displayName={this.props.displayName} />
        <div className="ezev-content">
          <LinkBack />
          {user.role >= Roles.PartnerAdmin ?
            <div className="ezev-candidate-selection" data-testid="candidateSelectTables-testid">
              <div className="ezev-candidate-header ezev-h2">
                Please select the replacement candidates you would like to see in your ezEV Suitability Assessment
              </div>
              <div className="ezev-candidate-additional">
                <p className="ezEV-body">Click on a row to select or deselect a candidate vehicle</p>
              </div>
            <div className="ezev-button-1 lhs" onClick={this.selectAllCandidates}>
                <p>Select All</p>
              </div>
              <div className="ezev-button-1" onClick={this.deselectAllCandidates}>
                <p>Clear All</p>
              </div>
              <div className="ezev-button-2" onClick={this.processCandidateSelection}>
                <p className="ezev-button-font-2">Save & Proceed</p>
              </div>

              <div className="ezev-candidate-options-wrapper">
              <div className="ezev-assumptions-vehicles">
              < CandidatesTableRenderer bevs={this.sortCandidates('BEV')} phevs={this.sortCandidates('PHEV')} clickCandidate={this.clickCandidate} userSettings={this.props.userSettings}/>
              </div>
                <div id="ezev-candidate-options">
                  <div className="ezev-candidate-explanation-wrapper">
                    <div className="ezev-candidate-explanation ezEV-body">
                      <p className="ezev-candidate-explanation-text">"Fleet Price" is based on Sourcewell vehicle pricing available through
                        the Climate Mayors EV Purchasing Collaborative (<a href="https://driveevfleets.org/" target="_blank" rel="noopener noreferrer">https://driveevfleets.org/</a>).
                        We recognize government, education & nonprofit organizations have access to reduced pricing in some instances and make
                        every attempt to reflect that. Please contact us for more detailed information.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          :
            <div data-testid="permissionError-testid">
              <h2 className="ezev-error-header">Permission Error</h2>
              <p className="ezev-error-text">Only users with admin permissions can view this page.</p>
              <p className="ezev-error-text">Please contact us for more information.</p>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(CandidateSelection);
