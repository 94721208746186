import React, { useState, useMemo, useEffect, useRef, useReducer, Suspense } from 'react'
import { ChargeLocation } from "../swt-ezio";
import * as S from "../../styles/ezio-styles/LocationDetail-styles"
//import StatBox from "./StatBox"
import Table from "./Table";
import { columnsToHeaders, rowsToData } from "../../utils/ezio-utils/CSVUtils"
import { vehiclesTableColumns } from "./TableHelpers";
import GraphDaily from './GraphDaily';
import { ReactComponent as DownArrow } from "../../images/dropdowndownarrow-17px-18px.svg"
import ChargeTypeDropdown from "./ChargeTypeDropdown";
import { OptionType } from "./ChargeTypeDropdown";
import DateSelectors from "./DateSelectors";
import useOutsideAlerter from '../../utils/ezio-utils/useOutsideAlerter'
import GraphMonthly from './GraphMonthly';
import { useHistory } from "react-router-dom";
import { StatView } from "@sawatchlabs/swt-components";
import { vehicleIdDisplay } from './TableHelpers';
import { DateTime } from 'luxon';

//code-splitting imports
const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));

export type LocationDetailProps = {
    chargeLocations: Map<number, ChargeLocation>
    selectedChargeLocation?: number
    totalVehicleCount: number
    viewMode: string
    req: any
    category: string
    group: string
    electrification: number
    vehicleClasses: Array<string>
    dbDisplayName: string,
    userSettings: any,
    selectedVins: Array<string>
}

enum ViewMode {
    Monthly,
    TimeOfDay
}

type StatBoxesType = {
    vehiclesProjectedToCharge: {
        value: string
        subValue: string
        caption?: string
    }
    vehiclesWithPassingEvScore: {
        value: string
        caption?: string
    },
    highestPeakDemand: {
        value: string
        subValue: string
        caption?: string
    }
    level2ChargingPortsNeeded: {
        value: string
        caption?: string
        subCaption?: string
    },
    dcfcChargingPortsNeeded: {
        value: string
        caption?: string
        subCaption?: string
    },
    totalLocationCharging: {
        value: string,
        subValue: string,
        caption?: string
    }
}

type DateBounds ={
    min: DateTime
    max: DateTime
}


export default function LocationDetail({ 
    
    viewMode: initialViewMode, selectedChargeLocation, chargeLocations, req, totalVehicleCount, category, group, vehicleClasses, electrification, dbDisplayName, userSettings, selectedVins }: LocationDetailProps) {
    const [chargeLocation, setChargeLocation] = useState<ChargeLocation>();
    const [selectedDates, setSelectedDates] = useState<DateBounds>({min: DateTime.utc(), max: DateTime.utc()});
    const [dateBounds, setDateBounds] = useState<DateBounds>(); //objects so we can work with proper date objects
    const [selectedChargeType, setSelectedChargeType] = useState<OptionType>({ value: 'unmanaged', label: 'Unmanaged Charging' });
    const [showVehicleCountToolTip, setShowVehicleCountToolTip] = useState<Boolean>(false);
    const [showPrimaryParkingToolTip, setShowPrimaryParkingToolTip] = useState<Boolean>(false);
    const [showPortCountToolTip, setShowPortCountToolTip] = useState<Boolean>(false);
    const [cursorCoords, setCursorCoords] = useState({ x: 0, y: 0 });
    const [navOpen, setNavOpen] = useState(false);

    //date sensitive state for stat views:
    const [inBoundsChargingVehicles, setInBoundsChargeVehicles] = useState<number>(chargeLocation?.chargingVehiclesCount ?? 0);
    const [notAtLocationChargingKwh, setNotAtLocationChargingKwh] = useState<number>(0);
    const [atLocationChargingKwh, setAtLocationChargingKwh] = useState<number>(0);

    const navRef = useRef(null)
    useOutsideAlerter(navRef, () => setNavOpen(false))

    const handleShowPrimaryParkingToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowPrimaryParkingToolTip(show);
    }
    const handleShowVehicleCountToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowVehicleCountToolTip(show);
    }
    const handleShowPortCountToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowPortCountToolTip(show);
    }

    const obj = vehiclesTableColumns.find((vtc) => vtc.Header === "Primary Parking Location");
    obj.showToolTip = handleShowPrimaryParkingToolTip;

    const [viewMode, setViewMode] = useState<ViewMode>(initialViewMode === "Monthly" ? ViewMode.Monthly : ViewMode.TimeOfDay)
    useEffect(() => {
        setViewMode(initialViewMode === "Monthly" ? ViewMode.Monthly : ViewMode.TimeOfDay)
    }, [initialViewMode])

    //Go through the master location list to find the address
    useMemo(() => {
        if (!selectedChargeLocation)return;
        
        const matchingLocation: ChargeLocation | undefined = chargeLocations.get(selectedChargeLocation);
        if (matchingLocation) {
            setChargeLocation(matchingLocation);
        }
    }, [selectedChargeLocation, chargeLocations]);

    const history = useHistory();

    useMemo(() => {
        //calc dates from history.location.state or req if no state
        let start: DateTime, end: DateTime = undefined;
        if(history.location.state){
            const s:any = history.location.state;
            if(s.dateBounds){
                // start at the maximum of (first day of month, minimum of app-level date bound)
                start = DateTime.fromMillis(Math.max((req.beginDate.ts), (s.dateBounds.min.ts))).setZone('UTC-0');
                // end at the minimum of (last day of month, maximum of app-level date bound)
                end = DateTime.fromMillis(Math.min((req.endDate.ts), (s.dateBounds.max.ts))).setZone('UTC-0');
            }
        }
        if((!start || !end) || !start.isValid || !end.isValid){
            start = DateTime.fromMillis(req.beginDate.ts).setZone('UTC-0');
            end = DateTime.fromMillis(req.endDate.ts).setZone('UTC-0');
        }
        const s: any = {}
        setDateBounds({min: start, max: end});
        s.min = start;
        s.max = end;
        setSelectedDates(s)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.state, req.beginDate, req.endDate]);

    const peakShavedDemandData = useMemo(() =>{
        if(!selectedDates)return;
        if(!chargeLocation)return;

        const arr: Array<any> = [];
        for(const k in chargeLocation.todPeakSmoothedSummaries){
            chargeLocation.todPeakSmoothedSummaries[k].dateKey = k;
            const ts = DateTime.fromISO(chargeLocation.todPeakSmoothedSummaries[k].localStart).toUTC();
            if(ts.ts >= selectedDates.min && ts.ts <= selectedDates.max)arr.push(chargeLocation.todPeakSmoothedSummaries[k]);
        }
        return arr;
    }, [chargeLocation, selectedDates]);

    const smartChargeDemandData = useMemo(() =>{
        if(!selectedDates)return;
        if(!chargeLocation)return;

        const smartChargeDemandData: Array<any> = [];
        for(const k in chargeLocation.todSmartSummaries){
            chargeLocation.todSmartSummaries[k].dateKey = k;
            const ts = DateTime.fromISO(chargeLocation.todSmartSummaries[k].localStart).toUTC();
            if(ts >= selectedDates.min && ts <= selectedDates.max)smartChargeDemandData.push(chargeLocation.todSmartSummaries[k]);
        }
        return smartChargeDemandData;
    }, [chargeLocation, selectedDates]);

    const demandData = useMemo(()=>{
        if(!selectedDates)return;
        if(!chargeLocation)return;
  
        const demandData: Array<any> = [];
        for(const k in chargeLocation.todSummaries){
            chargeLocation.todSummaries[k].dateKey = k;
            const ts = DateTime.fromISO(chargeLocation.todSummaries[k].localStart).toUTC();
            if(ts >= selectedDates.min && ts <= selectedDates.max)demandData.push(chargeLocation.todSummaries[k]);
        }

        return demandData;

    },[chargeLocation, selectedDates]);

    function handleDateChange(type: string, e: DateTime) {
        setSelectedDates({
            min: type === "min" ? e : selectedDates.min,
            max: type === "max" ? e : selectedDates.max
        });
    }

    const onChargeTypeChange = (option: React.SetStateAction<OptionType>) => {
        setSelectedChargeType(option);
    };

    const [statBoxesState, dispatchStatBoxes] = useReducer((state: StatBoxesType, action: Partial<StatBoxesType>) => ({
        ...state,
        ...action
    }), {
        vehiclesProjectedToCharge: {
            value: "",
            subValue: ""
        },
        vehiclesWithPassingEvScore: {
            value: ""
        },
        highestPeakDemand: {
            value: "",
            subValue: " kW"
        },
        level2ChargingPortsNeeded: {
            value: ""
        },
        dcfcChargingPortsNeeded: {
            value: ""
        },
        totalLocationCharging: {
            value: "",
            subValue: " %"
        }
    });

    useEffect(() => {
        dispatchStatBoxes({
            vehiclesProjectedToCharge: {
                value: `${inBoundsChargingVehicles}`,
                subValue: `/${totalVehicleCount ?? 0}`,
                caption: "Vehicles Projected to Charge/Total Vehicles"
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chargeLocation, chargeLocations, inBoundsChargingVehicles]);

    useEffect(() => {
        dispatchStatBoxes({
            vehiclesWithPassingEvScore: {
                value: `${chargeLocation?.evRecommendationCount ?? 0}`,
                caption: "Vehicles recommended for replacement with an EV"
            }
        })
    }, [chargeLocation]);

    useEffect(() => {
        let peak = 0;
        demandData?.forEach((d: any) => {
            d.activity.forEach((a: number) => {
                //cl worker generate 100 activity blocks on daylight saving
                //switch day in Nov. This handles that.
                //TODO: fix completely in cl-worker
                if(!isNaN(a))peak = Math.max(peak, a);
            })
        });
        dispatchStatBoxes({
            highestPeakDemand: {
                value: `${peak}`,
                subValue: " kW",
                caption: "Highest Peak Demand at Same Time"
            }
        })
    }, [demandData]);

    useEffect(() => {
        const locationHistogram = chargeLocations.get(selectedChargeLocation).drawHistogram
        dispatchStatBoxes({
          level2ChargingPortsNeeded: {
            value: `${locationHistogram[0]}/${locationHistogram[1]}/${locationHistogram[2]}`,
            caption: `L2 Ports Needed`,
            subCaption: '(7kW/11kW/20kW)'
          }
        })
        dispatchStatBoxes({
          dcfcChargingPortsNeeded: {
            value: `${locationHistogram[3] + locationHistogram[4]}`,
            caption: 'DCFC Ports Needed',
            subCaption: '(50kW+)'
          }
        })
    },
        [chargeLocations, selectedChargeLocation]
    );

    useEffect(() => {
        let ratio = 0;
        if(atLocationChargingKwh + notAtLocationChargingKwh > 0){
            ratio = atLocationChargingKwh / (atLocationChargingKwh + notAtLocationChargingKwh)*100;
            ratio = Math.round(ratio);
        }
        
        dispatchStatBoxes({
            totalLocationCharging: {
                value: `${ratio}`,
                subValue: " %",
                caption: "Charging projected at this location"
            }
        })
    }, [atLocationChargingKwh,notAtLocationChargingKwh]);

    useMemo(() => {
        if(chargeLocation && chargeLocation.chargingVehiclesVins && dateBounds){
            chargeLocation.chargingVehiclesVins.forEach((v: string) => {
                const vcl = chargeLocation.vehicleResults.find((b) => b.vin === v);
                const ces = chargeLocation.chargeEvents.filter((ce: any) => ce?.vin === v);
                let duration = 0;
                let count = 0;
                ces.forEach((ce: any)=>{
                    const ts = DateTime.fromISO(`${ce.utc_start}Z`).toUTC();
                    if(ts >= dateBounds.min && ts <= dateBounds.max){
                        const ts1 = DateTime.fromISO(`${ce.utc_stop}Z`).toUTC();
                        duration += (ts1.ts - ts.ts)/1000/3600;
                        count++;
                    }
                });
                vcl.averageChargeHours = duration/count;
            })
        }
    }, [chargeLocation, dateBounds]);

    //This is duplicative of work that is done in the cl-worker and a bad pattern
    //we're putting this in place temporarily, given time constraints,
    //pending a larger refactoring effort
    const filterVehicleData = (mutableVehicleData: any) => {

        // for each loop on the vehicles charge events to get
        // here and away kwh and avg hours of charging vals for dateBounds
        // this is now also an overloaded function and handles vcls plus charge count and home/non-home sums
        let chargingVcls = 0;
        let totalHereKwh = 0;
        let totalAwayKwh = 0;
        mutableVehicleData.forEach((vcl: any) => {
            const locId = chargeLocation.pkid;
            //adding one handles the zero index...
            const days = 1+(selectedDates.max.ts - selectedDates.min.ts)/1000/86400;
            let hereKwh = 0;
            let awayKwh = 0;
            let chargeHours = 0;
            if (selectedVins.includes(vcl.vin)) {
                vcl.chargeEvents?.forEach((ce: any) => {
                    const ls = DateTime.fromISO(`${ce.utc_start}Z`).toUTC();
                    if(ls >= selectedDates.min && ls <= selectedDates.max){
                        if(ce.parking_loc === locId)hereKwh += ce.kwh;
                        else awayKwh += ce.kwh;
                        const le = DateTime.fromISO(`${ce.utc_stop}Z`).toUTC();
                        const duration = le.ts - ls.ts;
                        chargeHours += (duration/1000/60/60); 
                    }
                });
                totalHereKwh += hereKwh;
                totalAwayKwh += awayKwh;
                vcl.averageChargeHours = chargeHours/days;
                vcl.period_at_location_kwh = hereKwh;
                vcl.period_not_at_location_kwh = awayKwh;
                if(chargeHours > 0)chargingVcls++;
            }
        });

        setInBoundsChargeVehicles(chargingVcls);
        setAtLocationChargingKwh(totalHereKwh);
        setNotAtLocationChargingKwh(totalAwayKwh);
        return mutableVehicleData;
    }

    const formattedVehicleData = useMemo(() => {

        if(!chargeLocation) {return null}
        const mutatableVehicleData = JSON.parse(JSON.stringify(chargeLocation.vehicleResults))
        mutatableVehicleData.forEach((vcl: any) => {
            vcl.asset_id = vehicleIdDisplay(vcl)
        });
        return filterVehicleData(mutatableVehicleData);
        // eslint-disable-next-line
    },[chargeLocation, selectedDates]);

    return (
        <>
            {showPortCountToolTip &&
                <S.ToolTipWrapper cursorCoords={cursorCoords}
                    onMouseEnter={(e) => {handleShowPortCountToolTip(true, e)}}
                    onMouseLeave={(e) => {handleShowPortCountToolTip(false, e)}}
                >
                    <S.ToolTipText>
                    These numbers are for the for the time period displayed at the top of the view, not the monthly drill down.
                    </S.ToolTipText>
                </S.ToolTipWrapper>
            }
            {showVehicleCountToolTip &&
                <S.ToolTipWrapper cursorCoords={cursorCoords}
                    onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                    onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
                >
                    <S.ToolTipText>
                    These are the number of vehicles projected to charge at this location based on the selected filters. Vehicles may be included in these figures that currently do not use this location as their primary parking location.
                    </S.ToolTipText>
                </S.ToolTipWrapper>
            }
            {showPrimaryParkingToolTip &&
                <S.ParkingToolTipWrapper cursorCoords={cursorCoords}
                    onMouseEnter={(e) => {handleShowPrimaryParkingToolTip(true, e)}}
                    onMouseLeave={(e) => {handleShowPrimaryParkingToolTip(false, e)}}
                >
                    <S.ToolTipText>
                        This is the current primary parking location of this vehicle based on our analysis of most recent 30 days of travel.
                    </S.ToolTipText>
                </S.ParkingToolTipWrapper>
            }
            <S.PageLayout >
                <S.ContentWrapper>
                <S.PageHeader ref={navRef}>
                    <S.DropdownMenu onClick={() => {setNavOpen(!navOpen)}}>
                        {viewMode === ViewMode.Monthly ? "Monthly Peak kW Demand" : "Time of Use kW Demand"}
                        <S.DownArrowWrapper>
                            <DownArrow/>
                        </S.DownArrowWrapper>
                    </S.DropdownMenu>
                    {navOpen &&

                        <S.DropdownOptions onClick={() => setNavOpen(false)}>
                            <S.OptionLink to={viewMode === ViewMode.Monthly ? `/ezio/kw-demand-daily/${selectedChargeLocation}` : `/ezio/kw-demand-monthly/${selectedChargeLocation}`} >
                                {viewMode === ViewMode.Monthly ? "Daily Demand" : "Monthly Peak Demand"}
                            </S.OptionLink>
                        </S.DropdownOptions>

                    }
                </S.PageHeader>
                <S.DetailsControls>
                    <S.InputWrapper>
                        <S.ControlsLabel>Charge Type</S.ControlsLabel>
                        <ChargeTypeDropdown selectedChargeType={selectedChargeType} onChange={onChargeTypeChange}/>
                    </S.InputWrapper>
                    <S.InputWrapper>
                        <S.ControlsLabel>Time Period</S.ControlsLabel>
                        {viewMode === ViewMode.Monthly &&
                        <S.DateSelectorContainer>
                            <DateSelectors
                                dateBounds={{min: req.beginDate, max: req.endDate}}
                                selectedDates={selectedDates}
                                handleChange={handleDateChange}
                                userSettings={userSettings}
                            />
                        </S.DateSelectorContainer>
                        }
                        {viewMode === ViewMode.TimeOfDay &&
                        <S.DateSelectorContainer>
                            <DateSelectors
                                dateBounds={{min: req.beginDate, max: req.endDate}}
                                selectedDates={selectedDates}
                                handleChange={handleDateChange}
                                userSettings={userSettings}
                            />
                        </S.DateSelectorContainer>
                        }
                        </S.InputWrapper>
                        

                    <S.LocationSummaryNavigation to={chargeLocation ? `/ezio/locations/${chargeLocation.pkid}` : `/ezio/locations/list`}>
                        Location Summary
                    </S.LocationSummaryNavigation>
                </S.DetailsControls>
                <S.StatBoxRow statCount = {selectedChargeLocation === -1 ? 5 : 6}>
                    
                    <S.StatBoxContainer>
                        {/* evRecommendationCount / vehicleCount of location  */}
                        {chargeLocation && chargeLocation.pkid > -1 &&
                            <StatView 
                                values={statBoxesState.vehiclesProjectedToCharge} 
                                hoverHighlight={false} 
                                onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                                onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
                            />
                        }
                        {chargeLocation && chargeLocation.pkid === -1 &&
                            <StatView
                                values={statBoxesState.vehiclesProjectedToCharge}
                                hoverHighlight={false} 
                                onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                                onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
                            />
                        }
                    </S.StatBoxContainer>
                    <S.StatBoxContainer>
                        {/* evRecommendationCount / vehicleCount of location  */}
                        <StatView 
                            values= {statBoxesState.vehiclesWithPassingEvScore} 
                            hoverHighlight={false} />
                    </S.StatBoxContainer>
                    <S.StatBoxContainer>
                        <StatView 
                            values={statBoxesState.highestPeakDemand} 
                            hoverHighlight={false} 
                        />
                    </S.StatBoxContainer>
                    {/* Same as max EVs for now */}
                    <S.StatBoxContainer>
                        <StatView 
                            values={statBoxesState.level2ChargingPortsNeeded} 
                            hoverHighlight={false} 
                            onMouseEnter={(e) => {handleShowPortCountToolTip(true, e)}}
                            onMouseLeave={(e) => {handleShowPortCountToolTip(false, e)}}
                            />
                    </S.StatBoxContainer>
                    <S.StatBoxContainer>
                        <StatView 
                            values={statBoxesState.dcfcChargingPortsNeeded} 
                            hoverHighlight={false} 
                            onMouseEnter={(e) => {handleShowPortCountToolTip(true, e)}}
                            onMouseLeave={(e) => {handleShowPortCountToolTip(false, e)}}
                            />
                    </S.StatBoxContainer>
                    { (selectedChargeLocation !== -1) && 
                    <S.StatBoxContainer>
                        <StatView 
                            values={statBoxesState.totalLocationCharging}
                            />
                    </S.StatBoxContainer>}
                </S.StatBoxRow>
                </S.ContentWrapper>

                {chargeLocation &&
                <S.GraphTableWrapper>
                    {viewMode === ViewMode.Monthly &&
                        <S.GraphWrapper>
                            <S.TableTitle>Monthly kW Demand</S.TableTitle>
                            <S.GraphSubtitle>
                                This graph shows the projected peak demand from charging at this location.
                            </S.GraphSubtitle>
                            {/* Left bar  */}
                            {chargeLocation?.monthlyPeaks &&
                                < GraphMonthly
                                    monthlyPeaks={chargeLocation?.monthlyPeaks}
                                    displayTitle={false}
                                />
                            }
                            {!chargeLocation?.monthlyPeaks &&
                                <S.NoDataMessage>No kW Demand Data to Display. Try adjusting filters for more results.</S.NoDataMessage>
                            }
                        </S.GraphWrapper>
                    }
                    {viewMode === ViewMode.TimeOfDay &&
                        <S.GraphWrapper>
                            <S.TableTitle>Time of Use kW Demand - Selected Period</S.TableTitle>
                            <S.GraphSubtitle>
                                This graph shows the projected daily demand curves for each day of the
                                selected period.
                            </S.GraphSubtitle>
                            {demandData && demandData.length > 0 &&
                                <GraphDaily
                                    demandData={demandData}
                                    smartChargeDemandData={smartChargeDemandData}
                                    peakShavedDemandData={peakShavedDemandData}
                                    dateBounds={selectedDates}
                                    selectedChargeType={selectedChargeType}
                                    userSettings={userSettings}
                                />
                            }
                            {(!demandData || demandData.length < 1) &&
                                <S.NoDataMessage>No kW Demand Data to Display. Try adjusting filters for more results.</S.NoDataMessage>
                            }
                        </S.GraphWrapper>
                    }
                    <S.TableWrapper>
                        <S.TableContainer>
                            <S.TableTitle>Vehicles</S.TableTitle>
                            <S.TableSubtitle>
                                This table lists the vehicles that are projected to charge at this location.
                            </S.TableSubtitle>
                            <Table columns={vehiclesTableColumns} data={formattedVehicleData} type={"details"} hoverHighlight={false} defaultSort={'chargingHours'} />
                        </S.TableContainer>
                        <div>
                            {chargeLocation?.vehicleResults && 
                                <Suspense fallback={<div></div>}>
                                    <ExcelDownloadButton
                                        csvType={'vehicles'}
                                        beginDate={selectedDates.min}
                                        endDate={selectedDates.max}
                                        category={category}
                                        group={group}
                                        electrification={electrification}
                                        vehicleClasses={vehicleClasses}
                                        location={chargeLocation}
                                        db={req.dbName}
                                        columns={columnsToHeaders(vehiclesTableColumns)}
                                        data={rowsToData(vehiclesTableColumns, formattedVehicleData, (a: any, b: any) => a.assetId > b.assetId ? 1 : -1)}
                                        dbDisplayName={dbDisplayName}
                                        userSettings={userSettings}
                                    />
                                </Suspense>
                            }
                        </div>
                    </S.TableWrapper>
                </S.GraphTableWrapper>
                }
            </S.PageLayout>
        </>
    )
}
