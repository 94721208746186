import React from "react"
import * as S from "../../styles/ezio-styles/Controls-styles"
import { ChangeElectrificationAction, ChangeClassAction, ChangeLocationAction, ChangeCategoryAction, ChangeGroupAction } from "../swt-ezio"
import CategoryDropdown from "./CategoryDropdown"
import GroupsDropdown from "./GroupsDropdown"
import LocationsDropdown from "./LocationsDropdown"
import ElectrificationDropdown from "./ElectrificationDropdown"
import ClassesDropdown from "./ClassesDropdown"
import { ChargeLocation } from "../swt-ezio";
//import grayArrow from "../../images/ic-refresh-gray-a100-504px-587px.png"
//import lightGreenArrow from "../../images/ic-refresh-lightgreen-a100-504px-587px.png"
//import greenArrow from "../../images/ic-refresh-green-a100-504px-587px.png"

type ControlsProps = {
    groups: Array<any>
    selectedGroup?: any
    selectedCategory: any
    chargeLocations: Map<number, ChargeLocation>
    selectedChargeLocation: number
    classes: Array<string>
    selectedClasses: Array<string>
    selectedElectrification: number
    onChangeControls: Function
}


export default function Controls({
    groups, selectedGroup,
    selectedCategory, chargeLocations, selectedChargeLocation,
    classes, selectedClasses,
    selectedElectrification,
    onChangeControls}: ControlsProps) {
    return (
        <S.ControlBoxRow>
            <S.ControlItem>
                <CategoryDropdown selectedCategory={selectedCategory.label} onChange={(action: ChangeCategoryAction) => onChangeControls({ category: action })} />
            </S.ControlItem>
            <S.ControlItem>
                <GroupsDropdown groups={groups} selectedGroup={selectedGroup} onChange={(action: ChangeGroupAction) => {
                    onChangeControls({ group: action })
                }} />
            </S.ControlItem>
            <S.VerticalRule />
            <S.ControlItem>
                <LocationsDropdown chargeLocations={chargeLocations} selectedChargeLocation={selectedChargeLocation} onChange={(action: ChangeLocationAction) => onChangeControls({ location: action })} />
            </S.ControlItem>
            <S.ControlItem>
                <ElectrificationDropdown selectedElectrification={selectedElectrification} onChange={(action: ChangeElectrificationAction) => onChangeControls({ electrification: action })} />
            </S.ControlItem>
            <S.ControlItem>
                <ClassesDropdown classes={classes} selectedClasses={selectedClasses} onChange={(action: ChangeClassAction) => onChangeControls({ classes: action })} />
            </S.ControlItem>
        </S.ControlBoxRow>
    )
}
