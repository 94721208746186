import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';

// Create styles --> SCALED DOWN by 1 to .66 ratio to account for PDF format differences
// Dropshadows are not supported by react-pdf

const styles = StyleSheet.create({
    statViewWrapper: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontStyle: 'normal',
        fontWeight: 'normal',
        width: '130px',
        height: '80px',
        color: 'black',
        backgroundColor: 'rgb(252, 252, 252)',
        border: '1px solid rgb(235, 235, 235)',
        borderRadius: '5px',
        padding: '16px',
    },
    statViewValueContainer: {
        justifyContent: "space-around",
        alignItems: "center"
    },
    statViewValue:{
        display: "flex",
        flexDirection: "row",
        fontSize: "21px",
        textAlign: "center",
        color: 'rgba(74, 74, 74, 1)',
    },
    statViewSubValue:{
        marginTop: '6px',
        fontSize: '15px',
        color: 'rgba(74, 74, 74, 1)',
    },
    statViewCaption:{
        fontSize: '8px',
        color: 'rgba(116, 120, 125, 1)',
        textAlign: 'center'
    },
    statViewSubCaption:{
        fontSize: '6px',
        color: 'rgba(116, 120, 125, 1)',
        textAlign: 'center'
    },
    arrows: {
        width: "15px",
        paddingRight: "3px"
    }
  }
);

export default function PDFStatView(props){
    const values = props.values;
    const greenArrowUrl='https://drive.google.com/uc?id=1pso60EllWvsiC3b1r7AKcUA0a3bHNCj-';
    const redArrowUrl = 'https://drive.google.com/uc?id=1Ako-Ab1Ii3dlaPovcrCvbE9EBVsXiiZi';

    if (!values || typeof values.value === 'undefined') {
        return (
            <View style={styles.statViewWrapper}>
                <Text>No Data </Text>
            </View>
            )
        }
    //ternaries replaced in this function as react-pdf responds poorly to nulls and empty strings
    //and we need to handle empty strings by converting to null... 
    //cause react-pdf is not catching in conditional render

    if(values.value.length < 1)values.value = null;
    if(!values.subValue || values.subValue.length < 1)values.subValue = null;
    if(!values.caption || values.caption.length < 1)values.caption = null;
    if(!values.subCaption || values.subCaption.length < 1)values.subCaption = null;
    return(
        <View style={styles.statViewWrapper}>
            <View style={styles.statViewValueContainer}>
                {values.arrow &&
                    <Image style={styles.arrows} src={props.arrow === 'asc' ? greenArrowUrl : redArrowUrl }/>
                }
                {values.value && 
                    <View style={styles.statViewValue}><Text>{values.value}</Text>
                    {values.subValue &&
                        <View style={styles.statViewSubValue}><Text>{values.subValue}</Text></View>
                    }
                    </View>
                }
                {values.caption &&
                    <View style={styles.statViewCaption}><Text>{values.caption}</Text></View>
                }
                {values.subCaption &&
                    <View style={styles.statViewSubCaption}><Text>{values.subCaption}</Text></View>
                } 

            </View>
        </View>
    )
}