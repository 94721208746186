import styled from "styled-components/macro"
import * as colors from "./ColorScheme";

const MEDIUM_VIEW_BREAKPOINT = "1250px";
const SIX_STAT_VIEW_BREAKPOINT = "1500px";
const SMALL_VIEW_BREAKPOINT = "880px";


type PageLayoutProps = {
    showTableRow: boolean
}
type ToolTipWrapperProps = {
    cursorCoords: {x: number, y: number};
}

export const ParkingToolTipWrapper = styled.div<ToolTipWrapperProps>`
    padding: 8px;
    border: 1px solid #979a9e;
    box-shadow: 2px 2px 4px #47484a;
    border-radius: 5px;
    width: 300px;
    z-index: 1;
    background-color: white;
    font-size: 14px;
    display: inline-block;
    color: #47484a;
    position: absolute;
    ${({ cursorCoords }:  ToolTipWrapperProps) => {
        return `left: ${cursorCoords.x-200}px;top: ${cursorCoords.y +25}px`
    }}
`;

export const ToolTipWrapper = styled.div<ToolTipWrapperProps>`
    padding: 8px;
    border: 1px solid #979a9e;
    box-shadow: 2px 2px 4px #47484a;
    border-radius: 5px;
    width: 300px;
    z-index: 1;
    background-color: white;
    font-size: 14px;
    display: inline-block;
    color: #47484a;
    position: absolute;
    ${({ cursorCoords }:  ToolTipWrapperProps) => {
        return `left: ${cursorCoords.x+25}px;top: ${cursorCoords.y-100}px`
    }}
`;

export const ToolTipText = styled.p`
    margin: 0;
`

export const PageLayout = styled.main<PageLayoutProps>`
    position: relative;
    @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
        display: block;
        min-width: auto;
    }
    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        min-width: auto;
    }
`;

export const PageHeader = styled.h1`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 35px;
color: ${colors.ezio_dark_grey};
margin-left: 1rem;
margin-top: 0rem;
`

export const ContentWrapper = styled.div`
width: fit-content;
margin: 0 auto;

@media(max-width: ${MEDIUM_VIEW_BREAKPOINT}){
    width: auto;
}
`

export const StatBoxRow = styled.div <{statCount: number}>`
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    grid-row: 2 / span 1;
    grid-column: 1 / span 2;
    width: 80%;
    height: 100%;

    @media(max-width: ${props => (props.statCount === 6) ? SIX_STAT_VIEW_BREAKPOINT : MEDIUM_VIEW_BREAKPOINT}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 700px;
    }
    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        min-width: 500px;
        width: 500px;
    }
`;

export const StatBoxContainer = styled.div`
padding: 1rem;`

export const GraphContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1100px;

    @media (max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
        flex-direction: column;
        align-items: center;
        width: 900px;
    }
    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        width: auto;
    }
`

export const GraphContainerLhs = styled.div`
    height: 325px;
    width: 500px;
    border: 2px solid ${colors.ezio_medium_light_grey};
    margin-top: 30px;
`

export const GraphWrapperLhs = styled.div`
    height: 100%;
    padding-top: 10px;
`

export const GraphContainerRhs = styled.div`
    height: 325px;
    width: 500px;
    border: 2px solid ${colors.ezio_medium_light_grey};
    margin-top: 30px;
`;

export const GraphWrapperRhs = styled.div`
    height: 100%;
    padding-top: 10px;
`

export const TableContainer = styled.div`
display: flex;
flex-direction: column;
max-width: 1300px;
margin: 0 auto;

@media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
    width: 90%;
}

@media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
    min-width: 600px;
}
`


type TableTitleProps = {
    showTopMargin: boolean
}

export const TableTitle = styled.h1<TableTitleProps>`
font-family: "Arimo", "Roboto";
font-style: normal;
${({ showTopMargin }: TableTitleProps) =>
        (showTopMargin ? "margin-top: 30px;" : "")
    }

font-weight: 500;
font-size: 30px;
line-height: 35px;
color: ${colors.ezio_dark_grey};
`

export const TableSubtitle = styled.span`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: ${colors.ezio_medium_grey};
`

const centeredLinkInButton = `
display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 43px;
    color: inherit;
text-decoration-line: none;
`
const buttonStyles = `


width: 146px;
height: 43px;
background: #FFFFFF;
border: 2px solid ${colors.ezio_dark_grey};
font-family: "Arimo", "Roboto";
font-style: normal;
cursor: pointer;
font-weight: 500;
font-size: 16px;
align-items: center;
justify-content: center;
display:flex;
transition: 0.1s filter linear;
&:hover {
    filter: drop-shadow(4px 2px 10px rgba(74, 74, 74, 0.18));
}
a{
    ${centeredLinkInButton}
}
`

export const CSVDownloadButton = styled.div`
margin-top: 20px;
${buttonStyles}
`