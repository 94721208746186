import { formatDuration } from "./idling-table-helper-functions";
import exceljs from 'exceljs';
import FileSaver from 'file-saver';
import { logoBase64 } from "./Logo";
import { vehicleIdDisplay } from "./idling-table-helper-functions";

const BLUE = '0000EE'
const WHITE = 'FFFFFF'
const PRUSSIAN_BLUE = '12365B'

export function csvFilenameDateFormat(str){
    const regex = /\//g;
    if (str) {
    return str.toLocaleDateString('en-US', {timeZone: 'UTC'}).replace(regex, '_')
    }
} 

export function tableDateFormat(str) {
  if (!str) return;
  let date = new Date(str).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: '2-digit'})
  let time = new Date(str).toLocaleTimeString('en-US', {hour: 'numeric', minute: '2-digit'})
  //Convert AM or PM to lowercase
  let tod = time.split(' ')[1].toLowerCase()
  return `${date.replaceAll(',','')} - ${time.split(' ')[0]}${tod}`
}

async function saveFile(workbook, filename) {
  const buf = await workbook.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), filename);
}

function adjustColumnWidth(worksheet) {
  worksheet.columns.forEach(column => {
    const lengths = column.values.map(v => v.toString().length);
    const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
    column.width = maxLength + 2;
  });
}

function createExcelDoc(formattedData) {
  const workbook = new exceljs.Workbook();
  const worksheet = workbook.addWorksheet('sheet1', {views: [{showGridLines:false}]});
  const imgId = workbook.addImage({
      base64: logoBase64,
      extension: 'png'
  });
  worksheet.addRows(formattedData.data);

  // Set style of title block headers
  worksheet.getCell('A1').font = {
      bold: true,
      size: 14
  };
  worksheet.getCell('A2').font = {
      bold: true
  };
  worksheet.getCell(`A${formattedData.headerCount-formattedData.emptyRows}`).value = {
      text: 'sawatchlabs.com',
      hyperlink: 'http://www.sawatchlabs.com'
  };
  worksheet.getCell(`A${formattedData.headerCount-formattedData.emptyRows}`).font = {
      underline: true,
      color: { argb: BLUE }
  }

  // Set font and fill for table headers
  worksheet.getRow(formattedData.headerCount).font = { bold: true, color: { argb: WHITE }};
  worksheet.getRow(formattedData.headerCount).eachCell((cell) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: {argb: PRUSSIAN_BLUE} }
  });

  adjustColumnWidth(worksheet);

  // Add image to cell B2 and preserve size
  worksheet.addImage(imgId, {
      tl: { col: 1, row: 0 },
      ext: { width: 307, height: 175 },
      editAs: undefined,
  });

  // Add borders to data cells
  worksheet.eachRow((row, rowNum) => {
      if (rowNum > formattedData.headerCount) {
          row.eachCell({ includeEmpty: true }, (cell) => {
              cell.border = {
                  top: {style:'thin'},
                  left: {style:'thin'},
                  bottom: {style:'thin'},
                  right: {style:'thin'}
                  };
          });
          row.eachCell((cell) => {
              cell.alignment = { horizontal: 'left' };
          })
          row.commit();
      }
  });

  return workbook;
}

export function execExcelDownload(props) {
  const formattedData = formatTableCsv(props);
  const workbook = createExcelDoc(formattedData);
  saveFile(workbook, props.filename);
}

export function formatTableCsv(props) {
  const {tableType, beginDate, endDate, group, vehicleClasses, minDuration, columns, data, filter, dbDisplayName} = props;
  let vehicleClassString = "";
  let emptyRow = [''];
  let headers = [];
  let accessors = [];
  let tableTitle = [`Idling Incidents ${tableType} Report - ${dbDisplayName}`]
  let downloadDate = [`Download Date: ` + (new Date()).toLocaleDateString("en-US")];
  let date = [`Date Range: ${(beginDate) ? beginDate.toLocaleDateString("en-US"): '--'} - ${(endDate) ? endDate.toLocaleDateString("en-US"): '--'}`];
  let vehicleYMM = null;
  if (data && data.length > 0) {
    vehicleYMM = tableType === 'Vehicle' ? [`${vehicleIdDisplay(data[0])} - ${data[0].year} ${data[0].make} ${data[0].model}`]: null;
  } 
  let duration = [`Duration: ${minDuration}+ minutes`];
  let formatArr = []

  if (vehicleClasses.length < 1) {
    vehicleClassString = "All Classes";
  }
  else {
    vehicleClasses.forEach((vc) => {
      if (vc !== 'All Classes'){
        vehicleClassString += vc + ", "
      }
    })
    vehicleClassString = vehicleClassString.substring(0, vehicleClassString.length-2);
  }

 if (tableType === 'Vehicle') {
  formatArr = [
    ['Sawatch Labs - Idling'],
    tableTitle,
    downloadDate,
    date,
    vehicleYMM,
    duration,
    emptyRow,
    emptyRow,
    headers,
  ]
} 
else {
  formatArr = [
    ['Sawatch Labs - Idling'],
    tableTitle,
    downloadDate,    
    date,
    [`Group: ${group.name}`],
    [`Vehicle Classes: ${vehicleClassString}`],
    duration,
    filter === '' || filter === undefined ? emptyRow : [`Filter: "${filter}"`],
    emptyRow,
    emptyRow,
    headers,
  ]
}
const headerCount = formatArr.length;
const emptyRows = formatArr.filter((e) => {
  return e[0] === ''
}).length

  columns.forEach(item => {
    headers.push(item.Header)
    accessors.push(item.accessor)
  })
  if (!data || data.length < 1) {
    formatArr.push(['No data to display']);
  }
  else {
    data.forEach(data => {
      let row = []
        accessors.forEach(accessor => {
          // NOTE addition of commas left out of excel formatting
          if (accessor === 'asset_id'){
              row.push(vehicleIdDisplay(data))
          }else if (data[accessor] === null || data[accessor] === undefined){
            row.push('-');
          }else if(accessor === 'local_start'){
            row.push(tableDateFormat(data[accessor]));
          }else if(accessor.includes('duration')){
            // Convert duration to number if possible
            let duration = formatDuration(data[accessor]);
            if (isNaN(Number(duration))) {
              row.push(duration);
            }
            else {
              row.push(Number(duration));
            }
          }else{
            row.push(data[accessor]);
          }
        })
      formatArr.push(row);
    })
  }
  return {"data": formatArr, "headerCount": headerCount, "emptyRows": emptyRows};
}

  export function getMonth(value) {
    var month;
    switch (value) {
      case "01":
      case "1":
        month = "Jan";
        break;
      case "02":
      case "2":
        month = "Feb";
        break;
      case "03":
      case "3":
        month = "Mar";
        break;
      case "04":
      case "4":
        month = "Apr";
        break;
      case "05":
      case "5":
        month = "May";
        break;
      case "06":
      case "6":
        month = "Jun";
        break;
      case "07":
      case "7":
        month = "Jul";
        break;
      case "08":
      case "8":
        month = "Aug";
        break;
      case "09":
      case "9":
        month = "Sep";
        break;
      case "10":
        month = "Oct";
        break;
      case "11":
        month = "Nov";
        break;
      case "12":
        month = "Dec";
        break;
      default:
        month = "";
    }
    return month;
  }