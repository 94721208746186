import React, { useEffect, useState } from "react";
import GraphElectricMilesByLocation from "./GraphElectricMilesByMonth";
import GraphKwhTotalUptake, {GraphKwhTotalMissed} from "./GraphKwhTotals";
import GraphKwhByLoc from "./GraphKwhByLoc";
import GraphTcoTrend from "./GraphTcoTrend";
import StatsBubble from "./StatsBubble";
import FormatCell from "./TableHelpers";
import { Roles } from "../core-components/utility-components/UserUtils";

export default function StatsController(props) {
  const user = props.user;
  const [statsData, setStatsData] = useState({
    graph: "",
    boxes: []
  });

  useEffect(() => {
    selectStats(props.match.path);
    // eslint-disable-next-line
  }, [props]);

  function selectStats(path) {
    // NOTE if stats box requires arrow, also pass in rawValue to decide arrow
    // direction (based on raw not formatted val)
    var statsData = {'graph': null, 'boxes': null};
    if(path.includes('/driving-activity')){
      // if miles driven, add graph
      if(props.totalActivityStats.totalMiles > 0){
        statsData['graph'] = <GraphElectricMilesByLocation
                            baseUrl={props.baseUrl}
                            dbName={props.dbName}
                            startDate={props.startDate}
                            endDate={props.endDate}
                            user={props.user}
                          />;
      }
      statsData['boxes'] = [{'label': 'Number of EVs', 'value': props.evCount},
                            {'label': `Average Electric ${props.user.userSettings.distance_labels.shortPlural}/EV`, 'value': FormatCell('avgMiles', props.totalActivityStats['avgElecMiles'], null, null, null)},
                            {'label': 'Average EV Efficiency', 'value': `${FormatCell('avgMKwh', props.totalActivityStats['avgMKwh'], null, null, null)}`,'valueUnit':`${props.user.userSettings.distance_labels.shortPlural}/kWh`}];
    } else if (path.includes('/events-by-location')){
      var kwhByLocData = calcKwhByLoc();
      if(kwhByLocData)statsData['graph'] = < GraphKwhByLoc data={kwhByLocData} />;
      statsData['boxes'] = [{'label': 'Total kWh', 'value': `${FormatCell('chargeLocKwh', props.locKwhStats['chargeLocKwh'], null, null, null)}`},
                            {'label': 'Total Missed kWh', 'value': `${FormatCell('missedLocKwh', props.locKwhStats['missedLocKwh'], null, null, null)}`}];
    } else if (path.includes('/reimbursement')){
      var stats = calcReimbursement();
      statsData['boxes'] = [{'label': "Total Reimbursement", 'value': `${FormatCell('reimbursement', stats['total'], null, null, null, props.user.userSettings)}`},
                            {'label': "Reimbursement for Last Month", 'value': `${FormatCell('reimbursementLastMth', stats['lastMth'], null, null, null, props.user.userSettings)}`}];
    } else if (path.includes('/tco-comparison')){
      if(Object.keys(props.tcoTrend).length > 1)statsData['graph'] = <GraphTcoTrend trendData={props.tcoTrend} user={props.user}/>;
      statsData['boxes'] = [{'label': "Total Savings", 'value': FormatCell('totalSavings', props.tcoStatsTotal['totalSavings'], null, null, null, props.user.userSettings), 'arrow': true, 'rawValue': props.tcoStatsTotal['totalSavings']},
                            {'label': "Avg Operational Savings/EV", 'value': FormatCell('avgSavings', props.tcoStatsTotal['avgSavings'], null, null, null, props.user.userSettings), 'arrow': true, 'rawValue': props.tcoStatsTotal['avgSavings']},
                            {'label': 'TCO metrics are for the entire period of analysis', 'value': '', 'dateRange': `${props.formattedStartDate.date} - ${props.formattedEndDate.date}`}];
    } else if (path.includes('/charging-events')){
      stats = calcChargeTotals();
      var topVcls = getTopChargeVcls();
      if(topVcls.length > 0)statsData['graph'] = <GraphKwhTotalUptake kwhData={topVcls} graphTitle='Top 5 kWh Uptake' />;
      statsData['boxes'] = [{'label': 'Total kWh', 'value': `${FormatCell('totalKwh', stats['kwh'], null, null, null)}`},
                            {'label': 'Total Charging Events', 'value': stats['evtCt']}];
    } else if (path.includes('/missed-opportunities')){
      stats = calcMissedTotals();
      topVcls = getTopMissedVcls();
      if(topVcls.length > 0)statsData['graph'] = <GraphKwhTotalMissed kwhData={topVcls} graphTitle='Top 5 Missed kWh' />;
      statsData['boxes'] = [{'label': 'Total Missed kWh', 'value': `${FormatCell('totalKwh', stats['kwh'], null, null, null)}`},
                            {'label': `Total Missed Electric ${props.user.userSettings.distance_labels.longPlural}`, 'value': `${FormatCell('missedMiles', props.missedMiles, null, props.fuelCost, null)}`}];
    }
    setStatsData(statsData);
  }

  function getTopChargeVcls(){
    var sorted = props.chargeSummaryByVcl.sort((a, b) => (a.kwh < b.kwh) ? 1 : -1);
    return sorted.slice(0, 5);
  }

  function getTopMissedVcls(){
    var sorted = props.missedSummaryByVcl.sort((a, b) => (a.kwh < b.kwh) ? 1 : -1);
    return sorted.slice(0, 5);
  }

  function calcMissedTotals(){
    var kwh = 0;
    // var evts = 0;
    props.missedSummaryByVcl.forEach(m => {
      kwh += m.kwh;
      // evts += m.events_count;
    });
    return {kwh: kwh};
  }

  function calcChargeTotals(){
    var kwh = 0;
    var evts = 0;
    props.chargeSummaryByVcl.forEach(c => {
      kwh += c.kwh;
      evts += c.events_count;
    });
    return {kwh: kwh, evtCt: evts};
  }

  function calcReimbursement(){
    var total = 0;
    var lastMth = 0;
    props.reimbursementSummary.forEach(r => {
      total += r.cost;
    });
    if(props.reimbursementLastMth.length < 1){
      lastMth = "No Data";
    } else {
      props.reimbursementLastMth.forEach(r => {
        lastMth += r.cost;
      });
    }
    return {total: total, lastMth: lastMth};
  }

  function calcKwhByLoc() {
    // for KwhByLoc graph
    var chargeData = props.topLocs['topChargeLocs'];
    var missedData = props.topLocs['topMissedLocs'];
    var filteredData = { address: [], kwh: [], missedKwh: [] };
    chargeData.forEach((d) => {
      var addr = d.address;
      if(user.role >= Roles.FleetAdmin){
        if(d.nickname)addr = d.nickname;
        else addr = d.address_real;
      };
      if (addr.includes("Residential")) {
        addr = `Residential - ${addr.split("in ")[1]}`;
      }
      filteredData.address.push(addr.split(",")[0]);
      filteredData.kwh.push(d.charge_total_kwh);
      var missed = missedData.filter((m) => m.charge_loc_id === d.pkid);
      if (missed.length > 0 && missed[0].missed_total_kwh) {
        filteredData.missedKwh.push(missed[0].missed_total_kwh);
      }
    });
    filteredData.kwh = filteredData.kwh.map(
      (d) => (d = Math.round(d * 10) / 10)
    );
    filteredData.missedKwh = filteredData.missedKwh.map(
      (d) => (d = Math.round(d * 10) / 10)
    );
    if(filteredData.address.length < 1)return null;
    return filteredData;
  }

  var statsContent="";
  if(!statsData.graph || !statsData.boxes){
    statsContent="single";
  }
  if (statsData.boxes.length > 2){
    if(statsData.graph)statsContent="triple";
    if(!statsData.graph)statsContent="triple-only";
  }
  return (
    <div className="swt-ionev-stats-wrapper">
      <div className="swt-ionev-stats-wrapper-align">
      {(statsData.graph || statsData.boxes) &&
        <div className={`swt-ionev-stats-wrapper-content ${statsContent}`}>
          {statsData.graph &&
            <div className="swt-ionev-stats-wrapper-lhs">
              <div className="swt-ionev-graph-wrapper">
                {statsData.graph}
              </div>
            </div>
          }
          {statsData.boxes &&
            <div className="swt-ionev-stats-wrapper-rhs">
              <div className="swt-ionev-stats-box-wrapper">
                {statsData.boxes && statsData.boxes.map((b) => (
                  <StatsBubble key={b.label} label={b.label} value={b.value} valueUnit={b.valueUnit}
                               arrow={b.arrow} rawValue={b.rawValue}
                               dateRange={b.dateRange}
                  />
                ))}
              </div>
            </div>
          }
        </div>
      }
      </div>
    </div>
  );
}
