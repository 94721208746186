import React, {useState, useEffect} from 'react';
import * as S from '../../../../styles/core-styles/AdminTools-styles';

export default function Search(props){
    const[activeSearch, setActiveSearch] = useState(undefined)
    const {allValues, setMatchingValues, reloadTable, skipPageResetRef} = props
    useEffect(()=>{
            let matching = allValues;
            if (activeSearch !== undefined && activeSearch !== '') {
              let searchTerm = activeSearch.toString().toLowerCase();
                const matchingValues = matching.filter((entry) =>
                Object.values(entry).some((val) =>
                  `${val}`.toLowerCase().includes(searchTerm)
              ));
              setMatchingValues(matchingValues)
            } else {
              return setMatchingValues(allValues);
            }
          if (reloadTable) reloadTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeSearch, allValues]);
    
    //skipPageResetRef control needs to be seperate to prevent table page from resetting when allValues is updated.
    useEffect(() => {
      if(skipPageResetRef) skipPageResetRef.current = true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeSearch])

    return(
      <S.SearchBar>
        <S.SearchInput value={activeSearch ? activeSearch : ""} onChange={(e) => setActiveSearch(e.target.value)} placeholder = 'Search'/>
      </S.SearchBar>
    );

}