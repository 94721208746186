import React, {useMemo} from "react";
import CandidatesTable from "./CandidatesTable";

export default function CandidatesTableRenderer(props) {
  const {bevs, phevs, clickCandidate, userSettings} = props;

  const boolSort = useMemo(() => (rowA, rowB, columnId) => {
      const a = rowA.values[columnId].props.value;
      const b = rowB.values[columnId].props.value;
      return a > b ? 1 : -1;
},[]);

  const CandidateVehicleTableColumns = [
    { Header: 'Selected', accessor: 'is_selected', sortType: boolSort, width: 100 },
    { Header: 'Model', accessor: 'ymm', width: 350 },
    { Header: 'Class', accessor: 'vehicle_class', width: 150 },
    { Header: 'Price', accessor: 'net_price', width: 150 },
    { Header: 'Total Battery Capacity\n(kWh)', accessor: 'battery_capacity'},
    { Header: 'Special Vehicle\nConfigurations', accessor: 'upfits', width: 400}
  ]


  function addSelectedCheckbox(vehicles) {
    if (!vehicles) {return null}
    const immutableList = JSON.parse(JSON.stringify(vehicles))
    return immutableList.map((v) => {
      v.is_selected = <input className="ezev-selected-checkbox" type='checkbox' id={v.pkid} checked={v.is_selected} value={v.is_selected} readOnly />
      return v;
    })
  }

  return (
    <div>
      <div className="ezev-tables-wrapper">
        <div className="ezev-bev-table-wrapper">
          <CandidatesTable
            columns={CandidateVehicleTableColumns}
            data={addSelectedCheckbox(bevs)}
            handleClick={clickCandidate}
            tableHeader={'Battery Electric Vehicles'}
            userSettings={userSettings}
          />
        </div>
        <div className="ezev-phev-table-wrapper">
          <CandidatesTable
            columns={CandidateVehicleTableColumns}
            data={addSelectedCheckbox(phevs)}
            handleClick={clickCandidate}
            tableHeader={'Plug-In Hybrid Electric Vehicles'}
            userSettings={userSettings}
           />
        </div>
      </div>
    </div>
  );
}

