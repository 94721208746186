export function columnsToHeaders(columns: any) {
    return (columns.filter((column: any) => column.hasOwnProperty("simpleHeader")).map((column: any) => column.simpleHeader))
}

export function rowsToData(columns: Array<any>, rows: Array<any>, sort: (a: any, b: any) => number) {
    const filteredColumns = columns.filter((column: any) => column.hasOwnProperty("simpleHeader"))
    const sortedRows = rows.sort(sort)
    return sortedRows.map((row: any) => filteredColumns.map((column: any) => column.accessor(row)))



}