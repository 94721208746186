
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { DateTime} from 'luxon';
//import { getLongMonth } from "../../utils/ezio-utils/GraphUtils"

type GraphMonthlyProps = {
  monthlyPeaks: {
    month: string;
    peakKw: number;
    rawTime: string
    timeOfPeak: number
    vclsCharging: string,
    longDate: string
  }[],
  displayTitle: boolean
}

type GraphDataStandardized = {
  labels: string[],
  datasets: [{
    label: string,
    data: number[],
    backgroundColor: string,
    hoverBackgroundColor: string,
    borderColor: string,
    borderWidth: number
  }],
  orig: Array<any>
}

export default function GraphMonthly({ monthlyPeaks, displayTitle = true }: GraphMonthlyProps) {
  const [graphData, setGraphData] = useState<GraphDataStandardized>();

  useEffect(() => {
    let graphData = standardizeData()
    setGraphData(graphData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyPeaks])

  function standardizeData(): GraphDataStandardized {
    let values: number[] = [];
    let labels: string[] = [];
    let orig: any[] = [];
    for(const p in monthlyPeaks){
      monthlyPeaks[p].month = monthlyPeaks[p].month.replace("-", "/");
      orig.push(monthlyPeaks[p]);
      labels.push(monthlyPeaks[p]["longDate"]);
      values.push(monthlyPeaks[p].peakKw);
    }
    return {
      labels: labels,
      datasets: [
        {
          label: "Peak kW Demand",
          data: values,
          backgroundColor: "rgba(3, 94, 91, 0.6)",
          hoverBackgroundColor: "rgba(3, 94, 91, 0.8)",
          borderColor: "rgba(3, 94, 91, 0.7)",
          borderWidth: 1,
        },
      ],
      // Need data in this object for the click through to daily peak demand graph
      orig: orig,
    }
  }

  function monthYearToRange(monthYear: string) {
    const [year, month] = monthYear.split("/");
    const minDate = DateTime.utc(parseInt(`20${year}`), parseInt(month),1);
    const maxDate = DateTime.utc(parseInt(`20${year}`), parseInt(month)).endOf('month');
    return {
      min: minDate,
      max: maxDate
    }

  }

  const history = useHistory()
  function handleGraphClick(evt: any, el: any) {
    //Fixes error when empty graph is clicked on
    if (!el[0]) {
      return
    }
    //If there's an easier-to-access instance of the data on a given bar than this, I haven't found it.
    const bounds = monthYearToRange(graphData?.orig[el[0].element.$context.index].month);

    const go = () => {
        history.push({
            pathname: `/ezio/kw-demand-daily/${window.location.pathname.split('/')[3]}`,
            state: {dateBounds: bounds}
        });
    }
    //adding a very small delay to nav so chart.js can access its afterclick handler before it's torn down.
    setTimeout(()=>{go()}, 150);
    return;
  }

  if (graphData) {
    return (<Bar
      id="overview-graph"
      data={graphData}
      width={600}
      height={400}
      options={{
        transitions: {
          active: {
            animation: {
              duration: 0
            }
          }
        },
        onClick: (elems: any, el: any) => handleGraphClick(elems, el),
        onHover: (elems: any, el: any) => {
          document.getElementById("overview-graph")!.style.cursor = el.length > 0 ? "pointer" : "initial"
        },
        plugins: {
          title: { text: `kW Demand Peaks`, display: displayTitle },
          legend: {
            display: false
          },
        },
        scales: {
          y: {
            display: true,
            title: {
              display: true,
              text: "Peak kW"
            },

          },
          x: {
            display: true,

          },
        }
      }}
    />)
  }

  return (

    <></>

  )
}

// To add hover: in plugins          
// tooltip: {
//   callbacks: {
//     beforeLabel: function(item:any, data:any){
//       return 'Vehicles charging:'
//     }
//   }
// }