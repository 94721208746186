import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import SawatchLogo from '../../images/sawatch-logo-white.png'

const trademark = `\u2122`;

const styles = StyleSheet.create({
    footerContainer: {
      backgroundColor: "#12365b",
      height: "70px",
      width: '100%',
      position: 'absolute',
      bottom: 0,
      color: 'white',
      fontSize: 7,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 22
    },
      footerLogoContainer: {
        height: 40,
        padding: 5
      },
      footerLogo: {
        width: 70
      },
})


export default function PdfFooter(props) {

    return (
        <View style={styles.footerContainer}>
            <View style={styles.footerLogoContainer}>
                <Image style={styles.footerLogo} src={SawatchLogo} alt="Sawatch Logo" />
            </View>
            <View style={styles.textWrapper}>
              <Text>info@sawatchlabs.com | (303) 578-2465 | https://sawatchlabs.com</Text>
              <Text>&#169; {new Date().getFullYear()} Sawatch Labs, a WEX{trademark} company. All rights reserved</Text>
            </View>
        </View>
    )
}

