import React from "react";
import SawatchLogo from '../../images/sawatch-labs-logo-black.png'

function HeaderLanding(props) {
  var groupName = props.groupName;
  return (
    <div id="ezev-header">
      <img
        className="ezev-ic-ezev"
        src="https://images.sawatchlabs.com/ezev-logo.png"
        alt="ezEV Logo"
      />
      <a
        href="https://sawatchlabs.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="ezev-ic-sawatch"
          src={SawatchLogo}
          alt="Sawatch Labs Logo"
        />
      </a>
      <div className="ezev-heading-text">
        {props.groupName !== "" && (
          <>
            <h1 className="ezev-heading H1-ezEV">Suitability Assessment</h1>
            <h1 className="ezev-heading H1-ezEV ezev-secondary-header">
              <span className="client">{groupName}</span>
            </h1>
          </>
        )}
      </div>
    </div>
  );
}

export default HeaderLanding;
