import styled, { keyframes } from "styled-components/macro"
import { ReactComponent as DownArrow } from "../../images/navdownarrow-10px-12px.svg"
import React from 'react';
import * as colors from "./ColorScheme";

const MEDIUM_VIEW_BREAKPOINT = "1300px";
const SMALL_VIEW_BREAKPOINT = "700px";

const breatheAnimation = keyframes`
 0% { transform: scale(1) }
 25% { transform: scale(1.15) }
 50% { transform: scale(1) }
 75% { transform: scale(0.95) }
 100% { transform: scale(1)}
`

export const ControlBoxRow = styled.div`
    z-index : 1000;
    display:flex;
    flex-direction: row;
    @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
        width: 700px;
        flex-wrap: wrap;
        row-gap: 10px;
    }
    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        width: max-content;
        flex-direction: column;
    }
`;

export const ControlBox = styled.div`
background: #FFFFFF;
border: 2px solid ${colors.ezio_light_grey};
box-sizing: border-box;
font-family: "Arimo", "Roboto";
font-size: 18px;
line-height: 21px;
margin: 0 1em;
padding: 0 1em;
height: 46px;
display: flex;
justify-content: center;
align-items: center;
`

const UpdateButton = styled.img`
    cursor: pointer;
background-color: white;
border: none;
height: 27px;
width: 25px;
margin: 4px;
background-repeat: no-repeat;
background-size: auto 100%;
`

export const UpdateButtonGray = styled(UpdateButton)`

`
export const UpdateButtonActive = styled(UpdateButton)`
animation-name: ${breatheAnimation};
animation-duration: 1.5s;
animation-iteration-count: infinite;

`

export const ControlItem = styled.div`
padding: 0 0.5em;
position: relative;
width: min-content;
`

export const VerticalRule = styled.div`
    width: 2px;
    height: 40px;
    background-color: ${colors.ezio_light_grey};

    @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
        display: none;
    }
`

export const DropdownContainer = styled.div`
position: absolute;
top:100%;
left: 0;
height: 10rem;
overflow-y: auto;
overflow-x: hidden;
`

//Styles shared by dropdown components

export const OptionWrapper = styled.div`
display: flex;
font-size: 16px;`


export const BulletPoint = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 18px;
width: 16px;
color: ${colors.ezio_dark_green};`

export const ChargeTypeWrapper = styled.div`
display: inline-block;
cursor: pointer;
`

const MenuButton = styled.div`
cursor: pointer;
font-family: Arimo;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
color: ${colors.ezio_dark_grey};
height: 40px;
background: #FFFFFF;
border: 2px solid ${colors.ezio_light_grey};
box-sizing: border-box;
border-radius: 5px;
white-space: nowrap;
overflow: hidden;`

export const MenuText = styled.div`
margin-left: 16px;
display: inline-block;
justify-content: flex-start;
align-items: center;
overflow: hidden;
text-overflow: ellipsis;
height: 100%;
line-height: 36px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;

`

export const GroupsMenuText = styled(MenuText)`
width: 200px;
`

export const CategoryMenuText = styled(MenuText)`
width: 200px;
`

export const LocationsMenuText = styled(MenuText)`
width: 200px;`

export const ElectrificationMenuText = styled(MenuText)`
width: 140px;`



export const ClassesMenuText = styled(MenuText)`
width: 100px;`


export const SelectedLabel = styled.div`
height: 100%;`


export const CategoryMenuButton = styled(MenuButton)`
width: 261px;
`

export const GroupsMenuButton = styled(MenuButton)`
width: 261px;
`

export const LocationsMenuButton = styled(MenuButton)`
width: 261px;
`

export const ElectrificationMenuButton = styled(MenuButton)`
width: 184px;
`


export const ClassesMenuButton = styled(MenuButton)`
width: 125px;
`

export const DownArrowWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
bottom: 1px;
right: 10px;
position: absolute;
height: 100%;
width: 14px;

`

//Not exactly a styled component, but exists purely as style and goes in multiple places so fits here
export const CustomDownChevron = () => <DownArrowWrapper>
    <DownArrow />
</DownArrowWrapper>

//This is how react-select wants you to style it
export const SelectStyles = {
    valueContainer: (base: any) => ({
        ...base,
        cursor: "text",
        paddingLeft: "13px",
        margin: "5px",
        height: "27px",
        border: "1px solid #4A4A4A",
        borderRadius: "5px",
        fontSize: "12px",
        lineHeight: "14px"
    }),
    container: (base: any) => ({
        ...base,
        cursor: "pointer",
        width: "15rem",
        fontFamily: "Arimo",
        fontSize: "20px",

    }),

    control: (base: any, { isFocused }: any) => ({
        ...base,
        fontFamily: "Arimo",
        border: isFocused ? 0 : 0,
        // This line disable the blue border
        boxShadow: isFocused ? 0 : 0,
        '&:hover': {
            border: isFocused ? 0 : 0
        },
        minWidth: "240",
        margin: "8",
    }),
    option: (base: any, { isFocused, isSelected, isDisabled }: { isFocused: boolean, isSelected: boolean, isDisabled: boolean }) => ({
        ...base,
        cursor: "pointer",
        fontFamily: "Arimo",
        fontSize: "16px",
        color: isDisabled ? colors.ezio_medium_grey : "inherit",
        padding: "8px 0",
        ":hover": {
            backgroundColor: isDisabled ? null : "rgba(3, 94, 91, 0.2)",
            cursor: isDisabled ? "default": "pointer"
        },
        backgroundColor: isSelected
            ? "rgba(3, 94, 91, 0.2)"
            : null,
        ":active": {
            backgroundColor: null
        }
    }),
    noOptionsMessage: (base: any) => ({
        ...base,
        fontFamily: "Arimo",
        fontSize: "16px",

    }),

    menuList: (base: any) => ({
        ...base,

        maxHeight: "300px",
        overflow: "overlay",

        "::-webkit-scrollbar": {
            width: "5px"
        },
        "::-webkit-scrollbar-track": {
            background: "transparent"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "10825432px"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    menu: () => ({

        marginTop: "0",
    }),
    placeholder: (base: any) => ({
        ...base,
        verticalAlign: "middle"
    }
    )



}
