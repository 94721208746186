import styled from "styled-components";

export const TableRow = styled.tr`
  height: 50px;
  background-color: #fff;
  min-height: 50px;

  &:nth-child(odd) {
    background-color: var(--ezev-light-grey);
  }

  &:hover > td {
    text-decoration: underline;
    cursor: pointer;
    color: #259cc4;
  }
`;

export const TableContainer = styled.div`
  max-height: 440px;
  overflow: auto;
`

export const StyledTable = styled.table`
  border-collapse: collapse;
  font-family: "Arimo","Open Sans","HelveticaNeue","Helvetica Neue","Arial","sans-serif";
  width: 100%;
`;

export const TableHeadRow = styled.thead`
  position: sticky;
  top: 0;
  z-index: 10;
`

export const TableHeader = styled.th`
  min-height: 10vh;
  background-color: #72afc4;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  letter-spacing: .16px;
  color: #000000;
  cursor: pointer;
  padding: 0px 5px 7px;
  white-space: break-spaces;

  &:first-child {
    position: sticky;
    left: 0;
  }

  &:nth-child(2) {
    position: sticky;
    left: ${(props) => props?.columnsWidth ? `${props.columnsWidth[0]}px` : '250px'}
  }

`;


export const TableCell = styled.td`
  color: ${(props) => props.selected ? 'black' : '#cdcfd1'};
  font-size: 16px;
  white-space: nowrap;
  padding: 11px 5px 7px;
  white-space: pre-wrap;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #259cc4;
  }

  &:first-child {
    position: sticky;
    left: 0;
    z-index: 5;
    background-color: inherit;
  }

  &:nth-child(2) {
    position: sticky;
    left: ${(props) => props?.columnsWidth ? `${props.columnsWidth[0]}px` : '250px'};
    background-color: inherit;
  }

`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

 
export const PaginationDetails = styled.span`
  padding: 0 5px;
  font-size: 12px;
  color: #74787d;
  letter-spacing: .1px;
`;

export const PaginationButton = styled.button`
  width: 65px;
  height: 20px;
  padding: 0 5px;
  border: 0.7px solid #979a9e;
  border-radius: 9px;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .3px;
  text-align: center;
  color: #74787d;
  text-decoration: none;
  cursor: pointer;
  transition: all ease-in-out .2s;
  &:disabled {
    cursor:default;
    color: rgba(16, 16, 16, 0.3);
    border-color: rgba(16, 16, 16, 0.2);
  }
  &:hover:not([disabled]) {
    border-color: #262627;
    color: #262627;
    transform: scale(1.02);
    transition: all ease-in-out .2s;
  }
`;

export const TableNoDataMessage = styled.p`
  font-size: 16px;
  color: #4a4a4a;
  white-space: nowrap;
  margin: 0;
  padding: 10px 0;
  text-align: center;
`;

export const TableSortArrows = styled.span`
  padding-left: 4px;
  font-size: 7px;
`

export const SortArrowUp = styled.img`
  padding-bottom: 5px;
  height: 13px;
`;

export const SortArrowDown = styled.img`
  height: 13px;
`

export const SortArrowUnsel = styled.img`
  height: 13px;
`

export const CandidatesTableHeader = styled.h2`
font-size: 24px;
font-style: normal;
font-stretch: normal;
font-weight: normal;
line-height: 1.35;
letter-spacing: 0.1px;
text-align: left;
padding: 11px 5px 7px;
`

export const CandidatesTableHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 7vh;
  background-color: #72afc4;
`

