import React from "react";
import descArrow from '../../images/ic-arrow-desc-green-30px-19px.png';
import ascArrow from '../../images/ic-arrow-asc-red-30px-17px.png';

export default function StatsBubble(props) {
  return (
    <div className="swt-ionev-stats-box">
      <div className="swt-ionev-stats-box-value">
        {(props.arrow && props.rawValue!== 0 && !isNaN(props.rawValue)) &&
          <img className={`ionev-change-arrow ${Math.round(props.rawValue) > 0 ? "desc" : "asc"}`} src={Math.round(props.rawValue) > 0 ? descArrow : ascArrow} alt="Cost difference arrow"/>
        }
        {props.value}
        {props.valueUnit && <span className="swt-ionev-stats-box-value-unit"> {props.valueUnit}</span>}
      </div>
      <div className="swt-ionev-stats-box-label">
        {props.dateRange &&
          <div className="tco-label-total-fleet-period">
            {props.dateRange}
          </div>
        }
        {props.label}
      </div>
    </div>
  );
}
