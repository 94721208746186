import React from "react";
import MapContainer from "./MapContainer";
import { dateAsNonTZString } from "./UtilityFunctions";
import { processApiResponse } from "./utils/ConformUnits";
import { Roles } from "../core-components/utility-components/UserUtils";

class MapController extends React.Component {
  constructor(props) {
    super(props);
    var selVcl = "";
    var selLoc = "";
    if (
      props.location &&
      props.location.state &&
      props.location.state.selectedVcl
    )
      selVcl = props.location.state.selectedVcl;
    if (
      props.location &&
      props.location.state &&
      props.location.state.selectedLoc
    )
      selLoc = props.location.state.selectedLoc;
    this.state = {
      locationActivity: [],
      chargeActivity: [],
      missedOpsFleetActivity: [],
      missedOpsPubActivity: [],
      selectedVcl: selVcl,
      selectedLoc: selLoc,
      calculatedMapCenter: [],
      controllerIsLoading: true
    };
    this.handleSelectorChange = this.handleSelectorChange.bind(this);
    this.handleSelectorReset = this.handleSelectorReset.bind(this);
  }
  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // NOTE this could be cleaner when fetching map data
    this.props.activeLinkChange("activeLink", "map");
    this.fetchData(this.props.user);
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.locUpdated
    ) {
      this.props.fetchMapData();
    }
  }

  componentDidUpdate(nextProps, nextState) {
    const user = this.props.user;
    if (
      nextProps.startDate !== this.props.startDate ||
      nextProps.endDate !== this.props.endDate
    ) {
      this.fetchData(user);
    }
    if (nextState.selectedVcl !== this.state.selectedVcl) {
      this.fetchData(user);
    }
    if (
      nextProps.chargeSummaryByLocation !==
        this.props.chargeSummaryByLocation ||
      nextProps.chargeEvents !== this.props.chargeEvents
    ) {
      this.fetchData(user);
    }
  }

  fetchData(user) {
    // not used now for charge events in general, still there for single vin
    if (this.state.selectedVcl !== "") {
      this.fetchEventsSummary(user);
      this.sortEventsForVcl(user); // these can be combined to set state once
      // leaving for now as fetch may change
    } else {
      if(user.role >= Roles.FleetAdmin){
        // if admin, show all
        var filteredChargeEvents = this.props.chargeEvents;
        var sortedMissedOpps = this.sortMissedOps(this.props.missedEvents);
      } else {
        // if not admin, filter out residential
        filteredChargeEvents = this.nonResEvents(this.props.chargeEvents);
        sortedMissedOpps = this.sortMissedOps(
          this.nonResEvents(this.props.missedEvents)
        );
      }
      if (!this.props.chargeSummaryByLocation) return; // remove this once restructure done
      var summaryData = this.combineSummaryData(
        this.props.chargeSummaryByLocation,
        this.props.missedSummaryByLocation,
        user
      );

      this.calcMapCenter(summaryData);

      this.setState({
        locationActivity: summaryData,
        chargeActivity: filteredChargeEvents,
        missedOpsFleetActivity: sortedMissedOpps.fleet,
        missedOpsPubActivity: sortedMissedOpps.pub,
        controllerIsLoading: false
      });
    }
  }

  handleSelectorChange(event) {
    var index = event.target.options.selectedIndex;
    var vin = event.target.options[index].getAttribute("data-key");
    this.setState({ selectedVcl: vin });
  }

  handleSelectorReset() {
    this.setState({ selectedVcl: "" });
  }

  getVclDetails() {
    var vcls = [{ id: "", value: "All Vehicles" }];
    this.props.totalActivitySummary.forEach((d) => {
      vcls.push({ id: d.vin, value: d.asset_id });
    });
    return vcls;
  }

  nonResEvents(data) {
    var events = [];
    data.forEach((e) => {
      if (!e.landuse || e.landuse.toLowerCase() !== "residential") {
        events.push(e);
      }
    });
    return events;
  }

  fetchEventsSummary(user) {
    // JUST FOR WHEN VIN
    var selectedVcl = this.state.selectedVcl;
    if (!selectedVcl) return;
    var exclRes = (user.role >= Roles.FleetAdmin) ? false : true; // if not fleet admin+, exclude residential
    var chargeByLocUrl = `${this.props.base_url}getChargeSummaryByLocation?`;
    var queries = `&clientId=${this.props.db}&start=${dateAsNonTZString(this.props.startDate)}&stop=${dateAsNonTZString(this.props.endDate)}&exclRes=${exclRes}&vin='${selectedVcl}'`;
    fetch(`${chargeByLocUrl}${queries}`, {
      headers: { Authorization: `Bearer ${this.props.user.token}` },
    })
      .then((res) => res.json())
      .then((chargeData) => {
        this.fetchMissedSummary(chargeData["data"], user);
      })
      .catch((error) => console.error("Error: " + error));
  }

  fetchMissedSummary(data, user) {
    // JUST FOR WHEN VIN - called by fetch events summary
    var selectedVcl = this.state.selectedVcl;
    if (!selectedVcl) return;
    var exclRes = user.role >= Roles.FleetAdmin ? false: true; // if not fleet admin+, exclude residential
    var missedUrl = `${this.props.base_url}getMissedSummaryByLocation?`;
    var queries = `&clientId=${this.props.db}&start=${dateAsNonTZString(this.props.startDate)}&stop=${dateAsNonTZString(this.props.endDate)}&exclRes=${exclRes}&vin='${selectedVcl}'`;
    fetch(`${missedUrl}${queries}`, {
      headers: { Authorization: `Bearer ${this.props.user.token}` },
    })
      .then((res) => res.json())
      .then((missedData) => {
        return this.combineSummaryData(data, missedData["data"], user);
      })
      .then((data) => {
        let processedData = data.map((i) => {return processApiResponse(this.props.user.userSettings, i)})
        this.setState({ locationActivity: processedData });
        if (data.length > 0) this.calcMapCenter(data);
      })
      .catch((error) => console.error("Error: " + error));
  }

  sortEventsForVcl(user) {
    // map over all charge events and filter on selected vehicle
    var charge = [];
    var missed = [];
    var selectedVcl = this.state.selectedVcl;
    this.props.chargeEvents.forEach((e) => {
      if (
        e.vin === selectedVcl &&
        e.landuse &&
        (user.role >= Roles.FleetAdmin || e.landuse.toLowerCase() !== "residential")
      )
        charge.push(e);
      return;
    });
    this.props.missedEvents.forEach((e) => {
      if (
        e.vin === selectedVcl &&
        e.landuse &&
        (user.role >= Roles.FleetAdmin || e.landuse.toLowerCase() !== "residential")
      )
        missed.push(e);
      return;
    });
    var sortedMissed = this.sortMissedOps(missed);
    // return {'charge': charge, 'missed': this.sortMissedOps(missed)};
    this.setState({
      chargeActivity: charge,
      missedOpsFleetActivity: sortedMissed["fleet"],
      missedOpsPubActivity: sortedMissed["pub"],
    });
  }

  calcMapCenter(data) {
    var lat = 0;
    var lon = 0;
    if (data) {
      var count = data.length;

      data.forEach((d) => {
        lat += d["st_y"];
        lon += d["st_x"];
      });

      if(count >= 2){
        lat = lat / count;
        lon = lon / count;
      }
    }

    this.setState({
      calculatedMapCenter: [lat, lon]
    });
  }

  sortMissedOps(data) {
    var missedFleet = [];
    var missedPub = [];
    data.forEach((o) => {
      if (o.fleet_evse > 0) {
        missedFleet.push(o);
      }
      if (o.afdc_evse) {
        missedPub.push(o);
      }
    });
    return { fleet: missedFleet, pub: missedPub };
  }

  combineSummaryData(charge, missed, user) {
    var nonRes = [];
    charge.forEach((c) => {
      // Temporary until decided re exclRes in EP
      if (!c.landuse || user.role >= Roles.FleetAdmin || c.landuse.toLowerCase() !== "residential") {
        // see if missed opp at charge loc
        var missedLoc = missed.filter((m) => m.charge_loc_id === c.pkid);
        if (missedLoc.length > 0) {
          // add to charge obj
          c["missed_distinct_vins"] = missedLoc[0]["missed_distinct_vins"];
          c["missed_event_count"] = missedLoc[0]["missed_event_count"];
          c["missed_avg_duration"] = missedLoc[0]["missed_avg_duration"];
          c["missed_avg_kwh"] = missedLoc[0]["missed_avg_kwh"];
          c["missed_avg_soc_start"] = missedLoc[0]["missed_avg_soc_start"];
          c["avg_missed_elec_km"] = missedLoc[0]["avg_missed_elec_km"];
          c["missed_avg_distance"] = missedLoc[0]["missed_avg_distance"];
        } else {
          c["missed_distinct_vins"] = 0;
          c["missed_event_count"] = 0;
          c["missed_avg_duration"] = 0;
          c["missed_avg_kwh"] = 0;
          c["missed_avg_soc_start"] = 0;
          c["avg_missed_elec_km"] = 0;
          c["missed_avg_distance"] = 0;
        }
        nonRes.push(c);
      }
    });
    missed.forEach((m) => {
      if (!m.landuse || user.role >= Roles.FleetAdmin || m.landuse.toLowerCase() !== "residential") {
        // see if missed opp at charge loc
        var chargeLoc = charge.filter((c) => m.charge_loc_id === c.pkid);
        if (chargeLoc.length > 0) {
          // missed opp already added in above loop
          return;
        } else {
          m["charge_event_count"] = 0;
          m["pkid"] = m["charge_loc_id"];
        }
        nonRes.push(m);
      }
    });
    return nonRes;
  }

  render() {
    var displayType =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.displayType
        ? this.props.location.state.displayType
        : "";
    var mapType =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.mapType
        ? this.props.location.state.mapType
        : "";
    var vclDetails = this.getVclDetails();
    return (
     <>
       {!this.state.controllerIsLoading && <MapContainer
          history={this.props.history}
          base_url={this.props.base_url}
          selectedVcl={this.state.selectedVcl}
          selectedLoc={this.state.selectedLoc}
          chargeActivity={this.state.chargeActivity}
          missedOpsFleetActivity={this.state.missedOpsFleetActivity}
          missedOpsPubActivity={this.state.missedOpsPubActivity}
          mapType={mapType}
          db={this.props.db}
          vclDetails={vclDetails}
          handleSelectorChange={this.handleSelectorChange}
          handleSelectorReset={this.handleSelectorReset}
          mapCenter={this.state.calculatedMapCenter}
          locationActivity={this.state.locationActivity}
          displayType={displayType}
          user={this.props.user}
          fuelCost={this.props.fuelCost}
        />}
     </>
    );
  }
}

export default MapController;