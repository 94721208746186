import React, {useState} from "react";
import * as S from "../../styles/ezio-styles/DateSelectors-styles";
import exclamationIcon from '../../images/exclamation.png'
import { DatePicker } from "@sawatchlabs/swt-components";
import { DateTime } from "luxon";
import * as ColorScheme from "../../styles/ezio-styles/ColorScheme";

type DateSelectorProps={ 
    dateBounds: any, 
    selectedDates: any, 
    handleChange: Function, 
    disabled?: boolean, 
    width?: string, 
    height?: string,
    fontSize?: string,
    userSettings: any,
    limitedDateRange?: boolean
}

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

export default function DateSelectors(
    { dateBounds, 
      selectedDates, 
      handleChange, 
      disabled,
      limitedDateRange,
      userSettings}: DateSelectorProps) {
    const [infoShown, setInfoShown] = useState(false);
    const DATE_BOUND_BUFFER = 4;

    function getMinForMaxSelector() {
        if(selectedDates.min.isValid) {
            // set min to five days after selected min date
            let fiveOut = DateTime.fromMillis(selectedDates.min.ts).setZone('UTC-0');
            fiveOut = DateTime.fromMillis(fiveOut.ts + (MILLISECONDS_IN_DAY*DATE_BOUND_BUFFER)).setZone('UTC-0');
            return (fiveOut);
        }
        return DateTime.now().setZone('utc');
    }

    function getMaxForMinSelector() {
        if(selectedDates.max.isValid) {
            // set max to five days before selected max date
            let fiveBack = DateTime.fromMillis(selectedDates.max.ts).setZone('UTC-0');
            fiveBack = DateTime.fromMillis(fiveBack.ts - (MILLISECONDS_IN_DAY*DATE_BOUND_BUFFER)).setZone('UTC-0');
            return (fiveBack);
        }
        return DateTime.now().setZone('utc');
    }

    // this empty string check needs to be removed! Currently renders component
    // before date load. Won't be possible in dashboard but should still be refactored
    return (
        <S.DateSelectorContainer>
            {(limitedDateRange && !disabled) &&
                <S.HeaderDateSelectorInfoBubble>
                    <S.InfoIcon 
                        src={exclamationIcon}
                        alt="Infomation Icon"
                        onMouseOver={() => setInfoShown(true)} 
                        onMouseOut={() => setInfoShown(false)} 
                    />
                    {infoShown &&
                        <S.InfoTextContainer>
                            <S.InfoText>Date range is limited to the dates selected below. Adjust the selection to expand the period.</S.InfoText>
                        </S.InfoTextContainer>}
                </S.HeaderDateSelectorInfoBubble>
            }
            < DatePicker //Min selector. Goes from start of data collection to min(selectedDates.max - 5, dateBounds - 5)d 
                inputDisabled={disabled}
                minDate={dateBounds['min']}
                maxDate={getMaxForMinSelector()}
                placeholder={"N/A"}
                selected={
                    selectedDates['min'] !== '' ? selectedDates['min'] : DateTime.utc()
                }
                onAccept={(e:any) => handleChange('min', e)}
                popperPlacement={"bottom"}
                dateFormat={userSettings.date_display}
                highlightColor={`${ColorScheme.ezio_dark_green}d0 !important`} // set as important to prevent a mysterious issue where the proper highlight color would only show up after the user had manipulated the component
                highlightHoverColor={`${ColorScheme.ezio_dark_green} !important`}
                textFieldMarginRight={"10px"}
            />
            < DatePicker // Max selector. Goes from selectedDates.min + 5 to  end of data collection
                inputDisabled={disabled}
                minDate={getMinForMaxSelector()}
                maxDate={dateBounds['max']}
                placeholder={"N/A"}
                selected={
                    selectedDates['max'] !== '' ? selectedDates['max'] : DateTime.utc()
                }
                onAccept={(e:any) => handleChange('max', e)}
                popperPlacement={"bottom"}
                dateFormat={userSettings.date_display}
                highlightColor={`${ColorScheme.ezio_dark_green}d0 !important`}
                highlightHoverColor={`${ColorScheme.ezio_dark_green} !important`}
            />
        </S.DateSelectorContainer>
    )
}