import React, { useState, useMemo, useEffect, useRef, useCallback } from "react";
import { BrowserRouter as Router, 
    Route,
    useLocation,  
    Redirect,
    Switch,
    useHistory, } from "react-router-dom";
import LandingPage from "./ezio-components/LandingPage";
import LocationsDisplay from "./ezio-components/LocationsDisplay";
import LocationSummary from "./ezio-components/LocationSummary";
import Loading from "./ezio-components/Loading";
import AboutPage from "./ezio-components/AboutPage";
import Header from "./ezio-components/Header";
import Controls from "./ezio-components/Controls";
import LocationDetail from "./ezio-components/LocationDetail";
import * as S from "../styles/ezio-styles/Ezio-styles";
import "../styles/ezio-styles/ezio.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { processApiResponse, processRateTime, dateAsNonTZString } from "../utils/ezio-utils/ConformUnits";
import { consistentElectrificationSort } from "../utils/ezio-utils/CustomSortUtils";
import CountDownView from "./ezio-components/CountDownView";
import ActiveProcessingPage from "./ezio-components/ActiveProcessingPage";
import { DateTime } from "luxon"
import { checkIfLocationDetailpage } from "./ezio-components/HelperFunctions";
import InvalidDetailPage from "./ezio-components/InvalidDetailPage";

const LARGE_DATA_SET_THRESHOLD = 50000; //charge event count that limits initial date range
const MINIMUM_DAYS_FOR_MONTH = 2; //if we don't have at least this many days in the last month, don't show
const LARGE_DATE_SET_INIT_DAYS = 180; //days to load for large data set on init
const SECONDS_PER_DAY = 86400;
const EXISTING_TS_MARGINS = SECONDS_PER_DAY * 1000; //time in milliseconds in slop on existing data min/max ts
const INIT_ELEC_LEVEL = 50; //electrification level at init
const MAX_WORKERS = 8;
const MULTI_THREADING_ENABLED = true;
const PROCESS_LOOP_CADENCE = 50; //cadence at which to check completness and kickoff workers in milliseconds
const POST_REQ_THRESHOLD = 256; //number of locations at which the getKWDemand Call should be a POST req.
const PROCESSING_EVENTS_PER_SECOND = 14000;
const MINIMUM_EVENT_KWH = 0.5; //min kWh for a charge event to be valid
const MAX_FAILURES_TO_UPDATE_PROCESSING_ALLOWED = 5;
const UPDATE_MILLISECONDS = 1000;

let immutableChargeLocations = new Map();
let active_workers = [];
let processing = false;
let benchmark = false;

const CATEGORIES = [
    {id: 0, label: "All Vehicles"},
    {id: 1, label: "Light Duty"},
    {id: 2, label: "Medium and Heavy Duty"}
]

export type kWhUsageSummaries = {
    monthlyPeaks: any,
    todSummaries: any
}

export type ChargeLocation = {
    pkid: number,
    address: string,
    latitude: number,
    longitude: number,
    chargingVehiclesCount: number,
    chargingVehiclesVins?: Array<string>,
    chargeDurationSeconds: number,
    chargeDayCount: number,
    chargeEvents: Array<Object>, //charge events within filters
    _chargeEvents: Array<Object>, //all charge events
    peakKw: number,
    //smoothedPeakKW: number,
    peakCost: number,
    monthlyPeaks: any,
    todSummaries: any,
    todSmartSummaries: any,
    todPeakSmoothedSummaries: any,
    evRecommendationCount: number,
    maxVehiclesCharging: number,
    chargeEventCount: number,
    vehicleResults: Array<any>,
    presentationEvents: Array<Object>,
    vins: Array<String>,
    summary: any,
    pkids: Array<number>,
    locationEmpty: boolean,
    isSelected: boolean,
    vehicleHomebaseCount: number,
    homebaseVins: Array<string>,
    atLocationChargingKwh: number,
    notAtLocationChargingKwh: number,
    drawHistogram: Array<number>
}

export type KwhDetails = {
    kWh: number,
    cost: number,
    localMonth: string
}

export type ChangeElectrificationAction = {
    electrification: number
}
  
export type ChangeClassAction = {
    classes: Array<any>
}
  
export type ChangeLocationAction = {
    location: number
}
  
export type ChangeCategoryAction = {
    category: string
}
  
export type ChangeGroupAction = {
    group?: string
}
export type ControlsState = ChangeElectrificationAction & ChangeClassAction & ChangeLocationAction & ChangeCategoryAction & ChangeGroupAction

export type GraphProps = {
    dbName: string
    dateBounds: { min: string, max: string }
    user: {
      token: string
    }
    apiURL: string
}

function EzioApp({ apiURL, dbName, user, dbDisplayName, devState }: any){
    const [apiError, setApiError] = useState(false);
    const [groupError, setGroupError] = useState(false);
    const [isAggregateProcessing, setIsAggregateProcessing] = useState(false);
    const [isAnalyticsProcessing, setIsAnalyticsProcessing] = useState(false);
    const [processingCountdownFinished, setProcessingCountdownFinished] = useState(null);
    const [minDate, setMinDate] = useState<DateTime>();
    const [maxDate, setMaxDate] = useState<DateTime>();
    const [selectedBeginDate, setSelectedBeginDate] = useState<DateTime>();
    const [selectedEndDate, setSelectedEndDate] = useState<DateTime>();
    const [limitedDateRange, setLimitedDateRange] = useState<boolean>(false);
    const [isLd, setIsLd] = useState<any>(null);
    const [vehicles, setVehicles] = useState<Array<any>>();

    const [selectedCategory, setSelectedCategory] = useState(CATEGORIES[0]); 
    const [groups, setGroups] = useState<Array<any>>([]);
    const [electrification, setElectrification] = useState<number>(INIT_ELEC_LEVEL);
    const [selectedGroup, setSelectedGroup] = useState<any>();
    const [vehicleClassesPresent, setVehicleClassesPresent] = useState<Array<string>>();
    const [selectedVehicleClasses, setSelectedVehicleClasses] = useState<Array<string>>([]);
    const [totalVehicleCount, setTotalVehicleCount] = useState<number>(0);
    const [processEventsCountdown, setProcessEventsCountdown] = useState<number>(0);

    //location arrays. these are not time bounded
    const [ungroupedLocations, setUngroupedLocations] = useState<Array<any>>();
    const [groupedLocations, setGroupedLocations] = useState<Array<any>>();

    const [chargeLocations, setChargeLocations] = useState<Map<number, ChargeLocation>>();
    const [selectedChargeLocation, setSelectedChargeLocation] = useState<number>(-1);
    const [applyControl, setApplyControl] = useState<boolean>(false);
    const [loadChargeLocations, setLoadChargeLocations] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [simpleKWHRate, setSimpleKWHRate] = useState<number>(null);
    const [rateSchedules, setRateSchedules] = useState<Array<any>>([]);
    const [onInvalidDetailPage,setOnInvalidDetailPage] = useState<boolean>(false);

    const [selectedVins, setSelectedVins] = useState<Array<string>>([]); // Selected vins based off of controls, to be prop drilled to childred

    const location = useLocation();
    const history = useHistory();
    const countDown = useRef(0);
    const totalEventCount = useRef(0);
    const threads_required = useRef(0);
    const workers = useRef([]);
    const now = useRef(DateTime.now());

    if(devState.toLowerCase() !== "production")benchmark = true;

    const req = useMemo(() => {
        return { user: user,
                 dbName: dbName,
                 apiURL: apiURL, 
                 beginDate: selectedBeginDate,
                 endDate: selectedEndDate,
                 simpleKWHRate: simpleKWHRate,
                 rateSchedules: rateSchedules,
                 MINIMUM_EVENT_KWH: MINIMUM_EVENT_KWH 
                }
      }, [user, dbName, apiURL, selectedBeginDate, selectedEndDate, simpleKWHRate, rateSchedules]);

    const isDaylightSavings = (date) => {
        const dstDates = [
          {start: '2024-03-10', end: '2024-11-03'},
          {start: '2025-03-09', end: '2025-11-02'},
          {start: '2026-03-08', end: '2026-11-01'},
        ];
        let dst = true;
        dstDates.forEach((d) => {
            if(date.ts >= DateTime.fromISO(d.start).setZone("UTC-7").ts // Use MT 
            && date.ts <= DateTime.fromISO(d.end).setZone("UTC-7").ts)dst = true;
        });
        return dst;
      }

    const processUTCDateBounds = (dates) => {
        if(!dates || !dates.data){
          return {'utcStart': DateTime.utc(), 'utcEnd': DateTime.utc()};
        };
        const o = dates.data[0];
        const eventOffset = isDaylightSavings(DateTime.now().setZone("UTC-7")) ? o.min_offset : o.max_offset;
        let zone = `UTC-0`;
        const utcStart = DateTime.fromMillis(o.min_epoch).setZone(zone);
        const utcEnd = DateTime.fromMillis(o.max_epoch).setZone(zone);
        let hours = eventOffset / 60;
        zone = (hours >= 0) ? `UTC+${hours}` : `UTC${hours}`;
        //add in the offset millis because the bespoke endpoint is sending midnight utc as a date part
        //the intention is we start at local midnight of the relevant day, not re-adjusted by zone
        //but we still want it to be timezone aware.
        const eventStart = DateTime.fromMillis(o.min_epoch + (-1*hours*60*60*1000)).setZone(zone);
        const eventEnd = DateTime.fromMillis(o.max_epoch + (-1*hours*60*60*1000)).setZone(zone);
        return {'utcStart': utcStart, 'utcEnd': utcEnd, eventStart: eventStart, eventEnd: eventEnd};
    }

    const locationPen = useMemo(() => {
        const m = new Map();
        return m;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        const processEventsInterval = setInterval(() => {
            countDown.current++;
            const secondsLeft = Math.max(0, ((totalEventCount.current / PROCESSING_EVENTS_PER_SECOND) - countDown.current));
            setProcessEventsCountdown(secondsLeft);
        }, UPDATE_MILLISECONDS);
      
        return () => {
          clearInterval(processEventsInterval);
        };
    }, []);

    //this hook cleans up after the app is closed
    //only really testable in the dashboard.
    useEffect(() => {
        return () => {
            if(benchmark)console.info("clean up", workers.current.length);
            workers.current.forEach((cl) => cl.terminate());
            workers.current = [];
        }
    }, []);

    useEffect(() => {
        // Register a listener to the history object
        const unlisten = history.listen((location, action) => {
            let path = location.pathname;
            // if the user is going back (ie. new history event action was a pop), make sure they haven't updated their charge location to be all locations.
            // If they have, set the onInvalidDetailPage flag to show them the invalid page message.
            if (action === 'POP' && checkIfLocationDetailpage(path)) { 
                if (selectedChargeLocation === -1) {
                    setOnInvalidDetailPage(true);
                }
            } else {
                setOnInvalidDetailPage(false); // If the user is going to another page, turn off the onInvalidDetailPage flag to hide the message.
            }
        });
    
        // Cleanup the listener when the component unmounts
        return () => unlisten();
    
    }, [history,selectedChargeLocation]);

    useEffect(() => {
        //return empty if Worker is not a thing, ie in a testing env
        if(typeof Worker === 'undefined')return;
        while((active_workers.length + workers.current.length) < MAX_WORKERS){
            const options = {name: `worker-${workers.current.length+1}`};
            const clworker = new Worker(new URL('./ezio-components/cl-worker.js', import.meta.url), options);
            clworker.onmessage = function(e:any) {
                handleChargeLocations(e.data, this);
            }
            workers.current.push(clworker);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processing]);

    useEffect(() => {
        if (processingCountdownFinished) {
            let failures_to_update = 0;
            const interval = setInterval(async () => {
                const isProcessing = await getAnalyticsProcessingState();
                
                if (!isProcessing) { 
                    // Set various states when isProcessing is false
                    setIsAnalyticsProcessing(false);
                    setProcessingCountdownFinished(null);
                    clearInterval(interval); // Break out of the interval loop
                    return; // Exit early since we no longer need to continue the interval
                } else {
                    failures_to_update += 1;
                }
                
                if (failures_to_update >= MAX_FAILURES_TO_UPDATE_PROCESSING_ALLOWED) {
                    if (isProcessing) {
                        setApiError(true); // If still processing after 5 seconds, set ApiError to true
                    }
                    clearInterval(interval); // Stop the interval after 5 seconds
                }
            }, UPDATE_MILLISECONDS); // Every 1 second
            
            return () => clearInterval(interval); // Cleanup
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processingCountdownFinished]); // Dependency array

    useEffect(() => {
        if (isAggregateProcessing || isAnalyticsProcessing || apiError) return;
        else {
        let p = [
            fetch(`${req.apiURL}getPeriodObserved?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            fetch(`${req.apiURL}getVehicleClassesPresent?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            fetch(`${req.apiURL}getUngroupedLocations?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            fetch(`${req.apiURL}getGroupedLocations?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            fetch(`${req.apiURL}getSettings?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            fetch(`${req.apiURL}getKwhRates?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            fetch(`${req.apiURL}getEzioSelectedVehicles?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json())
        ];
        Promise.all(p)
            .then(([boundsResp, vehicleClassesResp, ungroupedResp, groupedResp, settingsResp, kwhRatesResp, vehicleCountResp]) => {
                const dates = processUTCDateBounds(boundsResp);
                setMinDate(dates.utcStart);
                setMaxDate(dates.utcEnd);
                setSelectedBeginDate(dates.utcStart)
                setSelectedEndDate(dates.utcEnd);

                kwhRatesResp.data.forEach((r) => {
                    r = processApiResponse(user.userSettings, r);
                    r = processRateTime(user.userSettings, r);
                })
                const classes = vehicleClassesResp.data.map((c: any) => {return c.vehicle_class});
                setVehicleClassesPresent(classes);
                setSelectedVehicleClasses(["All Classes"]);
                setUngroupedLocations(ungroupedResp.data);
                setGroupedLocations(groupedResp.data);

                if(settingsResp.data.length > 0 && settingsResp.data[0]){
                    // conform db settings to user settings
                    settingsResp.data[0] = processApiResponse(user.userSettings, settingsResp.data[0]);
                    if (settingsResp.data[0].local_kwh_cost !== undefined && settingsResp.data[0].local_kwh_cost !== null) {
                        setSimpleKWHRate(settingsResp.data[0].local_kwh_cost);
                    }
                    else {
                        console.error("Settings did not return a valid local_kwh_cost");
                    }
                    if (settingsResp.data[0].electrification_pct !== undefined && settingsResp.data[0].electrification_pct !== null)
                        setElectrification(settingsResp.data[0].electrification_pct);
                }
                const sortedKwhRatesResp = sortRates(kwhRatesResp.data);
                setRateSchedules(sortedKwhRatesResp);
                setTotalVehicleCount(vehicleCountResp.data.length);           
            })
            .catch((err) => {
                console.error(err);
                setApiError(true);
            });
        }
            
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [req.user, req.apiURL, req.dbName, user.userSettings,isAnalyticsProcessing, isAggregateProcessing, apiError]);

    useMemo(()=>{
        if (isAggregateProcessing || isAnalyticsProcessing || apiError) return;
        let p = [
            fetch(`${req.apiURL}getGroups?dbName=${req.dbName}&isLd=${isLd}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
        ]
        if(isLd === null){
            p = [
                fetch(`${req.apiURL}getGroups?dbName=${req.dbName}`, {headers: { Authorization: `Bearer ${req.user.token}` }}).then((resp) => resp.json()),
            ] 
        }
        Promise.all(p)
            .then(([groupsResp]) => {
                // set name and label of swt-vehicles to 'All Groups'
                let idx = groupsResp.data.findIndex((g: any) => g.id === "swt-vehicles")
                if (idx !== -1) {
                    groupsResp.data[idx].label = 'All Groups';
                    groupsResp.data[idx].name = 'All Groups';
                }
                return groupsResp
            }).then((groupsResp) => {
                if(groupsResp.data.length === 0) return setGroupError(true)
                setGroups(groupsResp.data);
                if(selectedGroup && groupsResp.data.find((g: any) => g.pkid === selectedGroup.pkid)){
                    //pass
                }else{
                    if(groupsResp.data.find((g: any) => g.id === "swt-vehicles")){
                        setSelectedGroup(groupsResp.data.find((g: any) => g.id === "swt-vehicles"));
                        return;
                    }
                    setSelectedGroup(groupsResp.data[0]);
                }
            })
            .catch((err) => {
                console.error(err);
                setGroupError(true);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[req.user, req.apiURL, req.dbName, isLd, isAggregateProcessing,isAnalyticsProcessing, apiError])

    useMemo(() => {
        if (isAggregateProcessing || isAnalyticsProcessing || apiError) return;
        if(!minDate || !maxDate)return;
        const s = dateAsNonTZString(minDate);
        const e = dateAsNonTZString(maxDate);
        fetch(`${req.apiURL}getChargeEventStats?dbName=${req.dbName}&start=${s}&stop=${e}`, {headers: { Authorization: `Bearer ${req.user.token}` }})
            .then((resp) => resp.json())
            .then((data) => {
                const count = parseInt(data.data[0].count);
                if(count > LARGE_DATA_SET_THRESHOLD){
                    // TODO: Tag up with Matt and review this logic. - LS 1/11/24
                    let d = DateTime.fromMillis(maxDate.ts).toUTC(); // Deep copy the max date
                    if(d.day < MINIMUM_DAYS_FOR_MONTH){
                        const oneMonthBack = d.minus({ months: 1 }); // Go back one month
                        d = oneMonthBack.startOf('month'); // Set the day to the first of the month
                    }
                    //calc days to go back
                    const m = DateTime.fromMillis(minDate.ts).toUTC(); // Deep copy the min date
                    const tsd = (d.ts-m.ts)/1000/SECONDS_PER_DAY;//delta in days
                    const initDays = Math.min(tsd, LARGE_DATE_SET_INIT_DAYS);
                    const initDaysMillis = initDays * 1000 * SECONDS_PER_DAY;
                    //set new min
                    const max = DateTime.fromMillis(maxDate.ts).toUTC();
                    const newMin = DateTime.fromMillis((max.ts - initDaysMillis)).toUTC(); //roll back the date by our days const (using millis so luxon doesn't get upset)
                    //update state
                    setSelectedBeginDate(newMin);
                    setSelectedEndDate(d);
                    setLimitedDateRange(true)
                }
                setTimeout(()=>setLoadChargeLocations(true), 300);//this is a terrible way to handle a race condition
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minDate, maxDate, isAggregateProcessing, isAnalyticsProcessing, apiError]);

    useEffect(()=>{
        // on initialization, check the aggregate processing state, and then if all clear, check the analytics processing state.
        getAggregateProcessingState().then(aggregateProcessing => {
            if (!aggregateProcessing) {
                getAnalyticsProcessingState();
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if (isAggregateProcessing || isAnalyticsProcessing || apiError || !loadChargeLocations) return;
        getAnalyticsProcessingState().then(analyticsProcessing => {
            if(!analyticsProcessing) {
                //compile locations
                //need a unique key here for grouped locs... pkid is a stub
                const controller = new AbortController();
                const signal = controller.signal;

                if((!ungroupedLocations && !groupedLocations) || (!req.beginDate || !req.endDate) || applyControl)return;
                if(typeof ungroupedLocations === "undefined" || ungroupedLocations.length < 1)return;
                setIsLoading(true);
                
                //new arrays
                const locs: any[] = [{ pkid: -1, address: "All Locations", latitude: 0, longitude: 0, locationIds: [-1], vehicleResults: [], chargeEvents: [], vins: [] }];
                const identVals: any[] = [-1];
                
                //keep track of our locations by copying them over
                groupedLocations?.forEach((l) => {
                    if(identVals.indexOf(l.pkid) < 0){locs.push(l);identVals.push(l.pkid)}
                });
                ungroupedLocations?.forEach((l) => {
                    if(identVals.indexOf(l.pkid) < 0){locs.push(l);identVals.push(l.pkid)}
                });

                //request time bounds
                let reqBegin = req.beginDate;
                let reqEnd = req.endDate;

                const clMapExists = chargeLocations && chargeLocations.size > 0 ? true : false;
                if(clMapExists){
                    //copy existing chargeLocations into our new array
                    chargeLocations.forEach((v,k)=>{
                        const idx = identVals.indexOf(k);
                        locs[idx].chargeEvents = [...v.chargeEvents];
                        locs[idx]._chargeEvents = [...v.chargeEvents];
                    })

                    const cl = chargeLocations.get(-1);
                    const existingMinTS = findMinTimestamp(cl.chargeEvents);
                    const existingMaxTS = findMaxTimestamp(cl.chargeEvents);
                    //if there's no increase in max or decrease in min, no new data is needed
                    if(reqBegin >= existingMinTS && reqEnd <= existingMaxTS){
                        setApplyControl(true);
                        setLoadChargeLocations(false);
                        setIsLoading(false);
                        return;
                    }

                    //this works because a change of lower or upper bounds both cause a data fetch
                    //(ie: only one param switches at a time)

                    //handle a lowering of the date bounds by just get the lower portion of the date range
                    if(existingMinTS > reqBegin){
                        reqEnd = existingMinTS;
                    } 
                    //handle an increase in the date bounds by just getting the newer portion
                    if(existingMaxTS < reqEnd){
                        reqBegin = existingMaxTS;
                    }
                }

                //use get method for smaller data sets. larger sets overwhelm the url scheme and so use post
                //Also: this should just be refactored into a POST req all the time for simplicity
                let kwDemandRequestMethod = "GET";
                let kwDemandRequestBody = undefined;
                const s = dateAsNonTZString(reqBegin);
                const e = dateAsNonTZString(reqEnd);
                let kwDemandRequestURL = `${req.apiURL}getChargeEventsForLocations?dbName=${req.dbName}&start=${s}&stop=${e}&locations=[${identVals}]&kwhLimit=${MINIMUM_EVENT_KWH}&includeReal=true`;
                if(identVals.join().length >= POST_REQ_THRESHOLD){
                    kwDemandRequestMethod = "POST";
                    kwDemandRequestBody = JSON.stringify({locations: identVals});
                    kwDemandRequestURL = `${req.apiURL}getChargeEventsForLocations?dbName=${req.dbName}&start=${s}&stop=${e}&kwhLimit=${MINIMUM_EVENT_KWH}`;
                }

                if(benchmark){
                    console.info(`loading data: ${reqBegin} - ${reqEnd}`);
                    now.current = DateTime.now();
                }
                //keep track of how many events(including duplications) we have
                let eventCount = 0;
                const promises = [
                    fetch(kwDemandRequestURL,
                        {
                            method: kwDemandRequestMethod, 
                            signal,
                            headers: { Authorization: `Bearer ${req.user.token}`, "Content-Type": "application/json"}, 
                            body: kwDemandRequestBody
                        })
                        .then((resp)=>resp.json()), 
                    fetch(`${req.apiURL}getVehicleResults?dbName=${req.dbName}`,
                        {
                            signal,
                            headers: { Authorization: `Bearer ${req.user.token}` }
                        })
                        .then((resp)=>resp.json()),
                    fetch(`${req.apiURL}getVehicleHomebaseUsage?dbName=${req.dbName}&start=${s}&stop=${e}&kwhLimit=${MINIMUM_EVENT_KWH}&includeReal=true`,
                        {
                            signal,
                            headers: { Authorization: `Bearer ${req.user.token}` }
                        })
                        .then((resp)=>resp.json()),
                    fetch(`${req.apiURL}getVehicleClasses?dbName=${req.dbName}`,
                        {
                            signal,
                            headers: { Authorization: `Bearer ${req.user.token}` }
                        })
                        .then((resp)=> resp.json())
                ]

                Promise.all(promises)
                    .then(([locationsResponse, vehicleResultsResponse, homebaseUsageResponse, vehicleClassesResponse]) => {
                        //handle homebase response
                        homebaseUsageResponse.data.forEach((hbv: any) => {
                            const vehicle = vehicleResultsResponse.data.find((v: any) => v.vin === hbv.vin);
                            if(!vehicle)return;
                            vehicle.currentHomebase = parseInt(hbv.current_homebase);
                            vehicle.homebases = hbv.homebases;
                            vehicle.current_homebase_kwh = Math.round(hbv.current_homebase_kwh);
                            vehicle.current_nonhomebase_kwh = Math.round((1-hbv.current_homebase_kwh_pct)*(vehicle.current_homebase_kwh/hbv.current_homebase_kwh_pct));
                            vehicle.current_homebase_kwh_pct = Math.round(hbv.current_homebase_kwh_pct*100);
                        });
                        
                        locationsResponse.data.forEach((l: { parking_loc: number; charge_events: Array<any>; vins: Array<string>; })=>{
                            const idx = identVals.indexOf(l.parking_loc);
                            if(!locs[idx].chargeEvents)locs[idx].chargeEvents = [];
                            if(!locs[idx]._chargeEvents)locs[idx]._chargeEvents = [];
                            if(!locs[idx].vins)locs[idx].vins = [];
                            
                            //update any existing values
                            locs[idx].chargeEvents = [...locs[idx].chargeEvents, ...l.charge_events];
                            locs[idx]._chargeEvents = [...locs[idx]._chargeEvents, ...l.charge_events];
                            l.vins.forEach((v: any)=>{
                                if(locs[idx].vins.indexOf(v) === -1)locs[idx].vins.push(v);
                                //append to catch all location object too
                                if(locs[0].vins.indexOf(v) === -1)locs[0].vins.push(v);
                            });
                            locs[idx].vehicleResults = [];
                            
                            //attach charge events to vehicle objects(for homebase/non charging calcs)
                            l.charge_events.forEach((ce: {vin: string, parking_loc: number, local_start: DateTime, local_stop: DateTime, modeled: boolean, vehicle_moved: DateTime, kwh: number, charger_level: string}) => {
                                ce.parking_loc = l.parking_loc;
                                const vcl = vehicleResultsResponse.data.find((v: any)=> v.vin === ce.vin);
                                if(vcl){
                                    if(!vcl.chargeEvents)vcl.chargeEvents = [];
                                    vcl.chargeEvents.push(ce);
                                }
                            });

                            //increment eventCount
                            eventCount += locs[idx]._chargeEvents.length;

                            //append to charge events to catch all location object
                            //instantiate if the arrays don't exist yet
                            if(!locs[0].chargeEvents)locs[0].chargeEvents = [];
                            if(!locs[0]._chargeEvents)locs[0]._chargeEvents = [];
                            locs[0].chargeEvents = [...locs[0].chargeEvents, ...l.charge_events];
                            locs[0]._chargeEvents = [...locs[0]._chargeEvents, ...l.charge_events];

                        });
                        eventCount += locs[0]._chargeEvents.length;

                        vehicleResultsResponse.data.forEach((v: any)=>{
                            v = processApiResponse(user.userSettings, v);

                            const vehicleClassProps = vehicleClassesResponse.data.find((vc: any) => vc.vehicle_class === v.vehicle_class);
                            v.default_kw_draw = vehicleClassProps ? vehicleClassProps[vehicleClassProps.default_rate] : "NA";

                            //guarantee uniqueness of vin, even though fresh obj
                            if(!locs[0].vehicleResults.find((lvr: any) => lvr.vin === v.vin)){
                                locs[0].vehicleResults.push(JSON.parse(JSON.stringify(v)));
                            }

                            locs.forEach((l)=>{
                                //check vehicle belongs to location
                                if(l.pkid > -1 && l.vins && l.vins.indexOf(v.vin) > -1){
                                    //guarantee uniqueness, because this location has been copied
                                    //this will cause dupes if we don't
                                    if(!l.vehicleResults.find((lvr: any)=> lvr.vin === v.vin)){
                                        l.vehicleResults.push(JSON.parse(JSON.stringify(v)));
                                    }
                                };
                            });
                        });

                if(benchmark){
                    let d = DateTime.local();
                    let tsd = d.ts - now.current.ts;
                    console.info(`data loading finished: ${tsd} ms --> Events Received: ${eventCount}`);
                }
                totalEventCount.current = eventCount;
                setVehicles(vehicleResultsResponse.data);

                        initializeChargeLocations(locs);
                        //setChargeLocations(chargingLocationsMap);
                        setApplyControl(true);
                        setLoadChargeLocations(false);
                        setIsLoading(false);
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                    })
                    .catch((err)=>{
                        console.error(err);
                        setApiError(true);
                    });
                return () => controller.abort();
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadChargeLocations, isAggregateProcessing, isAnalyticsProcessing, apiError]);

    useMemo(() => {
        if (isAggregateProcessing || isAnalyticsProcessing || apiError) return;
        //control plane
        if(!chargeLocations || !electrification || !applyControl)return;
        if(benchmark)now.current = DateTime.local();
        let vins = electrifiedVINS(electrification);
        vins = vinsAtLocation(vins);
        vins = vinsInSelectedGroup(vins);
        vins = vinsInCategory(vins);
        vins = vinsInSelectedClasses(vins);
        setSelectedVins(vins);
        const locs = Array.from(immutableChargeLocations.values());

        setChargeLocations(new Map());
        
        const kickoffWorker = ( chargeLocation: ChargeLocation, vins: string[], req: any) => {
            if(workers.current.length > 0){
                //don't do work on extra locations
                if(selectedChargeLocation !== -1 && selectedChargeLocation !== chargeLocation.pkid){
                    return;
                }
                const worker = workers.current.pop();
                active_workers.push(worker);
                if(worker)worker.postMessage([[chargeLocation], vins, req]);
            }
        };

        const timer = (milleseconds:number) => new Promise(res => setTimeout(res, milleseconds));

        const process = async () => {
            let i = 0;
            while(i < locs.length) {
                const l = locs[i];
                if(workers.current.length > 0){
                    kickoffWorker(l, vins, req);
                    i++;
                }else{
                    await timer(PROCESS_LOOP_CADENCE);
                }
            }
        }
        
        if(MULTI_THREADING_ENABLED){
            threads_required.current = Math.max(locs.length, threads_required.current);//
            if(selectedChargeLocation !== -1)threads_required.current = 1;
            countDown.current = 0;
            processing = true;
            process();
        }
        else{
            threads_required.current = 1;
            processing = true;
            const worker = workers.current.pop();
            if(worker)worker.postMessage([locs, vins, req]);
        }

        setApplyControl(false);
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyControl, isAnalyticsProcessing, isAggregateProcessing, apiError]);

    const handleControls = useCallback((controls) => {
        //this all needs to handle in order...
        if(processing){
            //kill all workers
            kill()
                .then(() => {
                    setChargeLocations(new Map(immutableChargeLocations));
                    handleControls(controls);
                })
            return;
        }
        if(chargeLocations.size === 0)setChargeLocations(new Map(immutableChargeLocations));
        controlSwitch(controls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[groups, chargeLocations, CATEGORIES]);

    const settingsFullOn = useMemo(()=>{
        if(selectedChargeLocation === -1 &&
           selectedCategory.id === 0 &&
           electrification === 100 &&
           selectedVehicleClasses.indexOf('All Classes') > -1 &&
           (selectedGroup && selectedGroup.id === "swt-vehicles"))return true;
        return false;
    },[selectedCategory, selectedChargeLocation, electrification, selectedGroup, selectedVehicleClasses])

    const getAggregateProcessingState = () => {
        const url = `${req.apiURL}isAggregateProcessing?dbName=${req.dbName}`;
        return fetch(url, {headers: { Authorization: `Bearer ${req.user.token}` }})
        .then(res => res.json())
        .then((data) => {
            let processing = data.data[0].aggregate_processing;
            setIsAggregateProcessing(processing);
            return Promise.resolve(processing);
        })
        .catch((err) => {
            console.error(err);
            setApiError(true);
            return Promise.reject(err);
        });
    };

    const getAnalyticsProcessingState = () => {
        const url = `${req.apiURL}isAnalyticsProcessing?dbName=${req.dbName}`;
        return fetch(url, { headers: { Authorization: `Bearer ${req.user.token}` } })
            .then(res => res.json())
            .then((data) => {
                let processing = data.data[0].analytics_processing;
                // only set isAnalyticsProcessing if the value has changed from previous, to prevent re-triggering of other hooks with isAnalyticsProcessing as a dependency
                if (processing !== isAnalyticsProcessing) setIsAnalyticsProcessing(processing);
                return Promise.resolve(processing);
            })
            .catch((err) => {
                console.error(err);
                setApiError(true);
                return Promise.reject(err);
            });
    };

    const handleChargeLocations = (data: Map<number, ChargeLocation>, worker:any) => {
        const pen = locationPen;
        const arr = Array.from(data.values());
        arr.forEach((cl) => {
            pen.set(cl.pkid, cl);
        });
        active_workers.pop();
        workers.current.push(worker);
        threads_required.current--;
        threads_required.current = Math.max(threads_required.current, 0);
        if(threads_required.current === 0){
            processing = false;
            setChargeLocations(pen);
            if(benchmark){
                const d = DateTime.local();
                const tsd = d.ts - now.current.ts;
                console.info(`workers finished: ${tsd} ms`);
            }
        }
        //if(chargeLocations && chargeLocations.size > 0 && chargeLocations.size === pen.size)setChargeLocations(pen);
    };

    const sortRates = (rateResp) => {
        if(!rateResp || rateResp.length < 1)return rateResp;
        let sortedRates = [];
        // filter TOD rates
        let tod = rateResp.filter(r => (r.startHour !== null && r.stopHour !== null && r.startMinute !== null && r.stopMinute !== null));
        // order TOD rates by shortest duration
        let sortedTod = tod.sort((a, b) => {
                let durationA = (a.stopHour - a.startHour) + ((a.stopMinute - a.startMinute)/60);
                let durationB = (b.stopHour - b.startHour) + ((b.stopMinute - b.startMinute)/60);
                if(durationA < durationB)return -1;
                if(durationA > durationB)return 1;
                // potentially add secondary sort (would need to add to datalayer also)
                return 0;
            }
        );
        // add sorted TOD rates to sorted rates array
        if(sortedTod.length > 0) sortedRates = sortedRates.concat(sortedTod);
        // filter DOW rates - no TOD set, only DOW set
        let dow = rateResp.filter(r => (r.startHour === null && r.stopHour === null &&
                                        r.startMinute === null && r.stopMinute === null &&
                                        (r.mondays || r.tuesdays || r.wednesdays || r.thursdays || r.fridays || r.saturdays || r.sundays)));
        // order DOW rates by kwh_rate descending
        let sortedDow = dow.sort((a, b) => {
                if(a.kwhRate < b.kwhRate)return 1;
                if(a.kwhRate > b.kwhRate)return -1;
                return 0;
            }
        )
        // add sorted DOW rates to sorted rates array
        if(sortedDow.length > 0) sortedRates = sortedRates.concat(sortedDow);
        // filter seasonal rates - rates with no TOD or DOW set
        let seasonal = rateResp.filter(r => (r.startMonth !== null && r.stopMonth !== null &&
                                             r.startHour === null && r.stopHour === null &&
                                             r.startMinute === null && r.stopMinute === null &&
                                             (!r.mondays && !r.tuesdays && !r.wednesdays && !r.thursdays && !r.fridays && !r.saturdays && !r.sundays)));
        // order seasonal rates by duration & cost
        let sortedSeasonal = seasonal.sort((a, b) => {
            return seasonalRateSort(a, b);
        })
        // add sorted seasonal rates to sorted rates array
        if(sortedSeasonal.length > 0) sortedRates = sortedRates.concat(sortedSeasonal);
        return sortedRates;
    }

    function seasonalRateSort(a, b) {
        // order by rate with shortest duration
        if(rateDuration(a) < rateDuration(b))return -1;
        if(rateDuration(a) > rateDuration(b))return 1;
        // if the same, return highest rate
        if(a.kwhRate < b.kwhRate)return 1;
        if(a.kwhRate > b.kwhRate)return -1;
        return 0;
    }

    const rateDuration = (rate) => {
        // sum up duration of seasonal rate
        // NOTE this function doesn't cover edge cases, which is fine for this purpose
        // but could be improved in future
        let startMonth = rate.startMonth;
        let startDay = rate.startDayOfMonth;
        let stopMonth = rate.stopMonth;
        let stopDay = rate.stopDayOfMonth;
        let yr = DateTime.utc().year;
        let startYr = yr;
        // if rate goes over a year, update start year
        if (startMonth > stopMonth)startYr = yr-1;
        const strt = DateTime.utc(startYr, startMonth, startDay);
        const stp = DateTime.utc(yr, stopMonth, stopDay);
        let diff = Math.abs(stp.ts - strt.ts);
        // diff = Math.ceil(diff / (1000 * 60 * 60 * 24)); // convert to days
        return diff
    }

    const findMaxTimestamp = (ceArray: any[]) => {
        const d = DateTime.utc();
        let max = d.ts - (2 * 365 * SECONDS_PER_DAY * 1000);
        ceArray.forEach((ce: any) => {
            const ts = DateTime.fromISO(`${ce.utc_start}Z`).toUTC(); // Charge events use json_agg fxn at API level which does not conform to standard UTC ISO
            max = Math.max(max, ts.ts + EXISTING_TS_MARGINS);
        })
        return DateTime.fromMillis(max);
    }

    const findMinTimestamp = (ceArray: any[]) => {
        const d = DateTime.utc();
        let min = d.ts;
        ceArray.forEach((ce: any) => {
            const ts = DateTime.fromISO(`${ce.utc_start}Z`).toUTC(); // Charge events use json_agg fxn at API level which does not conform to standard UTC ISO
            min = Math.min(min, ts.ts - EXISTING_TS_MARGINS);
        })
        return DateTime.fromMillis(min);
    }

    function initializeChargeLocations(locs){
        const chargingLocationsMap = new Map();
        
        locs.forEach((l: ChargeLocation)=>{
            const m = l.vehicleResults ? l.vehicleResults.filter((v: { is_ev_recommendation: boolean; })=> v.is_ev_recommendation===true) : [];
            const hb = l.vehicleResults ? l.vehicleResults.filter((v: { parking_id: number }) => v.parking_id === l.pkid) : [];

            //homebase calculations from API response
            l?.vehicleResults?.forEach((v) => {
                if(v.parking_id === l.pkid || l.pkid === -1)v.is_homebase_location = true;
                else v.is_homebase_location = false;
                if(!v.is_homebase_location && v?.homebases?.indexOf(l.pkid) > -1)v.is_past_homebase=true;
                else v.is_past_homebase = false;
            });
            l.isSelected = true;
            l.chargingVehiclesCount = l.vins ? l.vins.length : 0;
            l.vehicleHomebaseCount = hb.length; 
            l.homebaseVins = hb.map((v:any) => {return v.vin});
            l.evRecommendationCount = m.length;
            l.chargeEventCount = l.chargeEvents ? l.chargeEvents.length : 0;
            l.summary = null;
            l.peakKw = 0;
            chargingLocationsMap.set(l.pkid, l);
        });
        immutableChargeLocations = new Map(chargingLocationsMap);
        setChargeLocations(chargingLocationsMap);
    }

    function emptyChargeLocation(cl: ChargeLocation){
        //numbers to 0, arrays to empty and objects to undefined
        //immutable props commented out.
        //cl.pkid: number,
        //cl.address: string,
        //cl.latitude: number,
        //cl.longitude: number,
        cl.chargingVehiclesCount = 0;
        cl.chargingVehiclesVins = [];
        cl.chargeDurationSeconds = 0;
        cl.chargeDayCount = 0;
        cl.chargeEvents = [];
        //cl._chargeEvents = []; --> Immutable after assignemnt
        cl.peakKw= 0;
        //cl.smoothedPeakKW= 0;
        cl.peakCost= 0;
        cl.monthlyPeaks = undefined;
        cl.todSummaries = undefined;
        cl.todSmartSummaries = undefined;
        cl.todPeakSmoothedSummaries = undefined; 
        cl.evRecommendationCount = 0;
        cl.maxVehiclesCharging = 0;
        cl.chargeEventCount = 0;
        //cl.vehicleResults = []; --> Immutable after assignment
        cl.presentationEvents = [];
        //cl.vins = []; --> Immutable after assignment
        cl.summary = undefined;
        //cl.pkids = []; --> Immutable after assignment
        //cl.locationEmpty: boolean, <-- Unused?
        //cl.isSelected: boolean,
        //cl.vehicleHomebaseCount: number, --> Immutable after assignment
        //cl.homebaseVins: Array<string> --> Immutable after assignment
        return cl;
    }

    function electrifiedVINS(electrificationPercent: number){
        if(!chargeLocations)return [];
        const arr = Array.from(chargeLocations.values());

        let vins: any[] = [];
        arr.forEach((a)=>{
            if(a.vehicleResults && a.pkid > -1){
                a.vehicleResults.forEach((v: any)=>{
                    const included = vins.some(x => x.vin === v.vin);
                    if(!included)vins.push({vin: v.vin, overall: v.overall, energy: v.energy, economics: v.economics});
                });
            }
        });
        consistentElectrificationSort(vins);
        const vclCount = vehicles ? vehicles.length : vins.length;
        const slice2Take = vclCount * (electrificationPercent/100);
        const r = vins.slice(0, slice2Take);
        return(r.map((v)=> {return v.vin}));
    }

    function vinsInSelectedGroup(vins: Array<any>){
        if(!selectedGroup || !selectedGroup.vehicles)return vins;
        const r: Array<any> = [];
        vins.forEach((v)=>{
            if(selectedGroup.vehicles.findIndex((i:any) => i.vin === v) > -1)r.push(v);
        })
        return r;
    }

    function vinsInCategory(vins: Array<any>){
        if(!selectedCategory || !vehicles)return vins;
        const r: Array<any> = [];
        let b: Array<any> = [];
        if(selectedCategory.id === 0)return vins;
        if(selectedCategory.id === 1)b = vehicles.filter((v) => v.is_ld === (true || null));
        if(selectedCategory.id === 2)b = vehicles.filter((v) => v.is_ld === false);
        b = b.map((v) => {return v.vin});
        vins.forEach((v) => {if(b.indexOf(v) > -1)r.push(v)});
        return r;
    }

    function vinsAtLocation(vins: Array<any>){
        if(!selectedChargeLocation || selectedChargeLocation === -1)return vins;
        const r: Array<any> = [];
        const cl = chargeLocations?.get(selectedChargeLocation);
        if(cl)vins.forEach((v) => {if(cl.vins.indexOf(v) > -1)r.push(v)});
        return r;
    }

    function vinsInSelectedClasses(vins: Array<any>){
        if(!vehicles || !selectedVehicleClasses || selectedVehicleClasses.includes('All Classes'))return vins;
        const r: Array<any> = [];
        let b = vehicles.filter((v) => selectedVehicleClasses.indexOf(v.vehicle_class) > -1);
        b = b.map((v) => {return v.vin});
        vins.forEach((v) => {if(b.indexOf(v) > -1)r.push(v)});
        return r;
    }

    //kill all workers
    const kill = () => {
        return new Promise((resolve) => {
            if(active_workers.length === 0){
                threads_required.current = 0;
                processing = false;
                resolve(null);
                return
            }
            while(active_workers.length > 0){
                active_workers[0].terminate();
                active_workers.splice(0, 1);
                if(active_workers.length === 0){
                    threads_required.current = 0;
                    processing = false;
                    resolve(null);
                    return;
                }
            }
        })
    }

    const controlSwitch = (controls) => {
        for(const c in controls){
            if(c === 'electrification'){
                setElectrification(controls[c]);
            };
            if(c === 'group'){
                const g = groups.find((g) => g.id === controls[c]);
                setSelectedGroup(g);
            }
            if(c === 'category'){
                const cat = CATEGORIES.find((i) => i.label === controls[c]);
                if(cat?.id === 2)setIsLd(false);
                if(cat?.id === 1)setIsLd(true);
                if(cat?.id === 0)setIsLd(null);
                if(cat)setSelectedCategory(cat);
            }
            if(c === 'location'){
                if(!chargeLocations)return;
                const arr = Array.from(chargeLocations.values());
                const l = arr.find((i) => i.pkid === controls[c]);
                if(l){
                    const arr = Array.from(chargeLocations.values());
                    arr.forEach((cl: ChargeLocation) => {
                        const c = emptyChargeLocation(cl);
                        chargeLocations.set(cl.pkid, c); //explicity setting/not relying on the pointer for clarity
                    });
                    setSelectedChargeLocation(l.pkid);
                    
                    // if the user updates the location filter to be something other than "All Locations",
                    // turn off the onInvalidDetailPage flag.
                    if (l.pkid !== -1) setOnInvalidDetailPage(false);
                }
            }
            if(c === 'classes'){
                setSelectedVehicleClasses(controls[c]);
            }
        }
        setApplyControl(true);
    }

    const handleDateChange = (key: string, newDate: DateTime) =>{
        if(!key)return;
        if(key==='min')setSelectedBeginDate(newDate);
        if(key==='max')setSelectedEndDate(newDate);
        //setApplyControl(true);
        setLoadChargeLocations(true);
    }

    if(isAnalyticsProcessing || isAggregateProcessing || apiError || groupError){
        return(
            <S.ProductWrapper>
                <ActiveProcessingPage
                    user={user}
                    apiURL={apiURL}
                    dbDisplayName={dbDisplayName}
                    dbName={dbName}
                    setProcessingCountdownFinished={setProcessingCountdownFinished}
                    apiError={apiError}
                    aggregateProcessing={isAggregateProcessing}
                    groupError={groupError}
                />
            </S.ProductWrapper>
        );
    }

    if(!chargeLocations){
        return(
            <S.ProductWrapper>
                <S.LoadingContainer>
                    <Loading/>
                </S.LoadingContainer>
            </S.ProductWrapper>
        );
    }

    const enableControls = (threads_required.current < 1 && !isLoading) ? true : false;
    return(
        <S.ProductWrapper>
            <Header displayName={dbDisplayName} 
                    selectedBeginDate={selectedBeginDate}
                    selectedEndDate={selectedEndDate}
                    handleDateChange={handleDateChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    location={location.pathname}
                    userSettings={user.userSettings}
                    limitedDateRange={limitedDateRange}/>
            <S.HeaderRule />
            {enableControls && vehicleClassesPresent && selectedVehicleClasses && <Controls
                groups={groups}
                selectedGroup={selectedGroup}
                selectedCategory={selectedCategory}
                chargeLocations={chargeLocations} 
                selectedChargeLocation={selectedChargeLocation ? selectedChargeLocation : -1}
                classes={vehicleClassesPresent}
                selectedClasses={selectedVehicleClasses}
                selectedElectrification={electrification}
                onChangeControls={(c:any)=>{handleControls(c)}}
            />}
            <S.HeaderRule />
            {(chargeLocations.size === 0 || threads_required.current !== 0 || isLoading) && !onInvalidDetailPage && 
                <S.LoadingContainer>
                    <CountDownView countDown={processEventsCountdown}/><Loading/></S.LoadingContainer>
            }
            {(onInvalidDetailPage) &&
                <InvalidDetailPage/>
            }
            {chargeLocations.size > 0 && selectedCategory && selectedGroup && !isLoading && !onInvalidDetailPage &&
            <Switch>
                <Route exact path="/"><Redirect to="/ezio/"></Redirect></Route>
                <Route path="/ezio/locations/list">
                    <LocationsDisplay 
                        dbName={dbName} 
                        chargeLocations={chargeLocations} 
                        viewMode={"list"} 
                        selectedChargeLocation={selectedChargeLocation}
                        forceNavigate={(id: number)=>{setSelectedChargeLocation(id); setApplyControl(true); handleControls({location:id});}}
                        category={selectedCategory.label}
                        group={selectedGroup.name}
                        electrification={electrification}
                        vehicleClasses={selectedVehicleClasses}
                        beginDate={selectedBeginDate}
                        endDate={selectedEndDate}
                        dbDisplayName={dbDisplayName}
                        userSettings={user.userSettings}
                    />
                </Route>
                <Route path="/ezio/locations/map">
                    <LocationsDisplay 
                        dbName={dbName}
                        chargeLocations={chargeLocations} 
                        viewMode={"map"} 
                        selectedChargeLocation={selectedChargeLocation}
                        forceNavigate={(id: number)=>{setSelectedChargeLocation(id); setApplyControl(true); handleControls({location:id})}}
                        dbDisplayName={dbDisplayName}
                        userSettings={user.userSettings}
                    />
                </Route>
                <Route path="/ezio/locations/:urlId">
                    {selectedChargeLocation && <LocationSummary
                        totalVehicleCount={totalVehicleCount}
                        selectedChargeLocation={selectedChargeLocation}
                        chargeLocations={chargeLocations}
                        req={req}
                        category={selectedCategory.label}
                        group={selectedGroup.name}
                        electrification={electrification}
                        vehicleClasses={selectedVehicleClasses}
                        beginDate={selectedBeginDate}
                        endDate={selectedEndDate}
                        dbDisplayName={dbDisplayName}
                        userSettings={user.userSettings}
                    />}
                </Route>
                <Route path="/ezio/kw-demand-monthly/:urlId">
                    <LocationDetail 
                        viewMode={"Monthly"}
                        req={req}
                        totalVehicleCount={totalVehicleCount}
                        chargeLocations={chargeLocations}
                        selectedChargeLocation={selectedChargeLocation}
                        category={selectedCategory.label}
                        group={selectedGroup.name}
                        electrification={electrification}
                        vehicleClasses={selectedVehicleClasses}
                        dbDisplayName={dbDisplayName}
                        userSettings={user.userSettings}
                        selectedVins={selectedVins}
                    />
                </Route>
                <Route path="/ezio/kw-demand-daily/:urlId">
                    <LocationDetail 
                        viewMode={"TimeOfDay"}
                        req={req}
                        totalVehicleCount={totalVehicleCount}
                        chargeLocations={chargeLocations}
                        selectedChargeLocation={selectedChargeLocation}
                        category={selectedCategory.label}
                        group={selectedGroup.name}
                        electrification={electrification}
                        vehicleClasses={selectedVehicleClasses}
                        dbDisplayName={dbDisplayName}
                        userSettings={user.userSettings}
                        selectedVins={selectedVins}
                    />
                </Route>
                <Route exact path="/ezio">
                    <LandingPage 
                        apiUrl={apiURL}
                        user={user}
                        dbName={dbName}
                        selectedLocationId={selectedChargeLocation}
                        forceNavigate={(id: number)=>{setSelectedChargeLocation(id); setApplyControl(true); handleControls({location:id})}}
                        chargeLocations={chargeLocations}
                        totalVehicleCount={totalVehicleCount}
                        noGroups={false}
                        selectedCategory={selectedCategory}
                        settingsFullOn={settingsFullOn}
                    />
                </Route>
                <Route exact path="/ezio/about">
                    <AboutPage
                        user={user}
                        settingsKwhRate={simpleKWHRate}
                        kwhRates={rateSchedules}
                    />
                </Route>
            </Switch>
            }
        </S.ProductWrapper>
    )
}

export default function Ezio(props: any) {
    return (
        <Router basename={props.basename}><EzioApp{...props} /></Router>
    )
}