import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Roles } from "../core-components/utility-components/UserUtils";

export default function About({settings, user, activeLinkChange}) {
  useEffect(() => {
    activeLinkChange('activeLink', 'about');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="swt-ionev-about-wrapper">
      <div className="swt-ionev-about-section">
        <h2 className="swt-ionev-about-header">About</h2>
        <p className="swt-ionev-about-text">ionEV is designed to help fleet managers quickly understand how their EVs are operating and to identify opportunities to increase savings and GHG emissions reductions. The metrics are provided to support fleet reporting requirements and for tracking progress toward fleet sustainability goals.</p>
      </div>
      <div className="swt-ionev-assumptions-section">
        <h2 className="swt-ionev-about-header">Assumptions</h2>
        <div className="swt-ionev-assumptions-wrapper">
          <div className="swt-ionev-assumptions-list">
            <p className="swt-ionev-assumptions-label">Fuel Cost ({user.userSettings.currency}): </p><p className="swt-ionev-assumptions-value">{user.userSettings.currency_symbol}{settings['fuel_cost']} per {user.userSettings.liquid_volume_labels.longSingular.toLowerCase()}</p><br/>
            <p className="swt-ionev-assumptions-label">Vehicle Lifecycle (years): </p><p className="swt-ionev-assumptions-value">{settings['life_cycle']}</p><br/>
            <p className="swt-ionev-assumptions-label">State of Charge Threshold for Missed Opportunities (BEVs): </p><p className="swt-ionev-assumptions-value">{settings['max_soc_missed_opp']}%</p><br/>
            <p className="swt-ionev-assumptions-label">State of Charge Threshold for Missed Opportunities (PHEVs):</p><p className="swt-ionev-assumptions-value"> {settings['max_soc_missed_opp_phev']}%</p><br/>
            <br/>
            <p className="swt-ionev-about-text">Vehicle models and pricing are provided by the client. For more information, please contact us at <a href="mailto:info@sawatchlabs.com" className="ionev-link">info@sawatchlabs.com</a>.</p>
          </div>
        </div>
      </div>
      {user.role >= Roles.FleetAdmin &&
        <div className="swt-ionev-assumptions-edit" data-testid="assumptionsEdit-testid">To edit these assumptions,
          <Link
            className="ionev-link"
            to={{
              pathname: '/admin/settings',
              state: {redirectFrom: "/ionev/about"}
            }}
          >
            click here
          </Link>.
        </div>
      }
    </div>
  );
}

// <p className="swt-ionev-assumptions-label">Values for 'Recommendation to Save'</p><br/>
// <p className="swt-ionev-assumptions-label">EV Yearly Miles Target: </p><p className="swt-ionev-assumptions-value">{settings['ev_yr_km_target']}</p><br/>
// <p className="swt-ionev-assumptions-label">Max % M.O as portion of events: </p><p className="swt-ionev-assumptions-value">{settings['max_pct_missed_opps_target']}%</p><br/>
