import React from "react";
import {
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ActiveProcessingPage from "./ezev-components/ActiveProcessingPage";
import CandidateSelection from "./ezev-components/CandidateSelection";
import LandingPage from "./ezev-components/LandingPage";
import TableController from "./ezev-components/TableController";
import SingleVclController from "./ezev-components/SingleVclController";
import AssumptionsView from "./ezev-components/AssumptionsView";
import { GeotabIntegration } from "../geotab_integration";
import "../component-css/ezev-stylesheets/ezev-tables-v6.css";
import "../component-css/ezev-stylesheets/ezev-master-stylesheet.css";
import {getFuelType, vehicleIdDisplay} from "./ezev-components/HelperFunctions";
import { processApiResponse } from "./ezev-components/utils/ConformUnits";
import { calcEffectiveBattery } from "./ezev-components/HelperFunctions";
import { convertISOToExcelSerial } from "./ezev-components/FormatExcelData";

const USABLE_BATTERY_PCT = 0.9;
const MAX_FAILURES_TO_UPDATE_PROCESSING_ALLOWED = 5;
const UPDATE_MILLISECONDS = 1000;

class Ezev extends React.Component {
  stateSet = false;
  state = {
    activity: null,
    candidates: null,
    displayName: this.props.dbDisplayName,
    database: this.props.dbName,
    groups: [],
    authorizedGroups: [],
    populatedGroups: [],
    vehicles: [],
    selectedGroup: "",
    groupMinDate: null,
    groupMaxDate: null,
    hasRecievedDates: false,
    isAnalyticsProcessing: null,
    isAggregateProcessing: null,
    processingCountdownFinished: null,
    fleetEzevMetrics: null,
    groupEzevMetrics: null,
    totalGroupEzevMetrics: null,
    groupRecommendedCount: null,
    selectedVehicleCategory: "All Vehicles",
    apiError: false,
    settings: {},
    parkingData: []
  };

  componentDidMount = () => {

    if (
      typeof this.props.dbName !== "undefined" &&
      typeof this.props.apiURL !== "undefined"
    ) {
      if (this.props.geotabIntegrated) this.registerGeotabIntegration();
      else this.registerForGroupsUpdate(this.props.dbName);
    }

  };

  initializeApp = (db) => {
    if (this.state.isAggregateProcessing === null) {
      // if we are initializing, check whether aggregate processing is ongoing
      this.getAggregateProcessingState(db).then(processing => {
        if (!processing) {
          if (this.state.isAnalyticsProcessing === null) {
            this.getAnalyticsProcessingState(db, true).then(analyticsProcessing => {
              if (!analyticsProcessing) {
                this.getCandidates(db)
                .catch((err) => {
                  console.error(err);
                  this.setState({apiError: true});
                })
                .then(
                  this.fetchData(this.state.database,this.state.selectedGroup[0],this.state.selectedVehicleCategory)
                );
                if (this.stateSet !== true) return;
                this.getGroups(db);
                // abstract below out into a function to also be called on group update
                this.getGroupEzevMetrics(db, this.state.selectedGroup.id);
                this.getTotalGroupEzevMetrics(db);
                this.getEzevTemperatureMetrics(db, this.state.selectedGroup.id, this.state.selectedVehicleCategory);
                if (this.state.selectedGroup)
                  this.getGroupMetrics(db, this.state.selectedGroup.id);
                this.getGroupRecommendedCount(db);
                this.getParkingData(db);
                this.setState({settings: this.props.dbSettings})
                }
              });
            }
          }
        });
      }
    }

  componentDidUpdate(prevProps, prevState) {
    //If the category or group changed
    if ((prevState.selectedGroup.id !== this.state.selectedGroup.id) || (prevState.selectedVehicleCategory !== this.state.selectedVehicleCategory)) {
      this.getVehicles(this.state.database, this.state.selectedGroup.id, this.state.selectedVehicleCategory);
      this.getGroupRecommendedCount(this.state.database, this.state.selectedGroup.id, this.state.selectedVehicleCategory);
      this.getEzevTemperatureMetrics(this.state.database, this.state.selectedGroup.id, this.state.selectedVehicleCategory)
    }

    if ((prevState.processingCountdownFinished !== this.state.processingCountdownFinished) && this.state.processingCountdownFinished) {
      this.handleProcessingCountdownFinished(this.state.database);
    }
  }

  handleProcessingCountdownFinished = (db) => {
      let failures_to_update = 0;
      this.intervalId = setInterval(async () => {
        const isProcessing = await this.getAnalyticsProcessingState(db, true);
        
        if (!isProcessing) {
          this.setState({ processingCountdownFinished: null });
          clearInterval(this.intervalId);
        } else {
          failures_to_update+=1;
        }
        
        if (failures_to_update >= MAX_FAILURES_TO_UPDATE_PROCESSING_ALLOWED) {
          clearInterval(this.intervalId);
          if (isProcessing) {
            this.setState({ apiError: true });
          }
        }
      }, UPDATE_MILLISECONDS);
  }

  getGroupAccess(db) {
    if (!this.props.user) return;
    fetch(
      `${this.props.apiURL}getGroupAccess?ident=${db}&username=${this.props.user.email}`,
      { headers: { Authorization: `Bearer ${this.props.user.token}` } }
    )
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        let groups = [];
        if (data.groups) groups = data.groups;
        if (groups.length === 0)
          this.setState({ populatedGroups: ["No Groups"] });
        else {
          groups.forEach((g) => {
            groups.push(g.group_id);
          });
          return (groups);
        }
      })
      .then((groups) => {
        fetch(
          `${this.props.apiURL}getGroupChildren?ident=${db}`,
          { headers: { Authorization: `Bearer ${this.props.user.token}` } }
        )
          .then((res) => {
            return res.json();
          })
          .then((r) => {

            if (groups) {
              r.data.forEach((g) => {
                if (groups.indexOf(g.id) === -1) groups.push(g.id);
              });
            }
            this.setState({ authorizedGroups: groups });
            document.dispatchEvent(new Event("authGroupsReceived"));
          });
      });
  }

  getGroups(db, ld = false, all = true) {
    if (!this.state.authorizedGroups) return;
    const sortByName = function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    };
    fetch(`${this.props.apiURL}getGroups/${db}?is_ld=${ld ? "true" : "false"}&all_categories=${all ? "true" : "false"}`, {
      headers: { Authorization: `Bearer ${this.props.user.token}` },
    })
      .then((res) => {
        return res.json();
      })
      .then((r) => {
        let arr = r.data;
        arr.sort(sortByName);
        arr = arr.filter((g) => {
          if (g.count > 0) return g;
          else return null;
        });
        const idx = arr.findIndex((o) => { return o.id === 'swt-vehicles' });
        if (idx > -1) {
          const g = arr[idx];
          arr.splice(idx, 1)
          arr.unshift(g);
        }
        var newSelectedGroup = {}
        if (arr.length === 0) {
          arr = ["No Groups"]
          newSelectedGroup = { selectedGroup: "No Groups" }
        }
        //Update the selected group to no groups if no groups were returned
        //Doing this avoids all of the refetching associated with a full handleGroupChange
        this.setState({
          ...{
            groups: arr,
            populatedGroups: arr,
            authorizedGroups: arr
          },
          ...newSelectedGroup,
        });
      });
  }

  getVehicleCategoryFlags = (vehicleCategory) => {
    let ld = false;
    let all_categories = false;
    if (vehicleCategory === 'Light Duty') ld = true;
    if (vehicleCategory === 'Medium and Heavy Duty') ld = false;
    if (vehicleCategory === 'All Vehicles') {
      all_categories = true;
    }
    return {"ld": ld, "all_categories": all_categories};
  }

  fetchData = (db, selectedGroup, vehicleCategory) => {
    //Abort fetch if the group isn't selected yet
    if (!selectedGroup || !selectedGroup[0]) {
      return
    }
    const {ld, all_categories} = this.getVehicleCategoryFlags(vehicleCategory);
    var group = selectedGroup[0]
    // fetch data for all vcls
    var url = `${this.props.apiURL}getVehicleResults`;
    var queries = `?dbName=${db}`;

    queries += `&groupId=${group.id}`;
    queries += `&is_ld=${ld}&all_categories=${all_categories}`;

    fetch(`${url}${queries}`, { headers: { 'Authorization': `Bearer ${this.props.user.token}` } })
      .then(res => res.json())
      .then(data => {
        url = `${this.props.apiURL}getVehicleUpfits`;
        queries = `?dbName=${db}`;
        fetch(`${url}${queries}`, { headers: { 'Authorization': `Bearer ${this.props.user.token}` }})
          .then(res => res.json())
          .then(upfits => {
            data['data'].forEach((v) => {
              // get upfits
              v.upfits = upfits['data'].filter(u => u.vin === v.vin);
              v.has_upfits = v.upfits.length > 0 ? 'True' : 'False';
              v.required_upfits = v.upfits.filter(e => e.required);
              v.optional_upfits = v.upfits.filter(e => !e.required);

              // get vehicle effective usable kWh for PDFs
              // TODO: match candidates to vehicles on ID initially and not YMM
              let cand = this.state.candidates.find(c => c.ymm === v.scored_on);
              // calc effective battery capacity if candidate match is found and usable battery exists
              if (cand && cand.usable_kwh !== undefined && cand.usable_kwh !== null) {
                v.rec_effective_battery_kwh = calcEffectiveBattery(this.state.settings.charge_to_ld,this.state.settings.discharge_to_ld,this.state.settings.charge_to_mdhd,this.state.settings.discharge_to_mdhd,v.is_ld,cand.usable_kwh);
              } else {
                v.rec_effective_battery_kwh = '-';
              }

              
              // Convert min/max timestamps from ISO to Excel Serial, to ensure dates are still properly sortable in spreadsheet download.
              // These variables need to be assigned before the vehicle's data is ran through processAPIResponse since all values containing '_ts' are are then converted to strings matching the user's date format, instead of the original timestamp
              let excel_min_ts = convertISOToExcelSerial(v.min_ts);
              let excel_max_ts = convertISOToExcelSerial(v.max_ts);

              // Conform units
              v = processApiResponse(this.props.user.userSettings, v);

              // Once the API response is processed, add the serial-formatted timestamps back to the vehicle object for use in excel downloads
              v.excel_sortable_min_ts = excel_min_ts;
              v.excel_sortable_max_ts = excel_max_ts;
            })
            this.setState({
              activity: data['data']
            });
          });
      })
      .catch((err) => {
        console.error(err);
        this.setState({apiError: true})
      });
  }

  getCandidates = (db) => {
    var url = `${this.props.apiURL}getCandidates`;
    var queries = `?ident=${db}`;
    return fetch(`${url}${queries}`, { headers: { 'Authorization': `Bearer ${this.props.user.token}` } })
      .then(res => res.json())
      .then(data => {
        data['data'].forEach((u) => {
          u.upfits = u.upfits === null ? [] : u.upfits
          if(u.usable_kwh === null){
            if(u.battery_capacity !== null){
              // note: this logic is also present in analytics. If it is changed in one place, it needs to be changed in the other.
              // replace with 90% of battery capacity (incl where zero)
              
              u.usable_kwh = u.battery_capacity * USABLE_BATTERY_PCT;
            }
          }
          // conform units
          u = processApiResponse(this.props.user.userSettings, u);
        })
        this.setState({
          candidates: data['data']
        })
      })
  }

  handleProcessingFinished = () => {
    //invalidate most of state and reload
    var authGroups = [];
    if (this.props.geotabIntegrated) authGroups = this.state.authorizedGroups;
    this.setState({
      isAnalyticsProcessing: false,
      groups: [],
      authorizedGroups: authGroups,
      populatedGroups: [],
      vehicles: [],
      selectedGroup: "",
      groupMinDate: null,
      groupMaxDate: null,
      hasRecievedDates: false,
      fleetEzevMetrics: null,
      groupEzevMetrics: null,
      totalGroupEzevMetrics: null,
      groupRecommendedCount: null,
      apiError: false
    });
    this.getGroupAccess(this.props.dbName);
    this.getGroups(this.props.dbName);
    this.getCandidates(this.props.dbName);
    this.setState({settings: this.props.dbSettings})
    this.stateSet = true;
  };

  registerForGroupsUpdate = (db) => {
    this.getGroupAccess(db);
    document.addEventListener("authGroupsReceived", () => {
      this.initializeApp(db);
    });
  };

  registerGeotabIntegration = () => {
    let g = new GeotabIntegration();
    document.addEventListener("geotabSessionInit", () => {
      const url = `${this.props.apiURL}getSawatchDb?database=${g.ident}`;
      fetch(url, {headers: { Authorization: `Bearer ${this.props.user.token}` }})
        .then(res => res.json())
        .then((data) => {
          this.setState({
            database: data.db,
            geotabIntegration: g,
            authorizedGroups: g.authorizedGroups,
          });
          this.initializeApp(data.db);
        })
        .catch((err) => {
          console.error(err);
          this.setState({apiError: true});
        });
    });
    document.addEventListener("geotabSessionBlur", () => {
      this.setState({ selectedGroup: "", geotabIntegration: g });
    });
  };

  getAggregateProcessingState = (db) => {
    const url = `${this.props.apiURL}isAggregateProcessing?dbName=${db}`;
    return fetch(url, {headers: { Authorization: `Bearer ${this.props.user.token}` }})
      .then(res => res.json())
      .then((data) => {
        let processing = data.data[0].aggregate_processing;
        this.setState({ isAggregateProcessing: processing });
        return Promise.resolve(processing);
      })
      .catch((err) => {
        console.error(err);
        this.setState({apiError: true});
        return Promise.reject(err);
      });
  };

  getAnalyticsProcessingState = (db, shouldResetState) => {
    const url = `${this.props.apiURL}isAnalyticsProcessing?dbName=${db}`;
    return fetch(url, {headers: { Authorization: `Bearer ${this.props.user.token}` }})
      .then(res => res.json())
      .then((data) => {
        let processing = data.data[0].analytics_processing;
        this.setState({ isAnalyticsProcessing: processing });
        if (processing) {
          // return to the homepage
          this.props.history.push("/ezev/landing");
        } else {
          // if analytics is not processing, reset the state if we are initializing or the user updated candidates
          // if analytics is not processing, we don't want to reset state if this function was called from the single vehicle controller
          if (shouldResetState) this.handleProcessingFinished();
        }
        return Promise.resolve(processing);
      })
      .catch((err) => {
        console.error(err);
        this.setState({apiError: true});
        return Promise.reject(err);
      });
  };

  handleGroupSelect = (e, groupId) => {
    this.setState({hasRecievedDates: false});
    let group = this.state.groups.filter((g) => {
      return g.id === groupId;
    });
    this.fetchData(this.state.database, group, this.state.selectedVehicleCategory)
    this.getGroupMetrics(this.state.database, groupId);
    this.getGroupEzevMetrics(this.state.database, groupId);
    this.getTotalGroupEzevMetrics(this.state.database, groupId);
    this.setState({ selectedGroup: group[0], activity: null });
    let basename = "";
    if (this.props.geotabIntegrated) {
      basename = `/${this.state.database}/#`;
    }
    this.props.history.push(`${basename}/ezev/landing`);
  };

  handleVehicleClassSelect = (e) => {
    this.getGroups(this.state.database, e.value === "Light Duty", e.value === "All Vehicles");
    this.setState({ selectedVehicleCategory: e.value, activity: null })
  }

  getVehicles = (db, group, vehicleCategory) => {
    const {ld, all_categories} = this.getVehicleCategoryFlags(vehicleCategory);
    
    const url = `${this.props.apiURL}getGroupMembers?dbName=${db}&group_id=${group}&is_ld=${ld}&all_categories=${all_categories}`;
    fetch(url, {headers: { Authorization: `Bearer ${this.props.user.token}` }})
      .then(res => res.json())
      .then((data) => {
        const obj = processApiResponse(this.props.user.userSettings, data['data']);
        this.setState({ vehicles: obj });
      })
      .catch((err) => {
        console.error(err);
        this.setState({apiError: true});
      });
  };

  getEzevTemperatureMetrics = (db, group, vehicleCategory) => {
    const {ld, all_categories} = this.getVehicleCategoryFlags(vehicleCategory);

    if (typeof group === 'undefined') return;
    const url = `${this.props.apiURL}getEzevTemperatureMetrics?ident=${db}&group=${group}&is_ld=${ld}&all_categories=${all_categories}`;
    fetch(url, {headers: { Authorization: `Bearer ${this.props.user.token}` }})
      .then(res => res.json())
      .then((data) => {
        const obj = processApiResponse(this.props.user.userSettings, data.data[0]);
        this.setState({ fleetEzevMetrics: obj });
      })
      .catch((err) => {
        console.error(err);
        this.setState({apiError: true});
      });
  };

  getGroupMetrics = (db, group) => {
    const url = `${this.props.apiURL}getGroupMetrics?ident=${db}&group=${group}`;
    fetch(url, {headers: { Authorization: `Bearer ${this.props.user.token}` }})
      .then(res => res.json())
      .then((data) => {
        const obj = processApiResponse(this.props.user.userSettings, data.data);
        this.setState({ groupMinDate: obj.min_ts, groupMaxDate: obj.max_ts, hasRecievedDates: true});
      })
      .catch((err) => {
        console.error(err);
        this.setState({apiError: true});
      });
  };

  getGroupEzevMetrics = (db, group) => {
    const url = `${this.props.apiURL}getGroupEzevMetrics?ident=${db}&group=${group}`;
    fetch(url, {headers: { Authorization: `Bearer ${this.props.user.token}` }})
      .then(res => res.json())
      .then((data) => {
        data['data'].forEach((d) => {
          d = processApiResponse(this.props.user.userSettings, d);
        })
        const obj = data.data;
        this.setState({ groupEzevMetrics: obj });
      })
      .catch((err) => {
        console.error(err);
        this.setState({apiError: true})
      });
  };

  getTotalGroupEzevMetrics = (db, group) => {
    if (typeof group === 'undefined') return;
    const url = `${this.props.apiURL}getTotalGroupMetrics?ident=${db}&group=${group}`;
    fetch(url, {headers: { Authorization: `Bearer ${this.props.user.token}` }})
      .then(res => res.json())
      .then((data) => {
        const obj = processApiResponse(this.props.user.userSettings, data.data[0])
        this.setState({ totalGroupEzevMetrics: obj });
      })
      .catch((err) => {
        console.error(err);
        this.setState({apiError: true});
      });
  };

  getGroupRecommendedCount = (db, group, vehicleCategory = "All Vehicles") => {
    const {ld, all_categories} = this.getVehicleCategoryFlags(vehicleCategory);

    if (typeof group === 'undefined') return;
    const url = `${this.props.apiURL}getGroupRecommendedCount?ident=${db}&group=${group}&is_ld=${ld}&all_categories=${all_categories}`;
    fetch(url, {headers: { Authorization: `Bearer ${this.props.user.token}` }})
      .then(res => res.json())
      .then((data) => {
        const obj = data.data[0];
        this.setState({ groupRecommendedCount: obj.ev_recs });
      })
      .catch((err) => {
        console.error(err);
        this.setState({apiError: true});
      });
  };

  getParkingData = (db) => {
    fetch(`${this.props.apiURL}getEzevParkingLocations?dbName=${db}`,
        {headers: { Authorization: `Bearer ${this.props.user.token}` }
    })
    .then((resp)=>resp.json())
    .then((data) => {
        const parkingData = {};
        data.data.forEach((p)=>{
            if(!parkingData[p.vin]){
                parkingData[p.vin] = [];
            }
            const arr = parkingData[p.vin];
            arr.push(p); 
        });
        this.setState({parkingData: parkingData})
    })
  }

  _vehicleCount = () => {
    let c = 0;
    this.state.vehicles.forEach((v) => {
      if (v.selected) c++;
    });
    return c;
  };

  _filteredActivity = () => {
    if(!this.state.activity)return null;
    return this.state.activity.map(item => {
      item.asset_id = vehicleIdDisplay(item)
      //Add fuel type and coerce nulls to true for is_ld as per global default
      item.fuel_type = getFuelType(item);
      return item;
    })
  }

  render() {
    let db = this.state.database;
    let appWrapper = "ezev-app";
    let basename = "";
    if (this.props.geotabIntegrated) {
      appWrapper = "ezev-app-geotab";
      basename = `/${db}/#`;
    }
    //north carolina still uses their 2019 result so keep this cludge in place
    if (typeof db !== "undefined" && db.toLowerCase() === "nc_zev") {
      let url = `https://stdal.sawatchlabs.com/ezev.html?db=${db}&user=none`;
      return (
        <div className="ezev-wrapper">
          <iframe className="swt-ezev-frame" src={url} title="ezEV" />
        </div>
      );
    }

    if (this.state.isAnalyticsProcessing || this.state.isAggregateProcessing || this.state.apiError) {
      return (
        <ActiveProcessingPage
          {...this.props}
          database={this.state.database}
          displayName={this.state.displayName}
          setProcessingCountdownFinished={(value) => this.setState({ processingCountdownFinished: value })}
          apiError={this.state.apiError}
          aggregateProcessing={this.state.isAggregateProcessing}
        />
      );
    }

    return (
      <div className={appWrapper}>
        <div className="ezev-app-content">
          <Route
            exact
            path="/"
            render={(props) => (
              <Redirect to={{ pathname: `${basename}/ezev` }} />
            )}
          />
          <Route
            exact
            path="/ezev"
            render={(props) => (
              <Redirect to={{ pathname: `${basename}/ezev/landing` }} />
            )}
          />
          <Switch>
            <Route
              path="/ezev/landing"
              render={(props) => (
                <LandingPage
                  dbName={this.state.database}
                  group={this.state.selectedGroup}
                  groupMinDate={this.state.groupMinDate}
                  groupMaxDate={this.state.groupMaxDate}
                  hasRecievedDates={this.state.hasRecievedDates}
                  groupRecommendedCount={this.state.groupRecommendedCount}
                  totalGroupEzevMetrics={this.state.totalGroupEzevMetrics}
                  groupEzevMetrics={this.state.groupEzevMetrics}
                  fleetEzevMetrics={this.state.fleetEzevMetrics}
                  displayName={this.state.displayName}
                  user={this.props.user}
                  apiURL={this.props.apiURL}
                  groups={this.state.populatedGroups}
                  handleGroupSelect={this.handleGroupSelect}
                  vehicleCount={this._vehicleCount()}
                  selectedGroupId={
                    this.state.selectedGroup
                      ? this.state.selectedGroup.id
                      : null
                  }
                  selectedVehicleCategory={this.state.selectedVehicleCategory}
                  handleVehicleClassSelect={this.handleVehicleClassSelect}
                  activity={JSON.parse(JSON.stringify(this._filteredActivity()))}
                  settings={this.state.settings}
                  authorizedGroups={this.state.authorizedGroups}
                  parkingData={this.state.parkingData}
                  candidates={this.state.candidates}
                />
              )}
            />
            <Route
              path="/ezev/vehicle-results"
              exact
              render={(props) => (
                <TableController
                  activity={JSON.parse(JSON.stringify(this._filteredActivity()))}
                  group={this.state.selectedGroup}
                  multipleGroups={this.state.groups.length > 1}
                  clientName={this.props.dbDisplayName}
                  userSettings={this.props.user.userSettings}
                />
              )}
            />
            <Route
              path="/ezev/candidate-select"
              render={(props) => (
                <CandidateSelection
                  db={this.state.database}
                  apiURL={this.props.apiURL}
                  user={this.props.user}
                  devState={this.state.devState}
                  getAnalyticsProcessingState={this.getAnalyticsProcessingState}
                  displayName={this.state.displayName}
                  candidates={this.state.candidates}
                  userSettings={this.props.user.userSettings}
                />
              )}
            />
            <Route
              path="/ezev/vehicle/:vin"
              exact
              render={(props) => (
                <SingleVclController
                  db={this.state.database}
                  dbDisplayName={this.state.displayName}
                  user={this.props.user}
                  apiURL={this.props.apiURL}
                  localKwh={this.state.settings.local_kwh_cost}
                  settings={this.state.settings}
                  activity={JSON.parse(JSON.stringify(this._filteredActivity()))}
                  candidates={this.state.candidates}
                  otherParkingData={this.state.parkingData}
                  getAnalyticsProcessingState={this.getAnalyticsProcessingState}
                />
              )}
            />
            <Route
              path="/ezev/assumptions"
              exact
              render={(props) => (
                <AssumptionsView
                  db={this.state.database}
                  user={this.props.user}
                  displayName={this.state.displayName}
                  apiURL={this.props.apiURL}
                  settings={this.state.settings}
                  userSettings={this.props.user.userSettings}
                  candidates={this.state.candidates}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Ezev;