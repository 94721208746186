import styled from "styled-components/macro";
import { Link } from "react-router-dom"
import * as colors from "./ColorScheme";




export type DisplayLayoutProps = {
    show: boolean
}
export const DisplayLayout = styled.div`
display:grid;
position: relative;
grid-template-columns: 80% 15%;
grid-template-rows: 3rem 4rem auto 3rem;
width: 100%;
max-width: 1150px;
margin: 0 auto;
`

export const TableTitle = styled.h1`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 35px;
color: ${colors.ezio_dark_grey};
grid-row: 1 / span 1;
`


export const TableSubtitle = styled.span`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: ${colors.ezio_medium_grey};
grid-row: 2/ span 1;


`
const centeredLinkInButton = `
display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: inherit;
text-decoration-line: none;
`


const buttonStyles = `
width: 146px;
height: 43px;
background: #FFFFFF;
border: 2px solid ${colors.ezio_dark_grey};
font-family: "Arimo", "Roboto";
font-style: normal;
cursor: pointer;
font-weight: 500;
font-size: 16px;
align-items: center;
justify-content: center;
display:flex;
transition: 0.1s filter linear;
&:hover {
    filter: drop-shadow(4px 2px 10px rgba(74, 74, 74, 0.18));
}
a{
    ${centeredLinkInButton}
}
`


export const SwapViewButton = styled.div`
grid-column: 2;
grid-row: 1;
top: 0;
right: 0;
${buttonStyles}
`
export const CSVDownloadButton = styled.div`

${buttonStyles}

grid-row:4  / span 1;
grid-column: 1 / span 1;

`


export const MainInfoWrapper = styled.div`
grid-column: 1 / span 2;`

export const ButtonLink = styled(Link)`
${centeredLinkInButton}
`

export const CellFillLink = styled(Link)`
color: inherit;
text-decoration: inherit;
`

export const ViewGraphLink = styled(Link)`
color: inherit;
text-decoration: underline;
`

export const MapHeaderText = styled.div`
margin-top:10px; 
`