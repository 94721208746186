import React, { useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import * as S from "../../styles/ezio-styles/Header-styles"

import useOutsideAlerter from "../../utils/ezio-utils/useOutsideAlerter"
import { ReactComponent as DownArrow } from "../../images/navdownarrow-10px-12px.svg"
import DateSelectors from "./DateSelectors";
import { DateTime } from "luxon";


type HeaderProps = {
    displayName: string | null,
    handleDateChange: Function,
    selectedBeginDate?: DateTime,
    selectedEndDate?: DateTime,
    minDate?: DateTime,
    maxDate?: DateTime,
    location?: string,
    userSettings: any,
    limitedDateRange: boolean
}

export default function Header({ 
        displayName,
        handleDateChange,
        selectedBeginDate,
        selectedEndDate,
        minDate,
        maxDate,
        location,
        userSettings,
        limitedDateRange
    }: HeaderProps) {

    const showDatepicker = location?.includes('kw-demand') ? false : true;

    const [locationsOpen, setLocationsOpen] = useState(false)
    // const [adminOpen, setAdminOpen] = useState(false)
    
    const route = useLocation();
    const locationsRef = useRef(null)
    // const adminRef = useRef(null)
    useOutsideAlerter(locationsRef, () => setLocationsOpen(false))
    // useOutsideAlerter(adminRef, () => setAdminOpen(false))
    return (
        <S.HeaderContainer>
            <S.LHSContentWrapper>
                <S.ImageContainer>
                    <S.LogoLink to="/ezio" />
                </S.ImageContainer>
                <S.HeaderAndFleetWrapper>
                <S.FleetNameContainer>
                    {displayName}
                </S.FleetNameContainer>
                <S.NavLinkList>
                    <S.NavLinkListItem>
                        <S.NavLink to="/ezio" selected={route.pathname === '/ezio'}>Summary</S.NavLink >
                    </S.NavLinkListItem>
                    <S.NavLinkListItem ref={locationsRef}>
                        <S.DropdownMenu
                            onClick={() => {setLocationsOpen(!locationsOpen)}}
                            selected={route.pathname.includes("/locations") || route.pathname.includes("/kw-demand")}
                        >
                            Locations
                        </S.DropdownMenu>
                        <S.DownArrowWrapper>
                            <DownArrow />
                        </S.DownArrowWrapper>
                        {locationsOpen &&
                            <S.LocationsDropdownOptions onClick={() => setLocationsOpen(false)}>
                                <S.OptionLink to="/ezio/locations/list">
                                    List
                                </S.OptionLink>
                                <S.OptionLink to="/ezio/locations/map">
                                    Map
                                </S.OptionLink>
                            </S.LocationsDropdownOptions>
                        }
                    </S.NavLinkListItem>
                    <S.NavLinkListItem>
                        <S.NavLink to="/ezio/about" selected={route.pathname === '/ezio/about'}>About</S.NavLink >
                    </S.NavLinkListItem>
                </S.NavLinkList>
                </S.HeaderAndFleetWrapper>
            </S.LHSContentWrapper>
            <S.HeaderDateSelectorsContainer>
                <DateSelectors
                    dateBounds={{min: minDate, max: maxDate}}
                    selectedDates={{min:selectedBeginDate, max: selectedEndDate}}
                    handleChange={handleDateChange}
                    disabled={!showDatepicker}
                    height="36px"
                    width="100px"
                    fontSize="16px"
                    userSettings={userSettings}
                    limitedDateRange={limitedDateRange}
                />
            </S.HeaderDateSelectorsContainer>
        </S.HeaderContainer>
    )
}

// NOTE this is navigation for 'Admin' drop down
/* <S.NavLinkListItem ref={adminRef}>
<S.DropdownMenu onClick={() => {
    setAdminOpen(!adminOpen)
    setLocationsOpen(false)
}}>Admin<S.DownArrowWrapper>
        <DownArrow />
    </S.DownArrowWrapper></S.DropdownMenu>
{adminOpen &&
    <S.AdminDropdownOptions onClick={() => setAdminOpen(false)} >
        <S.OptionLink to="/ezio/select-locations">
            Select Locations
        </S.OptionLink>
        <S.OptionLink to="/ezio/select-vehicles">
            Select Vehicles
        </S.OptionLink>
        <S.OptionLink to="/ezio/edit-location-details">
            Edit Location Details
        </S.OptionLink>
        <S.OptionLink to="/ezio/join-locations">
            Join Locations
        </S.OptionLink>
        <S.OptionLink to="/ezio/separate-locations">
            Separate Locations
        </S.OptionLink>
    </S.AdminDropdownOptions>
}
</S.NavLinkListItem> */