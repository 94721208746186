import React, {useEffect, useState, useCallback, useMemo, useRef} from 'react';
import AdminTableView from './AdminTableView';
import Search from "./search";
import Loading from "../swt-loading";
import CreateUserModal from "./CreateUserModal";
import UpdateDatabaseModal from "./UpdateDbsModal";
import {formatDateTimeUTC} from '../UtilityFunctions';
import * as S from '../../../../styles/core-styles/AdminTools-styles';

const SERVER_ERRORED_MESSAGE = "There was a server error during your request.";

export default function SuperAdminUsers(props) {
    const { apiURL, db, passwordRules, user } = props;

    const [users, setUsers] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [databases, setDatabases] = useState();
    const [matchingUsers, setMatchingUsers] = useState();
    const [showCreateUserModal, setShowCreateUserModal] = useState(false);
    const [showDatabaseModal, setShowDatabaseModal] = useState(false);

    

    const skipPageResetRef = useRef(false)

    useEffect(() => {
        getUserRecords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiURL, db, user])


    ///API fetch functions

    function getUserRecords() {
        let url = `${props.apiURL}getUsers/`;
        if (props.db) url = `${props.apiURL}getUsers?dbName=default`;
        fetch(url, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
          .then(props.handleFetchErrors)
          .then((res) => res.json())
          .then((json) => {
            if (json.status === "error")
              alert(SERVER_ERRORED_MESSAGE);
            else {
              let sorted = json.data.sort((a, b) => (a.email > b.email ? -1 : 1));
              //getSelectedUserDatabases(json.data);
              setUsers(sorted);
            }
          })
          .catch((err) => console.error("error on getUsers fetch", err));
      }

      const getDatabasesForUser = useCallback((selectedUser, callback) => {
          const url = `${apiURL}getDatabasesForUser/${selectedUser.email}`;
          try {
              fetch(url,{
                  headers: {Authorization: `Bearer ${user.token}`}
              })
              .then((resp) => resp.json())
              .then((resp) => {
                if (resp.status === "error")
                  alert(SERVER_ERRORED_MESSAGE);
                else {
                  selectedUser.databases = resp.data.map((d) => {return d.database});
                  if(callback)callback(selectedUser);
                }
              });
          }
          catch (err){
              console.error(err);
              window.alert('There as an error getting databases info.')
          }
      },[apiURL, user]);

/*
    function getGroups(db, callback) {
        const url = `${apiURL}getGroups/${db}`;
        fetch(url, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
          .then((res) => res.json())
          .then((data) => {
            const groups = data.data;
            groups.sort(function (a, b) {return a.name.localeCompare(b.name);});
            if(callback)callback(groups);
          })
          .catch((err) => {
            window.alert(`Error getting groups for database ${db}`);
            console.error(err);
        });
      }
*/
      useMemo(() => {
        const url = `${apiURL}getDatabases`;
        try {
          fetch(url, {
            headers: { Authorization: `Bearer ${user.token}` },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === "error")
                alert(SERVER_ERRORED_MESSAGE);
              else
                setDatabases(data.data);
            });
        } catch (err) {
          console.error("error", err);
          window.alert('There as an error getting databases info.');
        }
      },[apiURL, user]);

/*
      const createUserObject = useCallback(() => {
        if (!users || !userDBs || (users.length < 1 && userDBs.length < 1)) return;
        else {
          let arr = users.map((user) => {
            let otherDBs = userDBs.filter((u) => u.email === user.email);
            return {
              admin: user.admin,
              superAdmin: user.super_admin,
              db: user.primary_db,
              developer: user.developer,
              email: user.email,
              lastLogin: user.last_login,
              otherDBs: otherDBs[0] === undefined ? [] : otherDBs[0].dbs,
            };
          });
          //setUpdatedUsers(arr);
        }
      }, [userDBs, users]);
*/

/*
      useEffect(() => {
        if (users && userDBs && users.length > 0 && userDBs.length > 0)
          createUserObject();
      }, [users, databases, userDBs, createUserObject]);
*/
      ///End API fetch functions

      //Table Data Code

      const boolSort = useMemo(() => (rowA, rowB, columnId) => {
        const a = rowA.values[columnId].props.bool;
        const b = rowB.values[columnId].props.bool;
        return a > b ? 1 : -1;
      },[]);

      const tableColumns = [
        {Header: "Email", accessor: "email", width: 300},
        {Header: "Last Login", accessor: "last_login", width: 200},
        {Header: "Primary Database", accessor: "primary_db", width: 250},
        {Header: "Basic User", accessor: "basic_user", sortType: boolSort, width: 120},
        {Header: "Fleet Admin", accessor: "fleet_admin", sortType: boolSort, width: 120},
        {Header: "Partner Admin", accessor: "partner_admin", sortType: boolSort, width: 120},
        {Header: "Super Admin", accessor: "super_admin", sortType: boolSort, width: 120},
        {Header: "Developer", accessor: "developer", sortType: boolSort, width: 120},
        {Header: 'Update Databases', accessor: 'update_dbs', disableSortBy: true, canSort: false, width: 150},
        {Header: 'Delete User', accessor: 'delete_user', disableSortBy: true, canSort: false, width: 150}
      ]

      const BoolTableCell = useCallback((props) => 
      <input
        className='swt-admin-table-input'
        type="checkbox"
        accessor={props.accessor} 
        checked={(props.bool === null || typeof props.bool === "undefined") ? false : props.bool} 
        email={props.email}
        onChange={props.handleOnClick}
        disabled={props.disabled}
      />,[]);

      const TableButton = useCallback((props) => 
        <S.InnerTableButton color={props.color} onClick={props.handleClick}>
        {props.label}
        </S.InnerTableButton>,[]);

      const mappedUsers = useMemo(() => {
          if(!users || !matchingUsers) {return null}
          const ImmutableUserList = JSON.parse(JSON.stringify(users))
          const matchedUsers = matchingUsers.map((u) => { return u.email})
  
          return ImmutableUserList.filter((u) => {
              if(matchedUsers.indexOf(u.email) < 0) {return null;}
              let devBool = u.developer;
              let fleetAdminBool = u.fleet_admin;
              let partnerAdminBool = u.partner_admin;
              u.last_login = formatDateTimeUTC(u.last_login);
              u.basic_user = <BoolTableCell accessor={"basic_user"} email={u.email} bool={u.basic_user} disabled={true} handleOnClick={() => {}}/>;
              u.developer = <BoolTableCell accessor={"developer"} email={u.email} bool={u.developer} handleOnClick={() => {updateUserRights(u.email, !devBool, 'developer')}}/>;
              u.fleet_admin = <BoolTableCell accessor={"fleet_admin"} email={u.email} bool={u.fleet_admin} handleOnClick={() => {updateUserRights(u.email, !fleetAdminBool, 'fleet_admin')}}/>;
              u.partner_admin = <BoolTableCell accessor={"partner_admin"} email={u.email} bool={u.partner_admin} handleOnClick={() => {updateUserRights(u.email, !partnerAdminBool, 'partner_admin')}}/>;
              u.super_admin = <BoolTableCell accessor={"super_admin"} email={u.email} bool={u.super_admin} disabled={true} handleOnClick={() => {}}/>;
              u.update_dbs = <TableButton handleClick={() => {showUpdateDatabaseModal(u)}} label={'Update'} />
              u.delete_user = <TableButton handleClick={() => {handleDeleteClick(u.email)}} label={'Delete'} color={'#e01f1f'} />
        return u;
      });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[matchingUsers, users])

  function deleteUser(data) {
    if (data === null || data === undefined)
      console.error("no data for request");
    let url = `${props.apiURL}deleteUser`;
    try {
      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "error")
            alert(SERVER_ERRORED_MESSAGE)
          else
            getUserRecords()
        });
    } catch (err) {
      console.error("error", err);
    }
  }


  function handleDeleteClick(username) {
    var answer = window.confirm(
      `Are you sure you wish to delete the user ${username}? This will permanently delete the user from all databases. This action is irreversible.`
    );
    if (answer) {
      let d = { username: username };
      deleteUser(d);
      var updatedUserList = users.filter((u) => u.email !== username);
      setUsers(updatedUserList);
    }
  }

  function updateDBs(data) {
    let errorList = []
    const url = `${props.apiURL}updateUserDatabases/`;
    const promises = data.map((d) => {
      return fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify(d),
      }).then((resp) => resp.json())
    })
    Promise.allSettled(promises).then((values) => {
     values.forEach((v, idx) => {
      if(v.value.status === 'error') {
       return errorList.push(`${data[idx].database} - ${v.value.message.split('-')[1]}`)
      }
     })
    }).then((data) => {
    if(errorList.length > 0) {
      alert(`There was an issue with adding the following databases:\n${errorList.join('\n')}`)
    } else {
      window.alert('Updated users databases.')
    }
  }
    )
  }

  function removeDBs (data) {
    const url = `${apiURL}removeUserDatabases/`;
    let errorList = [];
    const promises = data.map((d) => {
      return fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify(d),
      }).then((resp) => resp.json())
    })

    Promise.allSettled(promises).then((values) => {
      values.forEach((v, idx) => {
       if(v.value.status === 'error') {
        return errorList.push(`${data[idx].database} - ${v.value.message.split('-')[1]}`)
       }
      })
     }).then((data) => {
      if(errorList.length > 0) {
        alert(`There was an issue removing the following databases:\n${errorList.join('\n')}`)
      } else {
        alert('Removed user from databases.')
      }
     })
  }

  const showUpdateDatabaseModal = (selectedUser) => {
    //swap the mutated userObject(with table properties) 
    //for the original object held in state
    const userObject = users.find((u) => u.email === selectedUser.email);
    getDatabasesForUser(userObject, (u)=>{
      setShowDatabaseModal(!showDatabaseModal);
      setSelectedUser(u);
    });
  }

  function createUser(userData) {
    let url = `${props.apiURL}createUser/`;
    try {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify(userData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            getUserRecords();
            props.createNewUserPassword(userData.username);
            window.alert('New user added.')
          } else {
            window.alert("An error occured. Please try again.");
          }
        });
    } catch (err) {
      console.error("err", err);
    }
  }

    function createUserHandler(data) {
        createUser(data);
        setShowCreateUserModal(false);
    }

    function updateUserRights(email, access, permission) {
    skipPageResetRef.current = false
    let data = { email: email, access: access, permission: permission };
    let url = `${props.apiURL}updateUserRights/`;
    try {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "error") 
            window.alert(SERVER_ERRORED_MESSAGE);
          else 
            getUserRecords();
        });
    } catch (err) {
      console.error("fail", err);
    }
  }

    //End Table Data Code

    if (!users)return <Loading />;

    return (
        <>
        <S.TableHeaderContainer>
            <div>
                <S.AdminTableExplainTextPrimary>List of all users.</S.AdminTableExplainTextPrimary>
                <S.AdminTableExplainTextSub>Click a column name to sort the table.</S.AdminTableExplainTextSub> 
                <S.SearchContainer>
                    <Search allValues={users} setMatchingValues={setMatchingUsers} skipPageResetRef={skipPageResetRef}/>
                </S.SearchContainer>
                <S.CtaButton onClick={() => setShowCreateUserModal(!showCreateUserModal)}>Add</S.CtaButton>
            </div>
        </S.TableHeaderContainer>
        
        <AdminTableView 
            columns={tableColumns}
            data={mappedUsers ? mappedUsers : []}
            stickyCols={2}
            skipPageResetRef={skipPageResetRef}
        />

        <CreateUserModal
            user={user}
            apiURL={apiURL}
            db={props.db ? props.db : "experimental"}
            groups={null}
            databases={databases}
            show={showCreateUserModal}
            handleClose={() => setShowCreateUserModal(!setShowCreateUserModal)}
            createUser={createUserHandler}
            passwordRules={props.passwordRules}
        />


        {showDatabaseModal && selectedUser &&
          <UpdateDatabaseModal
            databases={databases}
            removeDBs={removeDBs}
            updateDBs={updateDBs}
            selectedUser={selectedUser}
            show={showDatabaseModal}
            handleClose={() => setShowDatabaseModal(!showDatabaseModal)}
            passwordRules={passwordRules}
            updateDatabases={props.updateDatabases}
            user={user}
          />
        }

      </>
    )

}