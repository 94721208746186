export function FormatSavings(val, userSettings) {
  if(val === '-') return val
  var value = parseInt(val);
  if (value < -100000) {
    return `More than -${userSettings.currency_symbol}100,000`;
  }
  else if (-100000 <= value && value < -96000) {
    return `-${userSettings.currency_symbol}96,000-100,000`;
  }
  else if (-96000 <= value && value < -93000) {
    return `-${userSettings.currency_symbol}93,000-96,000`;
  }
  else if (-93000 <= value && value < -90000) {
    return `-${userSettings.currency_symbol}90,000-93,000`;
  }
  else if (-90000 <= value && value < -87000) {
    return `-${userSettings.currency_symbol}87,000-90,000`;
  }
  else if (-87000 <= value && value < -84000) {
    return `-${userSettings.currency_symbol}84,000-87,000`
  }
  else if (-84000 <= value && value < -81000) {
    return `-${userSettings.currency_symbol}81,000-84,000`;
  }
  else if (-81000 <= value && value < -78000) {
    return `-${userSettings.currency_symbol}78,000-81,000`
  }
  else if (-78000 <= value && value < -75000) {
    return `-${userSettings.currency_symbol}75,000-78,000`;
  }
  else if (-75000 <= value && value < -72000) {
    return `-${userSettings.currency_symbol}72,000-75,000`
  }
  else if (-72000 <= value && value < -69000) {
    return `-${userSettings.currency_symbol}69,000-72,000`;
  }
  else if (-69000 <= value && value < -66000) {
    return `-${userSettings.currency_symbol}66,000-69,000`
  }
  else if (-66000 <= value && value < -63000) {
    return `-${userSettings.currency_symbol}63,000-66,000`;
  }
  else if (-63000 <= value && value < -60000) {
    return `-${userSettings.currency_symbol}60,000-63,000`
  }
  else if (-60000 <= value && value < -57000) {
    return `-${userSettings.currency_symbol}57,000-60,000`;
  }
  else if (-57000 <= value && value < -54000) {
    return `-${userSettings.currency_symbol}54,000-57,000`
  }
  else if (-54000 <= value && value < -51000) {
    return `-${userSettings.currency_symbol}51,000-54,000`;
  }
  else if (-51000 <= value && value < -48000) {
    return `-${userSettings.currency_symbol}48,000-51,000`
  }
  else if (-48000 <= value && value < -45000) {
    return `-${userSettings.currency_symbol}45,000-48,000`;
  }
  else if (-45000 <= value && value < -42000) {
    return `-${userSettings.currency_symbol}42,000-45,000`
  }
  else if (-42000 <= value && value < -39000) {
    return `-${userSettings.currency_symbol}39,000-42,000`;
  }
  else if (-39000 <= value && value < -36000) {
    return `-${userSettings.currency_symbol}36,000-39,000`
  }
  else if (-36000 <= value && value < -33000) {
    return `-${userSettings.currency_symbol}33,000-36,000`;
  }
  else if (-33000 <= value && value < -30000) {
    return `-${userSettings.currency_symbol}30,000-33,000`
  } else if (-30000 <= value && value < -27000) {
    return `-${userSettings.currency_symbol}27,000-30,000`;
  }
  else if (-27000 <= value && value < -24000) {
    return `-${userSettings.currency_symbol}24,000-27,000`
  } else if (-24000 <= value && value < -21000) {
    return `-${userSettings.currency_symbol}21,000-24,000`;
  }
  else if (-21000 <= value && value < -18000) {
    return `-${userSettings.currency_symbol}18,000-21,000`
  } else if (-18000 <= value && value < -15000) {
    return `-${userSettings.currency_symbol}15,000-18,000`;
  }
  else if (-15000 <= value && value < -12000) {
    return `-${userSettings.currency_symbol}12,000-15,000`;
  } else if (-12000 <= value && value < -9000) {
    return `-${userSettings.currency_symbol}9,000-12,000`;
  }
  else if (-9000 <= value && value < -6000) {
    return `-${userSettings.currency_symbol}6,000-9,000`
  } else if (-6000 <= value && value < -3000) {
    return `-${userSettings.currency_symbol}3,000-6,000`
  }
  else if (-3000 <= value && value < 3000) {
    return 'Cost parity'
  } else if (3000 <= value && value < 6000) {
    return `${userSettings.currency_symbol}3,000-6,000`
  }
  else if (6000 <= value && value < 9000) {
    return `${userSettings.currency_symbol}6,000-9,000`
  }
  else if (9000 <= value && value < 12000) {
    return `${userSettings.currency_symbol}9,000-12,000`
  }
  else if (12000 <= value && value < 15000) {
    return `${userSettings.currency_symbol}12,000-15,000`
  }
  else if (15000 <= value && value < 18000) {
    return `${userSettings.currency_symbol}15,000-18,000`
  }
  else if (18000 <= value && value < 21000) {
    return `${userSettings.currency_symbol}18,000-21,000`
  }
  else if (21000 <= value && value < 24000) {
    return `${userSettings.currency_symbol}21,000-24,000`
  }
  else if (24000 <= value && value < 27000) {
    return `${userSettings.currency_symbol}24,000-27,000`
  }
  else if (27000 <= value && value < 30000) {
    return `${userSettings.currency_symbol}27,000-30,000`
  }
  else if (30000 <= value && value < 33000) {
    return `${userSettings.currency_symbol}30,000-33,000`
  }
  else if (33000 <= value && value < 36000) {
    return `${userSettings.currency_symbol}33,000-36,000`
  }
  else if (36000 <= value && value < 39000) {
    return `${userSettings.currency_symbol}36,000-39,000`
  }
  else if (39000 <= value && value < 42000) {
    return `${userSettings.currency_symbol}39,000-42,000`
  }
  else if (42000 <= value && value < 45000) {
    return `${userSettings.currency_symbol}42,000-45,000`
  }
  else if (45000 <= value && value < 48000) {
    return `${userSettings.currency_symbol}45,000-48,000`
  }
  else if (48000 <= value && value < 51000) {
    return `${userSettings.currency_symbol}48,000-51,000`
  }
  else if (51000 <= value && value < 54000) {
    return `${userSettings.currency_symbol}51,000-54,000`
  }
  else if (54000 <= value && value < 57000) {
    return `${userSettings.currency_symbol}54,000-57,000`
  }
  else if (57000 <= value && value < 60000) {
    return `${userSettings.currency_symbol}57,000-60,000`
  }
  else if (60000 <= value && value < 63000) {
    return `${userSettings.currency_symbol}60,000-63,000`
  }
  else if (63000 <= value && value < 66000) {
    return `${userSettings.currency_symbol}63,000-66,000`
  }
  else if (66000 <= value && value < 69000) {
    return `${userSettings.currency_symbol}66,000-69,000`
  }
  else if (69000 <= value && value < 72000) {
    return `${userSettings.currency_symbol}69,000-72,000`
  }
  else if (72000 <= value && value < 75000) {
    return `${userSettings.currency_symbol}72,000-75,000`
  }
  else if (75000 <= value && value < 78000) {
    return `${userSettings.currency_symbol}75,000-78,000`
  }
  else if (78000 <= value && value < 81000) {
    return `${userSettings.currency_symbol}78,000-81,000`
  }
  else if (81000 <= value && value < 84000) {
    return `${userSettings.currency_symbol}81,000-84,000`
  }
  else if (84000 <= value && value < 87000) {
    return `${userSettings.currency_symbol}84,000-87,000`
  }
  else if (87000 <= value && value < 90000) {
    return `${userSettings.currency_symbol}87,000-90,000`
  }
  else if (90000 <= value && value < 93000) {
    return `${userSettings.currency_symbol}90,000-93,000`
  }
  else if (93000 <= value && value < 96000) {
    return `${userSettings.currency_symbol}93,000-96,000`
  }
  else if (96000 <= value && value <= 100000) {
    return `${userSettings.currency_symbol}96,000-100,000`
  }
  else if (value > 100000) {
    return `More than ${userSettings.currency_symbol}100,000`
  }
  else {
    return value;
  }
}

export function FormatCommas(value) {
  if (value === 0) {
    return 0
  }
  if (!value) return;
  // if number is decimal, toFixed
  if (parseFloat(value) % 1 !== 0) {
    value = parseFloat(value).toFixed(2)
  }
  // add commas if needed
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function FormatMonetaryValue(value,currency_symbol) {
  const FormattedValue = FormatCommas(value);
  if (value < 0) {
    return `-${currency_symbol}${FormattedValue.slice(1)}`;
  } else {
    return `${currency_symbol}${FormattedValue}`;
  }
}

export function FormatMaintPer15kKm(value) {
  // update to just be string formatting (or combine w/ above)
  if (value === 0) {
    return 0
  }
  if (!value) return;
  // convert maintenance dollars per km to per 15,000 km
  value = value * 15000
  value = Math.round(value)
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function FormatTemperatureRange(min, max, useCelsius){
  let hasMin = (min !== null) && (min !== undefined);
  let hasMax = (max !== null) && (max !== undefined)
  let formattedMin = hasMin && ((-1 < min && min < 0) ? 0 : min.toFixed(0)); //prevent an edge case where value could be "-0"
  let formattedMax = hasMax && ((-1 < max && max < 0) ? 0 : max.toFixed(0));
  const unit = useCelsius ? '°C' : '°F';

  if (!hasMin && !hasMax) return 'No Data';
  else if (hasMin && hasMax) return `${formattedMin}${unit} - ${formattedMax}${unit}`;
  else if (hasMin) return `${formattedMin}${unit}`;
  else if (hasMax) return `${formattedMax}${unit}`;
  else return 'No Data';
}

export function FormatGhgReductionAmt(value,userSettings) {
  let amt = FormatCommas(Math.round(value));
  let unitLabel = null;
  // These are values are hard coded right now since this is the only situation where this specific text can occur, but could/should be standardized in the future in user settings / dashboard core.
  if (userSettings.use_kgs) {
    unitLabel = (value === 1) ? "Metric Ton" : "Metric Tons";
  }  else {
    unitLabel = (value === 1) ? "Ton" : "Tons";
  }
  
  return `${amt} ${unitLabel}`;
}

export function FormatGhgReductionPct(value) {
  return `${Math.round(value * 100)}%`;
}