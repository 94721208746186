import React, {useEffect,useMemo,useRef} from 'react';
import { useSortBy, useTable, useFlexLayout, usePagination } from "react-table";
import arrowUnsel from "../../images/ic-arrow-unsort-20px-36px.png"
import arrowUp from "../../images/ic-arrow-asc-20px-36px.png"
import arrowDown from "../../images/ic-arrow-desc-20px-36px.png"
import * as S from '../../styles/ezev-styles/CandidatesTable-styles';
import { FormatCell } from './FormatTable';

// **Any styling changes done to this table will need to be changed in the CustomAssumptionsTable.jsx component. As of now these components do not share the same styles.

const headerProps = (props, { column }) => getStyles(props, column.align)

const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent:'flex-start',
      alignItems: 'center',
      display: 'flex',
    },
  },
]

export default function CandidatesTable(props) {
    const { columns, data } = props;

    let noData = 'No data to display.';

    const defaultColumn = useMemo(
        () => ({
          // When using the useFlexLayout
          // This is the default widths for every column
          // width is used for both the flex-basis and flex-grow
          width: 250,
        }),
        []
      )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        rows,
        state: { pageIndex }
      } = useTable(
        {
          columns,
          data,
          defaultColumn,
          initialState: {
            pageIndex: 0,
            pageSize: 30,
            sortBy: [
              {
                id: props.sortBy ? props.sortBy : columns[0].accessor,  
                desc: props.desc ? props.desc : true,
              },
            ],
          },
          manualSortBy: false,
          autoResetSortBy: false,
          autoResetPage: false
        },
        useSortBy,
        usePagination,
        useFlexLayout
      );

      const tableRef = useRef(null);
  
      useEffect(() => {
        tableRef.current.scrollTo({top:0});
      }, [pageIndex]);

      let widthsArr = [];
      return(
        <>
        <S.CandidatesTableHeaderContainer>
            <S.CandidatesTableHeader>{props?.tableHeader}</S.CandidatesTableHeader>
          </S.CandidatesTableHeaderContainer>
        <S.TableContainer ref={tableRef}>
          <S.StyledTable {...getTableProps()}>
            
            <S.TableHeadRow>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    widthsArr.push(column.width)
                    return(
                    <S.TableHeader
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      {...column.getHeaderProps(headerProps)}
                      columnsWidth={widthsArr}
                      styles={column.styles}
                    > 
                     
                      {column.render("Header")}{" "}
                      <S.TableSortArrows>
                          {column.canSort === false ?
                          <></>
                          :
                          column.isSorted
                            ? column.isSortedDesc
                              ? <S.SortArrowDown src={ arrowDown } alt="Column sorted in descending order" />
                              : <S.SortArrowUp src={ arrowUp } alt="Column sorted in ascending order" />
                            : <S.SortArrowUnsel src={ arrowUnsel } alt="Unsorted column" />}
                      </S.TableSortArrows>
                    </S.TableHeader>
    )})}
                </tr>
              ))}
            </S.TableHeadRow>
            
            <tbody {...getTableBodyProps()}>
              {page.map((row, idx) => {
                prepareRow(row);
                return (
                  <S.TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <S.TableCell
                          {...cell.getCellProps(cellProps)}
                          columnsWidth={widthsArr}
                          selected={cell.row.original.is_selected.props.value}
                          onClick={() => props.handleClick(cell.row.original.pkid)}
                          >
                          {FormatCell(cell.column.id, cell.value, null, props.userSettings)}
                        </S.TableCell>
                      );
                    })}
                  </S.TableRow>
                );
              })}
            </tbody>
          </S.StyledTable>
          </S.TableContainer>
          {rows.length < 1 
          ?
          <S.TableNoDataMessage>
            {noData}
          </S.TableNoDataMessage> 
          :
          <S.PaginationContainer>
            <S.PaginationButton
              onClick={previousPage}
              disabled={!canPreviousPage}>
              Previous
            </S.PaginationButton>
            <S.PaginationDetails>Page {pageIndex + 1} of {pageOptions.length}</S.PaginationDetails>
            <S.PaginationButton
              onClick={nextPage} 
              disabled={!canNextPage}>
              Next
            </S.PaginationButton>
          </S.PaginationContainer>
          }
        </>
      )    

}