import React, 
  { useMemo,
    useState, 
    useEffect, 
    useReducer } from "react"
import * as S from "../../styles/ezio-styles/LandingPage-styles";
import { ChargeLocation } from "../swt-ezio";
import { StatView } from "@sawatchlabs/swt-components";

//Stat View Properties
const VALUE_COLOR = "#4A4A4A";
const SUB_VALUE_COLOR ="#4A4A4A";
const HOVER_EFFECT="2px 2px 15px rgba(0, 0, 0, 0.18)";
const CAPTION_COLOR="#74787D";

type LandingProps = {
  selectedLocationId: number
  forceNavigate: Function
  chargeLocations: Map<number, ChargeLocation>
  totalVehicleCount: number;
  apiUrl: string
  user: {
    userSettings: {
      currency_symbol: string
    }
  }
  dbName: string
  noGroups: boolean
  selectedCategory: any
  settingsFullOn: boolean
}

type StatBubblesType = {
  vehiclesProjectedToCharge: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  }
  vehiclesWithPassingEvScore: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  }
  averageMonthlyEVChargingCost: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  },
  highestPeakDemand: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  },
  projectedChargingLocations: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  },
  level2PortsNeeded: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  }
  dcfcPortsNeeded: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  }
}

export default function LandingPage({ selectedLocationId, forceNavigate, chargeLocations, selectedCategory, noGroups, totalVehicleCount, settingsFullOn, user }: LandingProps) {
  
  const [showVehicleCountToolTip, setShowVehicleCountToolTip] = useState<Boolean>(false);
  const [cursorCoords, setCursorCoords] = useState({x: 0, y: 0});
  const currencySymbol = user.userSettings.currency_symbol;
  const handleShowVehicleCountToolTip = (show: Boolean, mouseEvent: any) => {
    setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
    setShowVehicleCountToolTip(show);
  }
  
  function navigateIfValidID(id: number): void {
    if (selectedLocationId <= 0) {
      return
    }
    else { forceNavigate(id) }
  }

  const [state, dispatch] = useReducer((state: StatBubblesType, action: Partial<StatBubblesType>) => ({
    ...state,
    ...action
  }), {
    vehiclesProjectedToCharge: {
      value: "",
      subValue: "",
    },
    vehiclesWithPassingEvScore: {
      value: ""
    },
    averageMonthlyEVChargingCost: {
      value: ""
    },
    highestPeakDemand: {
      value: ""
    },
    projectedChargingLocations: {
      value: ""
    },
    level2PortsNeeded: {
      value: ""
    },
    dcfcPortsNeeded: {
      value: ""
    }
  })

  //Calculations for "Vehicles in Selection/Total Vehicles"
  useEffect(() => {
    dispatch({
      vehiclesProjectedToCharge: {
        value: `${chargeLocations.get(selectedLocationId).chargingVehiclesCount}`,
        subValue: `/${totalVehicleCount}`,
        caption: "Projected to Charge/Total Vehicles"
      }
    }
    )
  }, [chargeLocations, selectedLocationId, totalVehicleCount])

  useEffect(() => {
    dispatch({
      vehiclesWithPassingEvScore: {
        value: `${chargeLocations.get(selectedLocationId).evRecommendationCount}`,
        caption: "Vehicles recommended for replacement with an EV"
      }
    })
  }, [chargeLocations, selectedLocationId]);

  //Calculations for "Average Monthly EV Charging Cost"
  useEffect(() => {
    //confusingly, the "monthlyPeaks" list also has the monthly cost as "cost"
    //which one might think is the peak cost for the month, but it's the total cost for the month. 

    const loc = chargeLocations.get(selectedLocationId);
    let cost = 0;
    let count = 0;
    for(const p in loc.monthlyPeaks){cost += loc.monthlyPeaks[p].cost;count++;}

    dispatch({
      averageMonthlyEVChargingCost: {
        value: `${currencySymbol}${(cost > 0 ? (Math.round(cost/count)) : 0).toLocaleString()}`,
        caption: selectedLocationId > -1 ? "Average Monthly EV Charging Cost" : "Average Monthly EV Charging Cost at Selected Locations"
      }
    })

  }, [chargeLocations, currencySymbol, selectedLocationId])
  //calculations for "Highest Peak Demand"
  useEffect(() => {
    const validLocs = Array.from(chargeLocations.values()).filter((l) => (l.pkid > -1 && l.maxVehiclesCharging > 0));
    let m = 0;
    validLocs.forEach((a)=>{for(const mp in a.monthlyPeaks){m = Math.max(m, a.monthlyPeaks[mp].peakKw)}});
    dispatch({
      highestPeakDemand: {
        value: `${m.toFixed(0)}`,
        subValue: " kW",
        caption: selectedLocationId > -1 ? "Highest Peak Demand at Same Time" : "Highest Peak Demand at Same Time at a Single Location"
      }
    })
  }, [chargeLocations, selectedLocationId])
  
  //calculations for "Projected Charging Locations"
  useEffect(() => {
    const validLocs = Array.from(chargeLocations.values()).filter((l) => (l.pkid > -1 && l.maxVehiclesCharging > 0));
    dispatch({
      projectedChargingLocations: {
        value: `${validLocs.length}`,
        caption: "Projected Charging Locations"
      }
    })
  }, [chargeLocations])
  //"L2 Ports Needed" & "DCFC Ports Needed" 
  useEffect(() => {
    const locationHistogram = chargeLocations.get(selectedLocationId).drawHistogram
    dispatch({
      level2PortsNeeded: {
        value: `${locationHistogram[0]}/${locationHistogram[1]}/${locationHistogram[2]}`,
        caption: `L2 Ports Needed`,
        subCaption: '(7kW/11kW/20kW)'
      }
    })
    dispatch({
      dcfcPortsNeeded: {
        value: `${locationHistogram[3] + locationHistogram[4]}`,
        caption: 'DCFC Ports Needed',
        subCaption: '(50kW+)'
      }
    })
  }, [chargeLocations, selectedLocationId])
    const countMismatch = useMemo(() => {
      if(!settingsFullOn)return false;
      const r = Array.from(chargeLocations.values()).filter((l) => l.pkid > -1);
      const i = r.reduce((p, n)=>{return p+n.chargingVehiclesCount},0);
      if(i < totalVehicleCount)return true;
      return false;
    }, [settingsFullOn, chargeLocations, totalVehicleCount]);
    
  const noDataMessage = noGroups ? `You do not have any groups assigned with ${selectedCategory.label} vehicles. Try updating the category selector or reach out to your admin to be added to applicable groups` : "No Data to Display. Try adjusting filters for more results."
  const misalignedVehicleCountsMessage = `Total Vehicles and Vehicles Projected to Charge are mismatched when not all vehicles under analysis have consistent projected charge locations.`
  return (
        <>
          {showVehicleCountToolTip &&
            <S.ToolTipWrapper
              cursorCoords={cursorCoords}
              onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
              onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
            >
              <S.ToolTipText>
                These are the numbers of vehicles with projected charging based on the selected filters.
              </S.ToolTipText>
            </S.ToolTipWrapper>
          }
          <S.NoDataMessage show={(chargeLocations.size === 0 || noGroups)}>{noDataMessage}</S.NoDataMessage>
          <S.StatBoxContainer>
            <S.StatBoxRow>
              <S.StatBoxLink
                to={selectedLocationId > 0 ? `/ezio/locations/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}
                onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
              >
                <StatView
                  valueId={"proj-charge-value"}
                  subValueId={"proj-charge-subvalue"}
                  captionId={"proj-charge-caption"}
                  values={state.vehiclesProjectedToCharge} 
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                  />
              </S.StatBoxLink>
              <S.StatBoxLink
                to={selectedLocationId > 0 ? `/ezio/locations/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}
              >
                <StatView
                  valueId={"recommended-vehicles-value"}
                  captionId={"recommended-vehicles-caption"}
                  values={state.vehiclesWithPassingEvScore} 
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                />
              </S.StatBoxLink>
              <S.StatBoxLink
                to={selectedLocationId > 0 ? `/ezio/locations/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}>
                <StatView 
                  valueId={"avg-monthly-charging-cost-value"}
                  captionId={"avg-monthly-charging-cost-caption"}
                  values={state.averageMonthlyEVChargingCost}
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                />
              
              </S.StatBoxLink>
              {/* Max peakKw */}
              <S.StatBoxLink to={selectedLocationId > 0 ? `/ezio/kw-demand-monthly/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}>
                <StatView 
                  valueId={"highest-peak-demand-value"}
                  subValueId={"highest-peak-demand-subvalue"}
                  captionId={"highest-peak-demand-caption"}
                  values = {state.highestPeakDemand}
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                />
              </S.StatBoxLink>
              {/* Count of non-empty locations */}


            </S.StatBoxRow>
            <S.StatBoxRow>
              <S.StatBoxLink to={selectedLocationId > 0 ? `/ezio/locations/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}>
                <StatView 
                  valueId={"projected-charging-locations-value"}
                  captionId={"projected-charging-locations-caption"}
                  values={state.projectedChargingLocations}
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                />
              </S.StatBoxLink>

              <S.StatBoxLink to={selectedLocationId > 0 ? `/ezio/locations/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}>
                <StatView 
                  valueId={"level2-port-counts-value"}
                  captionId={"level2-port-counts-caption"}
                  subCaptionId={"level2-port-counts-subcaption"}
                  values={state.level2PortsNeeded}
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                />

              </S.StatBoxLink>

              <S.StatBoxLink to={selectedLocationId > 0 ? `/ezio/locations/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}>
                <StatView 
                  valueId={"dcfc-port-count-value"}
                  captionId={"dcfc-port-count-caption"}
                  subCaptionId={"dcfc-port-count-subcaption"}
                  values={state.dcfcPortsNeeded}
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                />

              </S.StatBoxLink>
            </S.StatBoxRow>
            <S.CountMismatchMessage show={settingsFullOn && countMismatch}><sup>*</sup>{misalignedVehicleCountsMessage}</S.CountMismatchMessage>
          </S.StatBoxContainer >
        </>
  )
}

