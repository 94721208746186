import React, { useEffect, useState, useMemo, Suspense } from "react";
import { ChargeLocation } from "../swt-ezio";
import Table from "./Table";
import * as S from "../../styles/ezio-styles/LocationsDisplay-styles";
import { Redirect } from "react-router-dom";
import { columnsToHeaders, rowsToData } from "../../utils/ezio-utils/CSVUtils";
import { DateTime } from "luxon";

//code-splitting imports
const LocationsMap = React.lazy(() => import("./LocationsMap"));
const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));


type LocationsDisplayProps =
    {
        chargeLocations: Map<number, ChargeLocation>
        viewMode: "map" | "list"
        dbName: string
        forceNavigate: Function
        selectedChargeLocation?: number
        category?: string,
        vehicleClasses?: Array<string>,
        group?: string,
        electrification?: number,
        beginDate?: DateTime,
        endDate?: DateTime,
        dbDisplayName: string,
        userSettings: any
    }   

const tableText = {
    title: "EVSE Locations",
    subtitle: <div>
        This table lists the locations where vehicles are projected to charge. Select a row below to see the location summary. <br />
        If a location is not showing any vehicles or charging data, try adjusting the filters above.
    </div>,
    buttonText: "Map View"
}

const mapText = {
    title: "EVSE Locations",
    subtitle:
        <S.MapHeaderText>
            This map shows the projected charging locations for your vehicles. Click on the location to view the kW demand at each site.<br />
            If a location is not showing any vehicles or charging data, try adjusting the filters above.
        </S.MapHeaderText>,
    buttonText: "List View"
}


export default function LocationsDisplay({ chargeLocations, viewMode, dbName, forceNavigate, selectedChargeLocation, category, group, electrification, vehicleClasses, beginDate, endDate, dbDisplayName, userSettings }: LocationsDisplayProps) {

    const [infoText, setInfoText] = useState(viewMode === "list" ? tableText : mapText);
    //const [mergedLocations, setMergedLocations] = useState<Array<ChargeLocation>>([]);

/*

    //Merge the locations and combine data from the location details endpoint and the location groupings endpoint
    useEffect(() => {
        function getDetailsAboutParsed(location: ChargeLocation) {

            var mergedDetails: ChargeLocation = {
                isSelected: location.isSelected,
                pkids: location.locationIds,
                address: location.address,
                latitude: location.latitude,
                longitude: location.longitude,
                vehicleCount: 0,
                chargeDurationSeconds: 0,
                chargeDayCount: 0,
                peakKw: 0,
                peakCost: 0,
                evRecommendationCount: 0,
                locationEmpty: true,
                maxVehiclesCharging: 0,
            }
            location.locationIds.forEach((pkid) => {
                if (locationDetails.has(pkid)) {
                    let currDetails: DetailedLocation = locationDetails.get(pkid)!
                    mergedDetails.vehicleCount = mergedDetails.vehicleCount + parseInt(currDetails.vehicleCount)
                    mergedDetails.chargeDurationSeconds += currDetails.chargeDurationSeconds
                    mergedDetails.chargeDayCount = mergedDetails.chargeDayCount + parseInt(currDetails.chargeDayCount)
                    mergedDetails.peakKw += currDetails.peakKw
                    mergedDetails.peakCost += currDetails.peakCost
                    mergedDetails.evRecommendationCount += parseInt(currDetails.evRecommendationCount)
                    mergedDetails.locationEmpty = false
                    mergedDetails.maxVehiclesCharging += parseInt(currDetails.maxVehiclesCharging)
                }

            })
            return (mergedDetails)
        }
        setMergedLocations(locations.map((item) => {
            return (getDetailsAboutParsed(item))
        })
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationDetails])

*/


    const locationsWithSelected = useMemo(() => {
        let r = Array.from(chargeLocations.values());
        return(r.filter((l)=> l.chargingVehiclesCount > 0 && l.pkid > -1));
    }, [chargeLocations]);


    useEffect(() => {
        if (viewMode === "list") {
            setInfoText(tableText)
        }
        else {
            setInfoText(mapText)
        }

    }, [viewMode])



    //Blank out the entry with a hyphen if the location had no associated details, otherwise run the accessor
    function blankIfEmpty(row: any, accessor: Function) {
        return (
            !row.locationEmpty
                ? accessor(row)
                : row.isSelected
                    ? "0"
                    : row.locationEmpty
                        ? "-"
                        : accessor(row))
    }

    function valueToLinkCell(cell: any) {
        if (!cell.row.original.locationEmpty || cell.row.original.isSelected) {
            return (
                <S.CellFillLink onClick={() => forceNavigate(cell.row.original.pkid)} to={`/ezio/locations/${cell.row.original.pkid}`}>{cell.cell.value}</S.CellFillLink>
            )
        }
        else {
            return (
                <div >{cell.cell.value}</div>
            )
        }

    }

    function AddressColumnFilter({ column: { filterValue, setFilter } }: any) {
        return (
            <input
                className="swt-ezio-search-input"
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined)// Set undefined to remove the filter entirely
                }}
                placeholder={`Search`}
            />
        )
    }

    // NOTE need error handling for all columns. Added check for division by zero to totalChargingHours but do NOT want to keep this pattern
    const LocationsTableColumns = useMemo(() => [
        {
            Header: "Location ID",
            simpleHeader: "Location ID",
            accessor: (row: any) => blankIfEmpty(row, (row: any) => row.pkid),
            Cell: valueToLinkCell,
            sortType: "basic", 
            id: "locationId",
        },
        {
            Header: "Location", accessor: (row: any) => row.address,
            simpleHeader: "Location",
            Cell: valueToLinkCell
            , sortType: "basic",
            Filter: AddressColumnFilter,
            minWidth: 306
        },
        {
            Header: "Number of Vehicles Charging",
            simpleHeader: "Number of Vehicles Charging",
            accessor: (row: any) => blankIfEmpty(row, (row: any) => row.chargingVehiclesCount),
            Cell: valueToLinkCell,
            sortType: "basic", id: "chargingVehiclesCount", Filter: <div />, width: 110
        },
        {
            Header: () => (
                <div className="ezio-table-header-long">
                    EV Rec
                    <br />
                    count
                </div>
            ), simpleHeader: "EV Rec count",
            id: "evRecommendationCount",
            accessor: (row: any) => blankIfEmpty(row, (row: any) => row.evRecommendationCount),
            Cell: valueToLinkCell
            , sortType: "basic", Filter: <div />
        },
        {
            Header: () => (
                <div className="ezio-table-header-long">
                    Total charging hours
                    <br />
                    (avg per day)
                </div>
            ), simpleHeader: "Total charging hours (avg per day)",
            id: "totalChargingHours", accessor: (row: any) => blankIfEmpty(row, (row: any) => (row.chargeDayCount > 0 ? ((row.chargeDurationSeconds / 3600) / row.chargeDayCount).toFixed(1) : 0)), sortType: "basic",
            Cell: valueToLinkCell, Filter: <div />, width: 140
        },
        {
            Header: "L2 Port count",
            simpleHeader: "L2 Port count",
            accessor: (row: any) => blankIfEmpty(row, (row: any) => row.drawHistogram[0] + row.drawHistogram[1] + row.drawHistogram[2]),
            Cell: valueToLinkCell,
            sortType: "basic", Filter: <div />, width: 120
        },
        {
            Header: "DC Port count",
            simpleHeader: "DC Port count",
            accessor: (row: any) => blankIfEmpty(row, (row: any) => row.drawHistogram[3] + row.drawHistogram[4]),
            Cell: valueToLinkCell,
            sortType: "basic", Filter: <div />, width: 120
        },
        {
            Header: () => (
                <div className="ezio-table-header-long">
                    Peak kW
                    <br />
                    Usage
                </div>
            ), simpleHeader: "Peak kW Usage", id: "peakKwUsage",
            accessor: (row: any) => blankIfEmpty(row, (row: any) => row.peakKw),
            Cell: valueToLinkCell, sortType: "basic", Filter: <div />, width: 95
        },
        {
            Header: () => (
                <div className="ezio-table-header-long">
                    kW Demand
                    <br />
                    graph
                </div>
            ), id: "demandGraph", accessor: (row: any) => `/ezio/kw-demand-monthly/${row.pkid.toString()}`, Cell: (cell: any) => {

                return (cell.row.original.locationEmpty ? "-" : <S.ViewGraphLink to={cell.value} onClick={() => forceNavigate(cell.row.original.pkid)}>View Graph</S.ViewGraphLink>
                )

            }, sortType: "basic", Filter: <div />
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [forceNavigate])

    return (
        <>
            <S.DisplayLayout>
                <S.TableTitle>
                    {infoText.title}
                </S.TableTitle>
                <S.TableSubtitle>
                    {infoText.subtitle}
                </S.TableSubtitle>

                <S.SwapViewButton >
                    <S.ButtonLink to={`/ezio/locations/${viewMode === "list" ? "map" : "list"}`}>{infoText.buttonText}</S.ButtonLink>

                </S.SwapViewButton>
                <S.MainInfoWrapper>
                    {viewMode === "list"
                        ? <Table columns={LocationsTableColumns} data={locationsWithSelected}
                            getRowProps={(row: any) => {
                                const shouldNotHighlight = row.original.locationEmpty && !row.original.isSelected
                                return (

                                    {
                                        style: {
                                            color: shouldNotHighlight ? '#74787D' : null,
                                            textDecorationLine: shouldNotHighlight ? 'none' : null,
                                            textShadow: shouldNotHighlight ? 'none' : null,
                                        },
                                    })
                            }} />


                        : viewMode === "map"
                            ? <Suspense fallback={<div></div>}><LocationsMap chargeLocations={chargeLocations} forceNavigate={forceNavigate} selectedLocationId={selectedChargeLocation} userSettings = {userSettings} /></Suspense>
                            : <Redirect to={"/ezio"} />
                    }

                </S.MainInfoWrapper>
                <div>
                    {viewMode === "list" &&
                        <Suspense fallback={<div></div>}>
                            <ExcelDownloadButton
                                category={category}
                                vehicleClasses={vehicleClasses}
                                group={group}
                                electrification={electrification}
                                beginDate={beginDate}
                                endDate={endDate}
                                db={dbName}
                                csvType={'locations'}
                                data={rowsToData(LocationsTableColumns, locationsWithSelected, (a: any, b: any) => a.vehicleCount < b.vehicleCount || !b.locationEmpty ? 1 : -1)}
                                columns={columnsToHeaders(LocationsTableColumns)}
                                dbDisplayName = {dbDisplayName}
                                userSettings={userSettings}
                            />
                        </Suspense>
                    }
                </div>


            </S.DisplayLayout>
        </>



    )
}
