import React from "react";
import { getMonth, roundValue, addCommas, formatDateString } from "./UtilityFunctions.js";
import { Roles } from "../core-components/utility-components/UserUtils";

// var METERS_TO_FT = 3.28084;

export function FormatAdditional(notes) {
  if (notes.length > 0) {
    const additionalHtml = notes.map((a) => <p key={a}>{a}</p>);
    return <div className="ionev-table-additional">{additionalHtml}</div>;
  } else return;
}

export function formatPeriod(period) {
  var start = period[0].toLocaleDateString().split("/");
  var end = period[1].toLocaleDateString().split("/");
  var startMonth = getMonth(start[0]);
  var endMonth = getMonth(end[0]);
  return `${startMonth} ${start[1]} ${start[2]} - ${endMonth} ${end[1]} ${end[2]}`;
}

export function formatPeriodStr(period) {
  var start = period[0].toLocaleDateString().split("/");
  var end = period[1].toLocaleDateString().split("/");
  return `${start[0]}_${start[1]}_${start[2]}-${end[0]}_${end[1]}_${end[2]}`;
}

export function GetColumnExplanation(accessor, userSettings) {

  switch(accessor){
    // when it was 100 miles / kwh
    // case 'mileskwh': return <p className = 'swt-ionev-th-explanation-text'><strong>100 Miles per kWh:</strong> Calculated using total kWh per 100 miles. COMING SOON: This value is not yet available for PHEVs</p>;
    case 'hours': return <p className = 'swt-ionev-th-explanation-text'><strong>Hours of Operation:</strong> Total hours the vehicle ignition was on</p>;
    // case 'hours_idling': return <p className = 'swt-ionev-th-explanation-text'><strong>Hours Idling:</strong> Total hours the vehicle ignition was on and was stationary</p>;
    case 'miles': return <p className = 'swt-ionev-th-explanation-text'><strong>{userSettings.distance_labels.longPlural}:</strong> Total {userSettings.distance_labels.longPlural.toLowerCase()} driven</p>;
    case 'date': return <p className = 'swt-ionev-th-explanation-text'><strong>Date:</strong> The last date that results were recorded</p>;
    case 'pct_elec': return <p className = 'swt-ionev-th-explanation-text'><strong>Electric {userSettings.distance_labels.longPlural} (%):</strong> Estimated electric {userSettings.distance_labels.longPlural.toLowerCase()} as a percentage of total {userSettings.distance_labels.longPlural.toLowerCase()}. Applicable only to PHEVs</p>;
    case 'asset_id': return <p className = 'swt-ionev-th-explanation-text'><strong>Asset ID:</strong> Client assigned vehicle identifier</p>;
    case 'kwh': return <p className = 'swt-ionev-th-explanation-text'><strong>kWh:</strong> Total estimated energy uptake by the battery. Negative values indicate the vehicle gained more kWh from regenerative braking then was discharged from the battery because the vehicle was relying on gas</p>;
    case 'map': return <p className = 'swt-ionev-th-explanation-text'><strong>Map:</strong> Click to view activity details</p>;
    // way too long. Not explanation of column, explanation of page!
    case 'missed_ops': return <p className = 'swt-ionev-th-explanation-text'><strong>Missed Opportunities:</strong> Count of instances where a vehicle’s SOC was below the designated threshold and the vehicle was parked near a known or public charging location for at least an hour. These are provided to help fleets identify opportunities where charging a vehicle could have increased the {userSettings.distance_labels.longPlural.toLowerCase()} that could have been driven on electricity.</p>;
    case 'missed_miles': return <p className = 'swt-ionev-th-explanation-text'><strong>Missed Electric {userSettings.distance_labels.longPlural}:</strong> Estimated {userSettings.distance_labels.longPlural.toLowerCase()} that could have been powered by the battery rather than gas</p>;
    case 'missed_kwh': return <p className = 'swt-ionev-th-explanation-text'><strong>Missed kWh:</strong>  Potential charging demand during missed opportunities</p>;
    case 'total_kwh': return <p className = 'swt-ionev-th-explanation-text'><strong>kWh:</strong> Total estimated energy uptake by the battery. Negative values indicate the vehicle gained more kWh from regenerative braking then was discharged from the battery because the vehicle was relying on gas</p>;
    case 'events': return <p className = 'swt-ionev-th-explanation-text'><strong>Events:</strong> Count of charging events</p>;
    case 'regen_kwh': return <p className = 'swt-ionev-th-explanation-text'><strong>Regen. kWh:</strong> Total estimated kWh generated by the battery due to regenerative braking. This kWh amount is not included in the total kWh value.</p>;
    case 'regen_events_count': return <p className = 'swt-ionev-th-explanation-text'><strong>Regen. Events:</strong> Count of captured regenerative braking events. These incidents are not included in the charge events count.</p>;
    case 'locations': return <p className = 'swt-ionev-th-explanation-text'><strong>Locations:</strong> Number of locations at which this vehicle charged</p>;
    case 'charge_events': return <p className = 'swt-ionev-th-explanation-text'><strong>Events:</strong> Count of charging events</p>;
    case 'charge_vehicles': return <p className = 'swt-ionev-th-explanation-text'><strong>Vehicles:</strong> Count of vehicles that charged at this location</p>;
    case 'edit_loc': return <p className = 'swt-ionev-th-explanation-text'><strong>Edit Location:</strong> Click the link to edit the location address, name, and additional info</p>;
    case 'charge_kwh': return <p className = 'swt-ionev-th-explanation-text'><strong> Average Charge kWh:</strong> Average kWh charged during a charging event at this location </p>;
    case 'soc_start': return <p className = 'swt-ionev-th-explanation-text'><strong> State of Charge (%):</strong> Battery SOC at the beginning of a charge event </p>;
    case 'missed_ops_start_soc': return <p className = 'swt-ionev-th-explanation-text'><strong> Average Start State of Charge (%):</strong> Average battery SOC at the beginning of the potential charge event </p>;
    case 'address_missed_ops': return <p className = 'swt-ionev-th-explanation-text'><strong> Address: </strong>address of nearby previously used charger (within ~500 ft) </p>;
    case 'charge_soc_stop': return <p className = 'swt-ionev-th-explanation-text'><strong> Average SOC at End:</strong> Average battery SOC at the end of a charge event </p>;
    case 'charge_missed_miles': return <p className = 'swt-ionev-th-explanation-text'><strong>Missed Electric {userSettings.distance_labels.longPlural}:</strong> Estimated electric {userSettings.distance_labels.longPlural.toLowerCase()} that could have been traveled, had the vehicle charged during all identified missed opportunity instances at this location</p>;
    case 'charge_loc_missed_ops': return <p className = 'swt-ionev-th-explanation-text'><strong>Missed Opportunities:</strong> Count of instances where a vehicle with a SOC below the designated threshold was parked near this location for at least an hour. These are provided to help fleets identify opportunities where charging a vehicle could have increased the {userSettings.distance_labels.longPlural.toLowerCase()} that could have been driven on electricity</p>;
    case 'duration_missed_ops': return <p className = 'swt-ionev-th-explanation-text'><strong>Duration:</strong> Amount of time the vehicle was parked at location</p>;
    case 'start': return <p className = 'swt-ionev-th-explanation-text'><strong>Start:</strong> Event start time</p>;
    case 'stop': return <p className = 'swt-ionev-th-explanation-text'><strong>Stop:</strong> Event stop time</p>;
    case 'mo_fuel_cost': return <p className = 'swt-ionev-th-explanation-text'><strong>Associated Fuel Cost:</strong> Cost of fuel associated with missed electric {userSettings.distance_labels.longPlural.toLowerCase()}</p>;
    case 'break_even': return <p className = 'swt-ionev-th-explanation-text'><strong>Break Even year:</strong> Calculated by determining the year at which operational savings offset the increased upfront cost of the EV compared to an ICE</p>;
    case 'break_even_age': return <p className = 'swt-ionev-th-explanation-text'><strong>Age at Break Even:</strong> Estimated age of the vehicle at which the operational savings will offset the increased upfront cost of the EV compares to an ICE</p>;
    case 'vcl_count': return <p className = 'swt-ionev-th-explanation-text'><strong>Vehicle Count:</strong> Number of vehicles that charged at each location during the period selected</p>;
    case 'reimb_kwh': return <p className = 'swt-ionev-th-explanation-text'><strong>Total kWh:</strong> Total kWh charged at each location during the period selected</p>;
    case 'reimbursement': return <p className = 'swt-ionev-th-explanation-text'><strong>Reimbursement:</strong> Reimbursement value for charging that occurred at each location during the period selected. The reimbursement rate is based on the electricity rate ($/kWh) provided by the fleet</p>;
    // case 'duration': return <p className = 'swt-ionev-th-explanation-text'><strong>Duration:</strong> Time spent charging (hrs)</p>;
    // case 'charge_duration': return <p className = 'swt-ionev-th-explanation-text'><strong>Average Duration:</strong> Average duration of charging events </p>;
    default: return null;
  }
}

export default function FormatCell(accessor, value, row, fuelCost, user, userSettings) {
  // don't need value now we pass in entire row - get using accessor
  var toRound = ["avg_soc_start", "charge_avg_soc_start", "charge_avg_soc_stop", "missed_avg_soc_start", "soc_start"];
  var toOneDec = ["blended_kwh", "kwh", "regen_kwh", "hours", "idle_hours", "charge_avg_kwh", "missed_avg_kwh", "total_kwh"];
  var toDateTime = ["start", "stop", "utc_start", "utc_stop", "vehicle_moved_local", "localized_start", "localized_stop"];
  var toDate = ["localized_date"];
  var toHours = ["duration", "charge_avg_duration", "missed_avg_duration"];
  var toMiles = ["km", "missed_elec_km", "avg_missed_elec_km"];
  var toInt = ["charge_event_count", "charge_distinct_vins", "missed_event_count", "missed_distinct_vins","events_count","regen_events_count","distinct_vins"];
  var toTen = ['ghgRedGms'];
  var changeVals = ['tcoChangeLt', 'opChangeLt', 'totalSavings', 'avgSavings'];
  var statsMiles = ['avgMiles', 'avgMKwh', 'avgDailyElecMiles'];
  var totalVals = ['chargeLocKwh', 'missedLocKwh', 'totalKwh'];
  switch (true) {
    case accessor === 'vin': {
      return row.display_vin;
    }
    case toOneDec.includes(accessor): {
      if (value === "-") return value;
      value = roundValue(value, 1);
      value = addCommas(value);
      if (!value) value = '-';
      return value;
    }
    case toMiles.includes(accessor): {
      if(value === "-")return value;
      value = roundValue(value, 0);
      value = addCommas(value);
      if (!value) value = "-";
      return value;
    }
    case toInt.includes(accessor): {
      if (value !== "-") value = parseInt(value);
      return value;
    }
    case toDateTime.includes(accessor): {
      if (!value.date || !value.time) return;
      value = `${value.date} ${value.time}`
      return value;
    }
    case toDate.includes(accessor): {
      if (!value.date) return;
      return value.date;
    }
    case toRound.includes(accessor): {
      value = roundValue(value, 0);
      if (!value) value = 0;
      return value;
    }
    case accessor === "charger_level": {
      if (!value) value = "Unknown";
      return value;
    }
    case accessor === "odometer_km": {
      value = roundValue(value, 0);
      value = addCommas(value);
      return value;
    }
    case toHours.includes(accessor): {
      value = value * 0.000277778; // seconds to hours
      value = roundValue(value, 1);
      value = addCommas(value);
      return value;
    }
    case accessor === "Date": {
      value = value.split("T")[0];
      return value;
    }
    case accessor === "fleet_evse": {
      value ? (value = "Yes") : (value = "No");
      return value;
    }
    case accessor === "afdc_evse": {
      value ? (value = "Yes") : (value = "No");
      return value;
    }
    case accessor === "location_type": {
      value = "Fleet";
      return value;
    }
    case accessor === "pct_elec": {
      if (value && value > -1) {
        value = roundValue(value, 0);
      } else {
        return "-";
      }
      return value;
    }
    case accessor === "landuse": {
      if (!value) {
        value = "N/A";
      } else if (value && value !== "-") {
        value = value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        value = "Unclassified";
      }
      return value;
    }
    case accessor === "reimbursement": {
      value = roundValue(value, 2);
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      value = `${userSettings.currency_symbol}${value}`;
      return value;
    }
    case accessor === "reimbursementLastMth": {
      if(typeof value === "string")return value;
      // trim decimal places before adding commas
      value = value.toFixed(2);
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      value = `${userSettings.currency_symbol}${value}`;
      return value;
    }
    case accessor === "cost": {
      if (value) {
        value = roundValue(value, 2);
        value = addCommas(value);
        return `${userSettings.currency_symbol}${value}`;
      } else {
        return "-";
      }
    }
    case accessor === "address": {
      var addr = value;
      if(!value)addr="N/A";
      if(row.nickname && row.landuse !== "residential")addr = row.nickname;
      // if fleet admin+, show nickname or real address
      if(user.role >= Roles.FleetAdmin){
        if(row.nickname)addr = row.nickname;
        else if(row.address_real)addr = row.address_real;
      }
      return addr;
    }
    case accessor === "address_real": {
      addr = value;
      if(row.nickname)addr = row.nickname;
      return addr;
    }
    case accessor === "year": {
      if(!row.make && !row.model)return value;
      let make = formatCapitalization(row.make)
      let model = formatCapitalization(row.model)
      return `${value} ${make} ${model}`;
    }
    case accessor === "mon": {
      if(!row.yyyy)return value;
      var year = row.yyyy.toString();
      // year = year.slice(2);
      return formatDateString(userSettings, value, year);
    }
    case accessor === "mo_fu_liters": {
      // actually gives cost of fuel assoc with missed opps
      if (value) {
        value = value*(row.fuel_cost ? row.fuel_cost : fuelCost);
        value = roundValue(value, 2);
        value = addCommas(value);
        if(value === "NaN")return "-";
        return `${userSettings.currency_symbol}${value}`;
      } else {
        return "-";
      }
    }
    case accessor === "missedMiles": {
      if(value === "-" || !value)return "-";
      if(value > 0 && value < 10)return '<10';
      if(value < 0 && value > -10)return '>-10';
      value = (Math.round(value/10))*10;
      value = addCommas(value);
      return value;
    }
    case statsMiles.includes(accessor): {
      // for total activity table
      if(!value) return '-';
      if(value > 1000)value = Math.round(value/10)*10;
      value = Math.round(value);
      value = addCommas(value);
      return value;
    }
    case toTen.includes(accessor): {
      // this serves table and landing page
      value = Math.round(value/10)*10;
      value = addCommas(value);
      return value;
    }
    case changeVals.includes(accessor): {
      if(isNaN(value)) return "-";
      if(value > 0 && value < 10)return `<${userSettings.currency_symbol}10`;
      if(value < 0 && value > -10)return `>-${userSettings.currency_symbol}10`;
      value = Math.round(value/10)*10;
      if(value < 0){
        // put negative before $
        value = addCommas(value);
        value = value.substring(1);
        return `-${userSettings.currency_symbol}${value}`;
      } else {
        value = addCommas(value);
        return `${userSettings.currency_symbol}${value}`;
      }
    }
    case accessor === "ghgRedPct": {
      if (!value) value = 0;
      value = `${roundValue(value, 0)}%`;
      return value;
    }
    case accessor === "fuelSavings": {
      // note this serves table and landing page fuelSavings
      if (!value) value = 0;
      // switch to round helper function
      value = Math.round(value/10)*10;
      value = addCommas(value);
      return value;
    }
    case accessor === "fuelCostSavings": {
      // note this serves table and landing page fuelCostSavings
      value = Math.round(value/10)*10;
      value = addCommas(value);
      return `${userSettings.currency_symbol}${value}`;
    }
    case totalVals.includes(accessor): {
      if(!value || value === "NaN") return '-';
      if(value > 0 && value < 10)return '<10';
      if(value < 0 && value > -10)return '>-10';
      value = (Math.round(value/10))*10;
      if(value === "NaN")return "-";
      value = addCommas(value);
      return value;
    }
    case accessor === "totalKwhInt": {
      if(!value) return '-';
      value = (Math.round(value/10))*10;
      if(value === "NaN")return "-";
      return value;
    }
    case accessor === "elec_km": {
      // sum(km) / NULLIF(sum(blended_kwh),0)
      if(!row.blended_kwh)return "-";
      let miles = value;
      var res = miles/row.blended_kwh
      if (res < 0 || !res){
        return "-";
      }
      res = roundValue(res, 1);
      res = addCommas(res);
      if (!res) res = "-";
      return res
    }
    default:
      if(!value && value !== 0)value = "-";
      return value;
  }
}

function formatCapitalization(value) {
  if(!value)return '';
  // NOTE may be preferable to remove toLowerCase
  // and remove EV/PHEV handling but involves updating
  // vehicle ymms in existing databases so should discuss
  value = value.toLowerCase();
  let strings = value.split(' ');
  strings.forEach((s, i) => {
    if(strings[i] === 'ev')strings[i] = 'EV';
    else if(strings[i] === 'phev')strings[i] = 'PHEV';
    else strings[i] = s.charAt(0).toUpperCase() + s.slice(1);
  })
  return strings.join(' ');
}
