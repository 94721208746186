import React from "react";
import SingleSelectDropdown from "./SingleSelectDropdown";
import { SearchSelect } from '@sawatchlabs/swt-components';


export default function DropdownSelector(props) {
  return (
    <SingleSelectDropdown
      className={props.selectorName + " ezev-UI-Text-light"}
      placeholder={props.value.name}
      value={{value: props.value.id, label: props.value.name}}
      onChange={props.handleSelectorChange}
      options={props.options}
    />
  );
}

const formatCandidateOptions = (options, props) => {
  //filteredOptions is used to filter out the recommended replacement so we dont list it twice in dropdown options.
  const filteredOptions = options.filter((cand) => {return cand.ymm !== props.recommendation})
  const inClassCands = filteredOptions.filter((cand) => {return cand.vehicle_class === props.vcl.vehicle_class})
  const notInClassCands = filteredOptions.filter((cand) => {return cand.vehicle_class !== props.vcl.vehicle_class})

  //This structure allows for options to be grouped even when options are filtered by searchbar.
  let recReplacement = {
    label: 'Recommended Replacement',
    options: []
  }
  let inClassGroup = {
    label: 'Candidates In Class',
    options: []
  }
  let notInClassGroup = {
    label: 'Candidates Not In Class',
    options: []
  }

  recReplacement.options.push({label: props.recommendation, value: -1})

  inClassCands.sort((a,b) => {
    return a.overall < b.overall ? 1 : -1;
  }).forEach((c) => {
    inClassGroup.options.push({label: c.ymm, value: c.idx});
  })
  notInClassCands.sort((a,b) => {
    return a.overall < b.overall ? 1 : -1;
  }).forEach((c) => {
    notInClassGroup.options.push({label: c.ymm, value: c.idx});
  })
  return [recReplacement, inClassGroup, notInClassGroup]
}

const formatCandidateValue = (props) => {
  if (props.recommendation === 'No Candidates in Class' && props.value === -1) {
    return {label: props.recommendation, value: props.value}
  }
  else if (props.recommendation !== 'No Candidates in Class' && props.value === -1) {
    return {label: props.recommendation, value: props.value}
  }
  else {
    return {label: props.options[props.value].ymm, value: props.value}
  }
}

export function CandDropdownSelector(props) {
  // TEMPORARY logic to deal w/ rec that doesn't have equiv candidate - will refactor this
  //Save the original index for lookup within cands array
  const options = props.options.map((item, idx) => ({ ...item, idx })).sort((a, b) => (a.ymm < b.ymm ? -1 : 1))
  let formattedOptions = null;
  if (props.noCandidates) {
    formattedOptions = [{label: 'No Candidates', value: 'No Candidates', isDisabled: true}]
  }
  else {
    formattedOptions = formatCandidateOptions(options, props)
  }
  const selectedCand = formatCandidateValue(props)

  return (
    <SearchSelect
        onChange={props.handleSelectorChange}
        options={formattedOptions}
        value={selectedCand}
        width={'100%'}
        optionHeight={'40px'}
        menuShouldScrollIntoView={false}
    />
  );
}
