import styled from "styled-components/macro"

export const DateSelectorContainer = styled.div`
    width: max-content;
    margin-right:15px;
`

export const HeaderDateSelectorInfoBubble = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`

export const InfoIcon = styled.img`
  height: 13px;
  width: 13px;
  padding-bottom: 4px;
`

export const InfoTextContainer = styled.div`
  padding: 8px;
  margin-left: 10px;
  border: 1px solid #979a9e;
  box-shadow: 2px 2px 4px #47484a;
  border-radius: 5px;
  width: 300px;
  white-space: initial;
  z-index: 1;
  background-color: white;
  font-size: 13px;
  color: #47484a;
  position: absolute;
  bottom: 20px;
  right: 15px;
`
export const InfoText = styled.p`
  margin: 0;
`