import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import AdminTableView from "./AdminTableView";
import Loading from "../swt-loading";
import Search from "./search";
import { processApiResponse } from "../../utility-components/ConformUnits";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import { roundObjValues } from "../UtilityFunctions";

const SERVER_ERRORED_MESSAGE = "There was a server error during your request."

export default function SuperAdminVehicleClassesTable(props){

    const { apiURL, user } = props;
    const [vehicleClasses, setVehicleClasses] = useState(null);
    const [matchingClasses, setMatchingClasses] = useState(null);

    const skipPageResetRef = useRef(false)

    const inputSort = useMemo(() => (rowA, rowB, columnId) => {
        const a = rowA.values[columnId].props.label;
        const b = rowB.values[columnId].props.label;
        return a > b ? 1 : -1;
    },[]);

    const boolSort = useMemo(() => (rowA, rowB, columnId) => {
        const a = rowA.values[columnId].props.bool;
        const b = rowB.values[columnId].props.bool;
        return a > b ? 1 : -1;
    },[]);

    const columns = [
        {Header: "Class", accessor: "vehicle_class", sortType:inputSort, width: 200},
        {Header: "Light Duty", accessor: "is_ld", sortType:boolSort, width: 150, prevColWidth: '200px'},
        {Header: `EV Curb ${user.userSettings.weight_labels.shortPlural}`, accessor: "ev_curb_lbs", sortType:"basic", width: 150},
        {Header: `EV Loaded ${user.userSettings.weight_labels.shortPlural}`, accessor: "ev_loaded_lbs", sortType:"basic", width: 150},
        {Header: `ICE Curb ${user.userSettings.weight_labels.shortPlural}`, accessor: "ice_curb_lbs", sortType:"basic", width: 150},
        {Header: `ICE Loaded ${user.userSettings.weight_labels.shortPlural}`, accessor: "ice_loaded_lbs", sortType:"basic", width: 150},
        {Header: `${user.userSettings.liquid_fuel_economy} (City)`, accessor: "mpg_c", sortType:"basic", width: 120},
        {Header: `${user.userSettings.liquid_fuel_economy} (Hwy)`, accessor: "mpg_h", sortType:"basic", width: 120},
        {Header: "Default Rate", accessor: "default_rate", sortType:"basic", width: 150},
        {Header: "Level 2 Draw", accessor: "level_2_rate", sortType:"basic", width: 130},
        {Header: "Level 3 Draw", accessor: "level_3_rate", sortType:"basic", width: 130},
        {Header: "Battery Capacity (kWh)", accessor: "battery_kwh", sortType:"basic", width: 140},
        {Header: "Battery Usable (kWh)", accessor: "battery_usable_kwh", sortType:"basic", width: 130},
        {Header: "Cylinders", accessor: "cyl", sortType:"basic", width: 150},
        {Header: "Displacement (Liters)", accessor: "disp", sortType:"basic", width: 150},
        //Dollar sign is hard coded in headers because values are only displayed as USD and do not respect user/db settings currency at this time. - JJ 
        {Header: `BEV Purchase Price ($)`, accessor: "bev_purchase_price", sortType:"basic", width: 150},
        {Header: `PHEV Purchase Price ($)`, accessor: "phev_purchase_price", sortType:"basic", width: 150},
        {Header: `ICE Purchase Price ($)`, accessor: "ice_purchase_price", sortType:"basic", width: 150}
    ];

    const BoolTableCell = useCallback((props) =>
    <input
        className="swt-admin-table-input"
        type="checkbox"
        id={props.id} 
        name={props.name}
        accessor={props.accessor} 
        style={{color: props.bool ? "black" : "transparent"}} 
        onChange={props.handleOnClick}
        checked={(props.bool === null || typeof props.bool === "undefined") ? false : props.bool}
        disabled={props.disabled ? true : false}
    />,[]);

    useEffect(() => {
        const url = `${apiURL}getVehicleClasses`;
        fetch(`${url}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
            .then((resp) => {
                return resp.json();
            })
            .then((data) => {
                if (data.status === "success") {
                    data.data.map((v) => {
                        v = processApiResponse(user.userSettings,v, false)
                        v = roundObjValues(v)
                        return v
                    })
                    setVehicleClasses(data.data);
                }
                else {
                    alert(SERVER_ERRORED_MESSAGE);
                }
            })
            .catch((error) => console.error("Error: " + error));
    }, [apiURL, user.token, user.userSettings]);

    
    const mappedVehicleClasses = useMemo(() => {
        if(!vehicleClasses || !matchingClasses)return null;
        if(vehicleClasses.length < 1)return [];

        const classes = JSON.parse(JSON.stringify(vehicleClasses));
        const matchingVehicleClasses = matchingClasses.map((c) => { return c.vehicle_class})


        return classes.filter((c) => {
            if(matchingVehicleClasses.indexOf(c.vehicle_class) < 0) return null;

            c.is_ld = <BoolTableCell id={c.vehilce_class} accessor={"is_ld"} bool={c.is_ld} disabled/>;
            c.vehicle_class = <div style={{whiteSpace: "nowrap"}} label={c.vehicle_class} title={c.vehicle_class}>{c.vehicle_class}</div>
            return c;
        })
    }, [vehicleClasses, matchingClasses]);

    return(
        <div>
            <S.TableHeaderContainer>
                <S.AdminTableExplainTextPrimary>List of all vehicle classes.</S.AdminTableExplainTextPrimary>
                <S.AdminTableExplainTextSub>Click a column name to sort the table.</S.AdminTableExplainTextSub> 
                <S.SearchContainer>
                    <Search allValues={vehicleClasses} setMatchingValues={setMatchingClasses} skipPageResetRef={skipPageResetRef}/>
                </S.SearchContainer>
            </S.TableHeaderContainer>
            {!mappedVehicleClasses && <Loading />}
            {mappedVehicleClasses && 
            <AdminTableView 
                columns={columns} 
                data={mappedVehicleClasses}
                skipPageResetRef={skipPageResetRef}
                stickyCols={1}
                noDataMessage={'No vehicle classes to display.'}
            />
            }
        </div>
    )
}