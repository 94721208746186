import React from "react";

export const vehiclesTableColumns = [
    { Header: "Asset ID", simpleHeader: "Asset ID", id: "assetId", accessor: (row: any) => row.asset_id, sortType: "basic", Filter: IDColumnFilter, width: 181, showToolTip: (Boolean, object) => {} },
    { Header: "Year", simpleHeader: "Year", accessor: (row: any) => row.year, sortType: "basic", Filter: <div />, width: 70 },
    { Header: "Make", simpleHeader: "Make", accessor: (row: any) => row.make, sortType: "basic", Filter: <div />, width: 140 },
    { Header: "Model", simpleHeader: "Model", accessor: (row: any) => row.model, sortType: "basic", Filter: <div />, width: 110 },
    { Header: <div>Charging hours<br />(avg per day)</div>, simpleHeader: "Charging hours (avg per day)", id: "chargingHours", accessor: (row: any) => row.averageChargeHours > 0 ? row.averageChargeHours.toFixed(1) : "Not Charging", Filter: <div /> },
    { Header: <div>Charger <br /> Level</div>, simpleHeader: "Charger Level", id: "chargerLevel", accessor: (row: any) => formatChargerLevel(row.default_kw_draw), sortType: "basic", Filter: <div />, width: 80 },
    { Header: "Charger kW Draw", simpleHeader: "Charger kW Draw", accessor: (row: any) => row.default_kw_draw, sortType: "basic", Filter: <div />, width: 100 },
    { Header: "Vehicle Class", simpleHeader: "Vehicle Class", accessor: (row: any) => row.vehicle_class, sortType: "basic", Filter: <div />, width: 90 },
    { Header: "EV Recommendation", simpleHeader: "EV Recommendation", accessor: (row: any) => row.is_ev_recommendation ? "True" : "False", sortType: "basic", Filter: <div /> },
    { Header: "Primary Parking Location", simpleHeader: "Primary Parking Location", accessor: (row: any) => formatHomebaseStatus(row), sortType: "basic", Filter: <div />, showToolTip: (Boolean, object) => {} },
    { Header: "Charging at this location (kWh)", simpleHeader: "Charging at this location (kWh)", accessor: (row: any) => Math.round(row.period_at_location_kwh), sortType: "basic", Filter: <div /> },
    { Header: "Charging not at this location (kWh)", simpleHeader: "Charging not at this location (kWh)", accessor: (row: any) => Math.round(row.period_not_at_location_kwh), sortType: "basic", Filter: <div /> }
]

function IDColumnFilter({ column: { filterValue, setFilter } }: any) {
    return (
        <input
            className="swt-ezio-search-input"
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search`}
        />
    );
}

function formatHomebaseStatus(data: any) {
    if(data.is_past_homebase) {
        return 'Past'
    } else if (data.is_homebase_location) {
        return 'Current'
    } else {
        return 'No'
    }
}

function formatChargerLevel(draw: number) {
    if(draw>20){
        return ('DC Fast')
    } else{
        return ('Level 2')
    }
}

interface Vcl{
    asset_id: string | null,
    user_defined_vin: string | null,
    vin: string | null
}

export function vehicleIdDisplay (vcl: Vcl ) {
    if(vcl) {
    if (vcl.asset_id && vcl.asset_id !== 'null' && vcl.asset_id.toLowerCase() !== 'none') return vcl.asset_id
    if (vcl.user_defined_vin && vcl.user_defined_vin !== 'null') return vcl.user_defined_vin
    if (vcl.vin) return vcl.vin
    }
  }