import React, { useMemo } from 'react';

export default function VehicleClassDropdown(props) {

  const _handleChange = (e) => {
    props.handleClassChange(e.target.value, props.id);
  }

  const options = useMemo(() => (
    [props.vehicleClasses.map(o => <option key={o} id={o}>{o}</option>),
    (props.vehicleClasses.includes(props.class) ? [] : [<option key="none" value="none" disabled hidden>No Class Selected</option>])]
  ), [props.vehicleClasses, props.class]);

  const defaultValue = useMemo(() => props.vehicleClasses.includes(props.class) ? props.class : "none",
    [props.class, props.vehicleClasses]);
    return (<select id={props.id} 
                  className="swt-class-selector"
                  style={props.styles}
                  defaultValue={defaultValue}
                  onChange={_handleChange}>
    {options} </select>);
}
