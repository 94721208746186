import React from 'react';

function NoMatch() {
  return (
    <div className="swt-ionev-no-match">
      Oops, page not found.
    </div>
  );
}

export default NoMatch;
