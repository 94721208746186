import styled from "styled-components/macro"
import { Link } from "react-router-dom";
import logo from "../../images/ezio-logo-184px-176px.png"
import * as colors from "./ColorScheme";

const MEDIUM_VIEW_BREAKPOINT = "1100px";
const SMALL_VIEW_BREAKPOINT = "880px";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 38px;
  margin-left: 50px;
  margin-right: 50px;
  max-width: 1400px;
  align-items: flex-end;
  @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
    flex-direction: column;
    min-width: auto;
    margin-left: 15px;
    align-items: flex-start;
  }
  @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
    min-width: auto;
  }
`;

export const ImageContainer = styled.div`
  margin-right: 30px; 
  height: 92px;
  width: 92px;
  display: inline-block;
  background-image: url(${logo});
  background-size: cover;
  background-repeat: no-repeat;
  @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
    margin-right: 0px;
  }
  @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {

  }
`;

export const HeaderDateSelectorsContainer = styled.div`
  margin-left: 20px;
  white-space: nowrap;
  @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
    min-width: auto;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: -20px;
  }
  @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {

  }
`;

export const LHSContentWrapper = styled.div`

`;

export const HeaderAndFleetWrapper = styled.div`
  display: inline-block;
`;

export const FleetNameContainer = styled.span`
font-family: "Arimo", "Roboto";
font-size: 46px;
font-style: normal;
font-weight: 400;
line-height: 54px;
letter-spacing: 0em;
text-align: left;
color: ${colors.ezio_dark_grey};
`

export const StatusContainer = styled.div`
font-family: "Arimo", "Roboto";
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: 42px;
letter-spacing: 0em;
text-align: left;
color: ${colors.ezio_medium_grey};
`

export const NavLinkList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  color: ${colors.ezio_dark_grey};
  margin-top: 1em;
`;

export const OptionLink = styled(Link)`
display: inline-block;
text-decoration: none;
color: ${colors.ezio_dark_grey};
padding: 8px 15px 8px 15px;
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;


&:hover   {
  background-color: rgba(3, 94, 91, 0.2);
}

`

const boldOnHover = `
  &:hover   {
    text-shadow:0px 0px 0.1px black;
    cursor: pointer;
   text-decoration-skip-ink: all;
  }
`

export const NavLinkListItem = styled.div`
font-family: "Arimo", "Roboto";
margin-right: 1em;
white-space: nowrap;
`;

export const NavLink = styled(Link)<{ selected?: boolean }>`
  font-family: "Arimo", "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: inherit;
  padding-bottom: 2px;
  ${(props) => props.selected && `
      border-bottom: 1.5px solid;
    `}
  ${boldOnHover}
`

export const LogoLink = styled(Link)`
display: block;
height: 100%;
width: 100%;
`

export const DropdownMenu = styled.span<{ selected?: boolean }>`
  font-family: "Arimo", "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: inherit;
  white-space: nowrap;
  padding-bottom: 2px;
  ${(props) => props.selected && `
    border-bottom: 1.5px solid;
  `}
  ${boldOnHover}
`

const DropdownOptionsCommon = `
position: absolute;
display: flex;
flex-direction: column;
background: #FFFFFF;
border: 2px solid ${colors.ezio_light_grey};

box-sizing: border-box;
z-index: 1500;
`
export const AdminDropdownOptions = styled.div`
${DropdownOptionsCommon}
min-width: 10rem;
`

export const LocationsDropdownOptions = styled.div`
${DropdownOptionsCommon}
min-width: 150px;
`

export const DownArrowWrapper = styled.div`
  display: inline;
  svg {
    margin-left: 3px;
    position: relative;
    top: -1px;
  }
`

export const AdditionalInfoContainer = styled.div`
  margin-top: 32px;
`

export const AdditionalInfoCopy = styled.div`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 30px;
color: #74787D;
padding-top: 10px;
padding-bottom: 10px;
text-decoration: none;
`

export const AdditionalInfoEmailLink = styled.a`
font-family: "Arimo", "Roboto";
text-decoration: none;
color: #035E5B;
&:hover {
    color: #035E5B;
    text-decoration: underline;
}
`