
class GeotabIntegration{
    constructor(){
      this.available = false;
      this.ident = null;
      this.session = null;
      this.userName = null;
      this.api = {};
      if(typeof geotab !== "undefined"){
        this.available = true;
        this.register();
      }
    }
    register = () =>{
      if(!this.available)return;
      // eslint-disable-next-line no-undef
      geotab.addin.swt = (api) =>{
        return{
          focus: () => {this.getSession(api)},
          blur: () => {
            var evt = new Event('geotabSessionBlur');
            document.dispatchEvent(evt);
          }
        }
      }
    }
    getSession = (api) =>{
      this.setApi(api);
      this.api.getSession((c, s) => {this.setSession(c, s)});
    }
    setApi = (api) => {
      this.api = api;
    }
    setSession = (c, s) => {
      this.ident = c.database.toLowerCase();
      this.userName = c.userName;
      this.initComplete();
    }
    initComplete = () => {
      var evt = new Event('geotabSessionInit');
      document.dispatchEvent(evt);
    }
  }
  
  export {GeotabIntegration}