import React, { useState } from "react";

import passwordEye from "../../../../images/ic-password-eye.png";
export default function UpdatePasswordModal(props) {
  const [password, setPassword] = useState("");
  // This seems like it could be consolidated??
  const [conform, setConform] = useState(false);
  const [conformField, setConformField] = useState(null);
  const [conformMsg, setConformMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordColor, setPasswordColor] = useState({ color: "black" });

  function handlePassw0Change(e) {
    // This could use a refactor
    const { value } = e.target;
    if (value !== null && value.length < props.passwordRules.minLength - 1) {
      setPassword(value);
      setPasswordColor({ color: "red" });
      setConformMsg(
        `Minimum password length: ${props.passwordRules.minLength} characters`
      );
      return;
    } else {
      setPassword(value);
      setConformMsg("");
      setPasswordColor({ color: "black" });
    }
    //check for conformance
    if (value === conformField) {
      setConform(true);
    } else {
      setConform(false);
    }
  }

  function handlePassw1Change(e) {
    const { value } = e.target;
    const field = password;
    //check password rules pass upon conform attempts
    if (password.length < props.passwordRules.minLength) {
      setPasswordColor({ color: "red" });
      setConformMsg(
        `Minimum password length: ${props.passwordRules.minLength} characters`
      );
      return;
    } else {
      setConformMsg("");
      setPasswordColor({ color: "black" });
    }

    //check for conformance
    if (field === value) {
      setConform(true);
      setConformField(value);
    } else {
      setConform(false);
      setConformMsg("Passwords do not match");
      setConformField(value);
    }
  }
  function passwordToggle(e) {
    e.preventDefault();
    setShowPassword(!showPassword);
  }

  function handleSubmit(e) {
    const field = password;
    e.preventDefault();
    if (field !== "" && conform !== false) {
      props.updatePassword(props.user, password);
      props.handleClose();
    } else window.alert("Unable to update Password - Password's do not match");
  }

  if (props.show !== true) return null;
  return (
    <div className="swt-admin-modal swt-admin-display">
      <section className="swt-admin-modal-password">
        <button
          className="swt-admin-modal-close-btn"
          onClick={props.handleClose}
        >
          X
        </button>
        <h3 id="swt-password-form-title">
          Update <u>{props.user}'s</u> Password
        </h3>
        <p className="swt-conform-message">{conformMsg}</p>
        <form onSubmit={handleSubmit}>
          <label id="swt-password-form-label"> New Password</label>
          <br />
          <input
            className="swt-credential-input"
            type={showPassword === false ? "password" : "text"}
            onChange={handlePassw0Change}
            style={passwordColor}
          ></input>
          <br />
          <label id="swt-password-form-label"> Confirm New Password</label>
          <br />
          <input
            className="swt-credential-input"
            type={showPassword === false ? "password" : "text"}
            onChange={handlePassw1Change}
            style={passwordColor}
          ></input>
          <button
            onClick={passwordToggle}
            className="swt-password-render-button"
          >
            <img
              id="swt-password-render-icon"
              src={passwordEye}
              alt="show Password Toggle"
            />
          </button>
          <br />
          <button type="submit" className="swt-submit-button">
            Submit
          </button>
        </form>
      </section>
    </div>
  );
}
