import React, {useState, useEffect, useMemo, useCallback, Suspense} from "react";
import * as S from '../../../styles/core-styles/AdminTools-styles';
import AdminTableView from "./admin-components/AdminTableView";
import UpdateLocationModal from "./UpdateLocationModal";
import Loading from "./swt-loading";

const LocationEditMap = React.lazy(() => import('./LocationEditMap'))

export default function LocationEditTool(props) {
    const {user, apiURL, db} = props;

    const [showUpdateLocationModal, setShowUpdateLocationModal] = useState(false);
    const [locationId, setLocationId] = useState('');
    const [selectedLocation, setSelectedLocation] = useState();
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        getParkingLocations();
    //eslint-disable-next-line
    },[apiURL, db, user])

    function updateLocationData (data) {
        let locationData = {parkingLocation: data, dbName: db};
        let url = `${apiURL}updateParkingLocation`;
        fetch(url, {
            method: 'post',
            body: JSON.stringify(locationData),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        }).then((resp) => {
            if(resp.status === 200) {
                window.alert('Location updated.');
                getParkingLocations();
            } else {
                window.alert('There was an issue with updating the location.')
            }
        })
    }

    function getParkingLocations(){
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(`${apiURL}getAllUngroupedParkingLocations?dbName=${db}`, {signal, headers: { Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
            setLocations(data.data)
        })
    }

    // useEffect(() => {
    //     const controller = new AbortController();
    //     const signal = controller.signal;
    //     fetch(`${apiURL}getAllGroupedParkingLocations?dbName=${db}`, {signal, headers: { Authorization: `Bearer ${user.token}`}})
    //         .then((resp) => resp.json())
    //         .then((data) => {
    //             console.log('Grouped Data', data)
    //         // setUngroupedLocations(data.data)
    //     }
    //     )
    //     //eslint-disable-next-line
    // },[apiURL, db, user])

    function SearchColumnFilter({ column: { filterValue, setFilter } }) {
        return (
          <S.AdminTableColumnSearchBar
            value={filterValue || ""}
            onChange={(e) => {
              setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search by Location Id`}
          />
        );
      }

    const BoolTableCell = useCallback((props) => 
      <input
        className='swt-admin-table-input'
        type="checkbox"
        accessor={props.accessor} 
        style={{color: props.bool ? "black" : "transparent"}} 
        checked={(props.bool === null || typeof props.bool === "undefined") ? false : props.bool} 
        disabled={props.disabled ? true : false}
        email={props.email}
        onChange={props.handleOnClick}
      />,[]);


    const mappedLocationData = useMemo(() => {
        if(!locations) return null;
        const immutableLocations = JSON.parse(JSON.stringify(locations));
        return immutableLocations.map((l) => {
            //Selected will be used when grouping functionality added. For now leaving disabled.
            l.selected = <BoolTableCell disabled={true} accessor={"selected"} bool={l.selected} handleOnClick={(e) => {console.log(e)}}/>;
            l.coords = `${l.latitude}, ${l.longitude}`;
            return l
        })
    },[locations])

    const tableColumns = [
        {Header: 'Selected', accessor: 'selected', width: 50},
        {Header: 'Location ID', accessor: 'pkid', Filter: SearchColumnFilter, width: 100},
        {Header: 'Address', accessor: 'address', width: 200},
        {Header: 'Lat/Lon', accessor: 'coords', width: 150}
    ]
    return(
        <S.AdminContainer>
            {showUpdateLocationModal &&
            <UpdateLocationModal
                setShowUpdateLocationModal={setShowUpdateLocationModal}
                apiURL={apiURL}
                db={db}
                locationId={locationId}
                user={user}
                selectedLocation={selectedLocation}
                updateLocationData={updateLocationData}
            />
            }
            <h2 className="swt-admin-console-ttl">Location Edit Tool</h2>
            {locations.length === 0 &&
                <Loading />
            }
            {locations.length > 0 &&
            <Suspense fallback={<div></div>}>
            <S.LocationEditContainer>
            <LocationEditMap
                {...props}
                setShowUpdateLocationModal={setShowUpdateLocationModal}
                showUpdateLocationModal={showUpdateLocationModal}
                setLocationId={setLocationId}
                setSelectedLocation={setSelectedLocation}
                locations={locations}
            />
            <div style={{marginTop: '20px'}}>
                <AdminTableView 
                    columns={tableColumns} 
                    data={mappedLocationData} 
                    skipPageResetRef={true}
                />
            </div>
        </S.LocationEditContainer>
        </Suspense>
        }
        </S.AdminContainer>
    )
}


