import { Link } from "react-router-dom"
import styled from "styled-components/macro"
import * as colors from "./ColorScheme";

const MEDIUM_VIEW_BREAKPOINT = "1250px";
const SIX_STAT_VIEW_BREAKPOINT = "1500px";
const SMALL_VIEW_BREAKPOINT = "880px";

// DropdownMenu
// OptionLink

type ToolTipWrapperProps = {
    cursorCoords: {x: number, y: number};
}


export const PageLayout = styled.main`
    position: relative;
    @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
        display: block;
    }
`;

export const PageHeader = styled.h1`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 35px;
color: ${colors.ezio_dark_grey};
margin-top: 0rem;
display: inline-block;
cursor: pointer;`

export const ContentWrapper = styled.div`
    width: fit-content;
    margin: 0 auto;

    @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}){
        width: auto;
    }
`

export const DetailsControls = styled.div`
padding-top: 10px;
padding-bottom: 10px;
display:flex;
@media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
    column-gap: 15px;
    flex-wrap: wrap;
    min-width: 700px;
}
`

export const ControlsLabel = styled.div`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 400;
font-size: 18px;
color: ${colors.ezio_dark_grey};
margin-left: 1rem;
margin-right: 15px;
white-space: nowrap;

@media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
    margin-left: 5px;
    margin-bottom: 2px;
}
`

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
        flex-direction: column;
        align-items: flex-start;
    }
`

export const LocationSummaryNavigation = styled(Link)`
border: 2px solid ${colors.ezio_light_grey};
border-radius: 5px;
display: inline-flex;
width: max-content;
white-space: nowrap;
align-items: center;
justify-content: center;
height: 34px;
text-decoration: none;
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 400;
font-size: 18px;
color: ${colors.ezio_dark_grey};
padding: 0 10px;
&:hover{
    box-shadow: 2px 2px 4px #F5F5F5;
}

@media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
    margin-top: 23px;
}
`

export const StatBoxRow = styled.div<{statCount: number}>`
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    width: 80%;
    height: 100%;
    @media(max-width: ${props => (props.statCount === 6) ? SIX_STAT_VIEW_BREAKPOINT : MEDIUM_VIEW_BREAKPOINT}) {
        flex-wrap: wrap;
        justify-content: center;
        width: fit-content;
        max-width: 900px;
    }
    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        min-width: 500px;
    }
`;

export const StatBoxContainer = styled.div`
padding: 1rem;
`

export const GraphTableWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 4%;

    @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
        flex-direction: column;
        align-items: center;
    }
`

export const GraphWrapper = styled.div`
    display:flex;
    flex-direction: column;
    height: 375px;
    min-width: 500px;
    max-width: 600px;
    @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
        width: 500px;
    }
`

export const GraphSubtitle = styled.span`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: ${colors.ezio_medium_grey};
`

export const NoDataMessage = styled.div`
font-family: "Arimo", "Roboto";
display: flex;
align-items: center;
height: 3rem;
`

export const TableWrapper = styled.div`
    width: 600px;

@media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
    width: 100%;
    max-width: 900px;
    margin-top: 50px;
}
`
export const ParkingToolTipWrapper = styled.div<ToolTipWrapperProps>`
    padding: 8px;
    border: 1px solid #979a9e;
    box-shadow: 2px 2px 4px #47484a;
    border-radius: 5px;
    width: 300px;
    z-index: 1;
    background-color: white;
    font-size: 14px;
    display: inline-block;
    color: #47484a;
    position: absolute;
    ${({ cursorCoords }:  ToolTipWrapperProps) => {
        return `left: ${cursorCoords.x-200}px;top: ${cursorCoords.y + 25}px`
    }}
`;

export const ToolTipWrapper = styled.div<ToolTipWrapperProps>`
    padding: 8px;
    border: 1px solid #979a9e;
    box-shadow: 2px 2px 4px #47484a;
    border-radius: 5px;
    width: 300px;
    z-index: 1;
    background-color: white;
    font-size: 14px;
    display: inline-block;
    color: #47484a;
    position: absolute;
    ${({ cursorCoords }:  ToolTipWrapperProps) => {
        return `left: ${cursorCoords.x+25}px;top: ${cursorCoords.y-100}px`
    }}
`;

export const ToolTipText = styled.p`
    margin: 0;
`

export const TableContainer = styled.div`

    @media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
        margin: 0 auto;
    }

    @media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
        width: auto;
    }
`;

export const DateSelectorContainer = styled.div`
    white-space: nowrap;
`;

export const TableTitle = styled.h1`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 35px;
color: ${colors.ezio_dark_grey};
`

export const TableSubtitle = styled.span`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: ${colors.ezio_medium_grey};
`

// Dropdown arrow
export const DownArrowWrapper = styled.div`
padding-left: 10px;
padding-bottom: 5px;
display: inline-block;
`

// CSV download button
const centeredLinkInButton = `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration-line: none;
`;
const buttonStyles = `
width: 146px;
height: 43px;
background: #FFFFFF;
border: 2px solid ${colors.ezio_dark_grey};
font-family: "Arimo", "Roboto";
font-style: normal;
cursor: pointer;
font-weight: 500;
font-size: 16px;
align-items: center;
justify-content: center;
display:flex;
transition: 0.1s filter linear;
&:hover {
    filter: drop-shadow(4px 2px 10px rgba(74, 74, 74, 0.18));
}
a{
    ${centeredLinkInButton}
}
`

export const CSVDownloadButton = styled.div`
${buttonStyles}
margin-top: 25px;
`

export const DropdownMenu = styled.div`
display: block;
position: static;
&:hover   {
    text-shadow:0px 0px 0.1px black;}
`

export const DropdownOptions = styled.div`
position: absolute;
display: flex;
flex-direction: column;
background: #FFFFFF;
border: 2px solid ${colors.ezio_light_grey};
padding: 5px 8px 5px 0;
box-sizing: border-box;
z-index: 1500;
&:hover{
    background-color: #cddfde;
    // background-color: rgba(3, 94, 91, 0.2);
      
}
`

export const OptionLink = styled(Link)`
display: inline-block;
text-decoration: none;
color: ${colors.ezio_dark_grey};
padding: 8px 15px 8px 0px;
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 19px;`
