import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import AdminTableView from "./AdminTableView";
import Search from "./search";
import Loading from "../swt-loading";
import UpfitsCreationModal from "./UpfitsCreationModal";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import { conformPostRequest, processApiResponse } from "../../utility-components/ConformUnits";
import { preventNonIntegers, preventPasteNonIntegers, roundObjValues, formatDecimal } from "../UtilityFunctions";
import { _inputSort } from "./TableHelpers";
import { Roles } from "../../utility-components/UserUtils";
const SERVER_ERRORED_MESSAGE = "There was a server error during your request."

export default function UpfitsManagementTable(props) {

  const [upfits, setUpfits] = useState([]);
  const [reload, setReload] = useState(true);
  const [matchingUpfits, setMatchingUpfits] = React.useState(null);
  const [showModal, setShowModal] = useState(false);
  const dbSettings = props.dbSettings;

  const skipPageResetRef = useRef(false)

  const { apiURL, db, dbDisplayName, user } = props;

  const tableColumns = [
      {Header: "Upfit Name", accessor: "name", width: 500, sortType: _inputSort},
      {Header: `Price (${dbSettings.currency_symbol})`, accessor: "price", sortType: _inputSort},
      {Header: `Weight\n(${user.userSettings.weight_labels.shortPlural})`, accessor: "weight", sortType: _inputSort},
  ];

  useEffect(() => {
    const url = `${apiURL}getUpfits?dbName=${db}`;
    fetch(`${url}`, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
        .then((resp) => {
            return resp.json();
        })
        .then((data) => {
            if (data.status === "success") {
              data.data.forEach((u) => {
                u = processApiResponse(user.userSettings, u, false)
                u = roundObjValues(u)
              })
              setUpfits(data.data);
              setReload(false);
            }
            else {
              alert(SERVER_ERRORED_MESSAGE);
            }
        })
        .catch((error) => console.error("Error: " + error));
}, [apiURL, db, user.token, reload, user.userSettings]);

  const InputTableCell = useCallback((props) => 
  <input className="swt-admin-input"
         style={props.styles}
         onKeyDown={props.onKeyDown}
         onPaste={props.onPaste}
         title={props.label}
         key={props.id}
         value={`${props.label}`}
         id={props.id}
         accessor={props.accessor}
         onChange={props.handleOnChange}
         onWheel={(e) => e.target.blur()}
         type={props.type}
  />,[]);

  function addUpfit(upfit) {
    let upft = conformPostRequest(user.userSettings, [upfit])
    upft.forEach((u) => {
      return roundObjValues(u)
    })
    fetch(`${apiURL}addUpfit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        dbName: db,
        upfit: JSON.stringify(upft[0]),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "success") {
          window.alert("Upfit was succesfully added");
          setReload(true);
        }else
          window.alert(SERVER_ERRORED_MESSAGE);
      });
  }

  function updateUpfits() {
    // NOTE don't conform post request here
    const upfts = upfits.filter((u) => u.mutated);
    if(upfts.length < 1){
      window.alert("There are no upfits subject to update.");
      return;
    }
    fetch(`${props.apiURL}updateUpfitSpecs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        dbName: db,
        upfits: JSON.stringify(upfts),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "success") {
          window.alert("Upfits were succesfully updated");
          setReload(true);
        } else
          window.alert(SERVER_ERRORED_MESSAGE);
      });
  }

  function _getTableIndex(id) {
    const idxArr = upfits.map((c) => {
      return c.id;
    });
    return idxArr.indexOf(id);
  }

  const handleSave = () => {
    updateUpfits();
  };

  function handleInputChange(el) {
    skipPageResetRef.current = false
    const upfts = [...upfits];
    let val = el.target.value;
    val = val.replace(/['"]/g, "");
    const id = el.target.getAttribute("id");
    let col = el.target.getAttribute("accessor");
    let row = _getTableIndex(id);
    let u = upfts[row];
    if(el.target.getAttribute('type') === 'number') {
      val = formatDecimal(val, 4)
    }
    u[col] = val;
    u["mutated"] = true;
    setUpfits(upfts);
  }

  // function sanitizeData (data) {
  //   if(data) {
  //     data.net_price = !data.net_price ? data.msrp : data.net_price;
  //     data.mpg_c = !data.mpg_c ? null : data.mpg_c;
  //     data.mpg_h = !data.mpg_h ? null : data.mpg_h;
  //     data.battery_kwh_usable = !data.battery_kwh_usable ? data.battery_kwh : data.battery_kwh_usable;
  //     data.battery_kwh = !data.battery_kwh ? null : data.battery_kwh;
  //   }
  //   return data
  // }

    const handleUpfitsUpdate = () => {
      const answer = window.confirm("(Non-destructive)Update this database with new upfits?")
      if(answer){
        const data = {dbName: db}
        fetch(`${apiURL}bringInCDRUpfits`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify(data),
        })
        .then(resp => resp.json())
        .then(data => {
          if(data.status !== 'success') {
            window.alert(SERVER_ERRORED_MESSAGE)
          } else {
          window.alert('Upfits were brought in successfully.')
          setUpfits([]);
          setReload(true);
        }
        });
      }
    }

    const mappedUpfits = useMemo(()=> {
      if(!upfits || !matchingUpfits)return null;
      if(upfits.length < 1)return [];

      const upfts = JSON.parse(JSON.stringify(upfits));
      const matchingUpfts = matchingUpfits.map((c) => {return c.id});
      return upfts.filter((u) => {
          if(matchingUpfts.indexOf(u.id) < 0)return null;

              //inputs
              u.name = <InputTableCell label={u.name ? u.name : ""} id={u.id} accessor={"name"} handleOnChange={function(el){handleInputChange(el)}} type="text"/>
              u.price = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} label={u.price !== null ? u.price : ""} id={u.id} accessor={"price"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
              u.weight = <InputTableCell onKeyDown={function(el){preventNonIntegers(el)}} onPaste={function(el){preventPasteNonIntegers(el)}} label={u.weight !== null ? u.weight : ""} id={u.id} accessor={"weight"} handleOnChange={function(el){handleInputChange(el)}} type="number"/>
          return u;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upfits, matchingUpfits]);

    return (
        <>
          <S.TableHeaderContainer>
            <S.AdminTableExplainTextPrimary>List of vehicle upfits in {dbDisplayName}.</S.AdminTableExplainTextPrimary>
            {/* <S.AdminTableExplainTextSub>Assign vehicle access by selecting groups.</S.AdminTableExplainTextSub> */}
            <S.SearchContainer>
              <Search allValues={upfits} setMatchingValues={setMatchingUpfits} skipPageResetRef={skipPageResetRef}/>
            </S.SearchContainer>
            {showModal && (
                      <UpfitsCreationModal
                        dbSettings={dbSettings}
                        userSettings={user.userSettings}
                        setShowModal={setShowModal}
                        // sanitizeData={sanitizeData}
                        addUpfit={addUpfit}
                      />
            )}
            <S.CtaButtonWrapper>
              <S.CtaButton onClick={() => setShowModal(true)} id="add">Add</S.CtaButton>
              <S.CtaButton type="submit" onClick={handleSave}>
                Save
              </S.CtaButton>          
              {user.role >= Roles.SuperAdmin && // This is redundant for now since this component is not rendered for non-super admins, but keeping for when we expose in future
              <S.CtaButton onClick={() => handleUpfitsUpdate()}>
                Bring in Upfits
              </S.CtaButton>
              }
          </S.CtaButtonWrapper>
          </S.TableHeaderContainer>

          {(!mappedUpfits)&& <Loading />}
                {(mappedUpfits) && 
                <AdminTableView
                    stickyCols={0}
                    columns={tableColumns}
                    sortBy={{id: 'name', desc: true}}
                    data={reload ? [] : mappedUpfits}
                    skipPageResetRef={skipPageResetRef}
                    noDataMessage={'No candidates to display. Click "Add New Candidate" to create one.'}
                />}
        </>
    )
}

