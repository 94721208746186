import React, { useState, useEffect, useReducer, useMemo, Suspense } from "react"
import { ChargeLocation } from "../swt-ezio";
import * as S from "../../styles/ezio-styles/LocationSummary-styles"
//import StatBox from "./StatBox"
import Table from "./Table"
import { columnsToHeaders, rowsToData } from "../../utils/ezio-utils/CSVUtils"
import { vehicleIdDisplay, vehiclesTableColumns } from "./TableHelpers";
import GraphMonthly from "./GraphMonthly";
import GraphMonthlyTod from "./GraphMonthlyTod";
import { StatView } from "@sawatchlabs/swt-components";
import { DateTime } from "luxon";

//code-splitting imports
const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));


export type LocationSummaryProps = {
    chargeLocations: Map<number, ChargeLocation>
    selectedChargeLocation: number
    req: any
    totalVehicleCount: number
    category?: string
    vehicleClasses?: Array<string>
    group?: string
    electrification?: number
    beginDate?: DateTime
    endDate?: DateTime
    dbDisplayName: string,
    userSettings: any
}

type StatBoxesState = {
    vehiclesProjectedToCharge: {
        value: string,
        subValue: string,
        caption?: string
    },
    vehiclesWithPassingEvScore: {
        value: string
        caption?: string
    },
    highestPeakDemand: {
        value: string,
        subValue: " kW"
        caption?: string
    },
    level2PortsNeeded: {
        value: string
        caption?: string
        subCaption?: string
    },
    dcfcPortsNeeded: {
        value: string
        caption?: string
        subCaption?: string
    },
    totalLocationCharging: {
        value: string
        subValue?: string
        caption?: string
    }
}

export default function LocationSummary({ chargeLocations, selectedChargeLocation, req, totalVehicleCount, category, group, vehicleClasses, beginDate, endDate, electrification, dbDisplayName, userSettings }: LocationSummaryProps) {
    const [showVehicleCountToolTip, setShowVehicleCountToolTip] = useState<Boolean>(false);
    const [showPrimaryParkingToolTip, setShowPrimaryParkingToolTip] = useState<Boolean>(false);
    const [showParkingPercentageToolTip, setShowParkingPercentageToolTip] = useState<Boolean>(false);
    const chargeLocation: any = chargeLocations.get(selectedChargeLocation);
    const [cursorCoords, setCursorCoords] = useState({ x: 0, y: 0 });

    const handleShowPrimaryParkingToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowPrimaryParkingToolTip(show);
    }
    const handleShowVehicleCountToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowVehicleCountToolTip(show);
    }
    const handleShowParkingPercentageToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowParkingPercentageToolTip(show);
    }

    const obj = vehiclesTableColumns.find((vtc) => vtc.Header === "Primary Parking Location");
    obj.showToolTip = handleShowPrimaryParkingToolTip;

    const [statBoxesState, dispatchStatBoxes] = useReducer((state: StatBoxesState, action: Partial<StatBoxesState>) => ({
        ...state,
        ...action

    }),
        {
            vehiclesProjectedToCharge: {
                value: "",
                subValue: ""
            },
            vehiclesWithPassingEvScore: {
                value: ""
            },
            highestPeakDemand: {
                value: "",
                subValue: " kW"
            },
            level2PortsNeeded: {
                value: ""
            },
            dcfcPortsNeeded: {
                value: ""
            },
            totalLocationCharging: {
                value: "",
                subValue: " %"
            }
        })

    useEffect(() => {
        dispatchStatBoxes({
            vehiclesProjectedToCharge: {
                value: `${chargeLocation?.chargingVehiclesCount ? chargeLocation.chargingVehiclesCount : 0}`,
                subValue: `/${totalVehicleCount ? totalVehicleCount : 0}`,
                caption: chargeLocation.pkid === -1 ? "Vehicles in Selection/Total Vehicles" : "Vehicles Projected to Charge/Total Vehicles"
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chargeLocation])

    useEffect(() => {
        dispatchStatBoxes({
            vehiclesWithPassingEvScore: {
                value: `${chargeLocation?.evRecommendationCount ? chargeLocation.evRecommendationCount : 0}`,
                caption: "Vehicles recommended for replacement with an EV"

            }
        })
    }, [chargeLocation])

    useEffect(() => {
        dispatchStatBoxes({
            highestPeakDemand: {
                value: `${chargeLocation?.peakKw ? chargeLocation.peakKw : 0}`,
                subValue: " kW",
                caption: "Highest Peak Demand at Same Time"
            }
        })

    }, [chargeLocation])

    useEffect(() => {
        const locationHistogram = chargeLocation.drawHistogram
        dispatchStatBoxes({
            level2PortsNeeded: {
              value: `${locationHistogram[0]}/${locationHistogram[1]}/${locationHistogram[2]}`,
              caption: `L2 Ports Needed`,
              subCaption: '(7kW/11kW/20kW)'
            }
          })
          dispatchStatBoxes({
            dcfcPortsNeeded: {
              value: `${locationHistogram[3] + locationHistogram[4]}`,
              caption: 'DCFC Ports Needed',
              subCaption: '(50kW+)'
            }
          })
    }, [chargeLocation])

    useEffect(() => {
        const locationCharging =  chargeLocation.atLocationChargingKwh ? chargeLocation.atLocationChargingKwh : 0;
        const nonLocationCharging =  chargeLocation.notAtLocationChargingKwh ? chargeLocation.notAtLocationChargingKwh : 0;
        const totalCharging = locationCharging+nonLocationCharging;
        const val = totalCharging > 0 ? Math.round(locationCharging / totalCharging * 100) : 0 ; 
        // const val = 0;
        dispatchStatBoxes({
            totalLocationCharging: {
                value: `${val}`,
                subValue: " %",
                caption: "Charging projected at this location"
            }
        })
    }, [chargeLocation]);

    useMemo(() => {
        function dateSort(a, b) {
            if(a.local_start < b.local_start)return 1;
            if(a.local_start > b.local_start)return -1;
            return 0;
        }
        if(chargeLocation && chargeLocation.chargingVehiclesVins){
            chargeLocation.chargingVehiclesVins.forEach((v: string) => {
                const vcl = chargeLocation.vehicleResults.find((b: any) => b.vin === v);
                const ces = chargeLocation.chargeEvents.filter((ce: any) => ce?.vin === v);
                ces.sort(dateSort);
                let duration = 0;
                let count = 1;
                let bm = ces[0]?.local_start.split(' ')[0]
                ces.forEach((ce: any)=>{
                    if(bm !== ce.local_start.split(' ')[0]){
                        count++; //counting distinct dates
                        bm = ce.local_start.split(' ')[0];
                    }
                    const parsable_start = ce.local_start.replace(" ", "T");
                    const parsable_stop = ce.local_stop.replace(" ", "T");
                    const ts0 = DateTime.fromISO(parsable_start).toUTC();
                    const ts1 = DateTime.fromISO(parsable_stop).toUTC();
                    duration += (ts1.ts - ts0.ts)/1000/3600;
                });
                vcl.averageChargeHours = duration/count;
            })
        }
    }, [chargeLocation]);

    const formattedData = useMemo(() => {
        if(!chargeLocation) {return null}
        const immutableData = JSON.parse(JSON.stringify(chargeLocation.vehicleResults))
        return immutableData.map((vcl) => {
            vcl.asset_id = vehicleIdDisplay(vcl)
            return vcl;
        })
    },[chargeLocation])

    return (
        <>
            {showVehicleCountToolTip &&
                <S.ToolTipWrapper cursorCoords={cursorCoords}
                    onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                    onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
                >
                    <S.ToolTipText>
                    These are the number of vehicles projected to charge at this location based on the selected filters. Vehicles may be included in these figures that currently do not use this location as their primary parking location.
                    </S.ToolTipText>
                </S.ToolTipWrapper>
            }
            {showParkingPercentageToolTip &&
                <S.ParkingToolTipWrapper cursorCoords={cursorCoords}
                    onMouseEnter={(e) => {handleShowParkingPercentageToolTip(true, e)}}
                    onMouseLeave={(e) => {handleShowParkingPercentageToolTip(false, e)}}
                >
                    <S.ToolTipText>
                    This is the percentage of charging that is projected for all vehicles that would park at this location, in comparison to the total charging for the vehicles.
                    </S.ToolTipText>
                </S.ParkingToolTipWrapper>
            }
            {showPrimaryParkingToolTip &&
                <S.ParkingToolTipWrapper cursorCoords={cursorCoords}
                    onMouseEnter={(e) => {handleShowPrimaryParkingToolTip(true, e)}}
                    onMouseLeave={(e) => {handleShowPrimaryParkingToolTip(false, e)}}
                >
                    <S.ToolTipText>
                        This is the current primary parking location of this vehicle based on our analysis of most recent 30 days of travel.
                    </S.ToolTipText>
                </S.ParkingToolTipWrapper>
            }
            < S.PageLayout showTableRow={chargeLocation.vehicleResults.length > 0}>
                <S.ContentWrapper>
                <S.PageHeader>Location Summary</S.PageHeader>
                <S.StatBoxRow statCount = {selectedChargeLocation === -1 ? 5 : 6}>
                    <S.StatBoxContainer >
                        {/* evRecommendationCount / vehicleCount of location  */}
                        {/* evRecommendationCount / vehicleCount of location  */}
                        {chargeLocation && chargeLocation.pkid > -1 &&
                            <StatView values={statBoxesState.vehiclesProjectedToCharge}
                                onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                                onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
                            />
                        }
                        {chargeLocation && chargeLocation.pkid === -1 &&
                            <StatView values={statBoxesState.vehiclesProjectedToCharge}
                                onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                                onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
                            />
                        }
                    </S.StatBoxContainer>
                    <S.StatBoxContainer >
                        {/* evRecommendationCount / vehicleCount of location  */}
                        <StatView values={statBoxesState.vehiclesWithPassingEvScore}/>
                    </S.StatBoxContainer>
                    <S.StatBoxContainer>
                        <StatView values={statBoxesState.highestPeakDemand}/>
                    </S.StatBoxContainer>
                    {/* Same as max EVs for now */}
                    <S.StatBoxContainer>
                        <StatView values={statBoxesState.level2PortsNeeded}/>
                    </S.StatBoxContainer>
                    <S.StatBoxContainer>
                        <StatView values={statBoxesState.dcfcPortsNeeded}/>
                    </S.StatBoxContainer>
                    { (selectedChargeLocation !== -1) && 
                    <S.StatBoxContainer
                        onMouseEnter={(e) => {handleShowParkingPercentageToolTip(true, e)}}
                        onMouseLeave={(e) => {handleShowParkingPercentageToolTip(false, e)}}
                    >
                        <StatView values={statBoxesState.totalLocationCharging}/>
                    </S.StatBoxContainer>}
                </S.StatBoxRow>
                </S.ContentWrapper>
                {chargeLocation?.monthlyPeaks &&
                    <> 
                        <S.GraphContainer>
                            <S.GraphContainerLhs>
                                < GraphMonthly
                                    monthlyPeaks={chargeLocation.monthlyPeaks}
                                    displayTitle={true}
                                />
                            </S.GraphContainerLhs>
                            <S.GraphContainerRhs>
                                < GraphMonthlyTod monthlyPeaks={chargeLocation.monthlyPeaks} userSettings={userSettings} />
                            </S.GraphContainerRhs>
                        </S.GraphContainer>
                    </>}
                <S.TableContainer>
                    <S.TableTitle showTopMargin={chargeLocation.chargingVehiclesCount === 0}>Vehicles</S.TableTitle>
                    <S.TableSubtitle>
                        This table lists the vehicles that are projected to charge at this location.
                    </S.TableSubtitle>

                    <Table columns={vehiclesTableColumns} data={formattedData} hoverHighlight={false} defaultSort={'chargingHours'}/>
                    <div>
                        <Suspense fallback={<div></div>}>
                            <ExcelDownloadButton
                                csvType={'vehicles'}
                                beginDate={beginDate}
                                endDate={endDate}
                                category={category}
                                group={group}
                                vehicleClasses={vehicleClasses}
                                electrification={electrification}
                                location={chargeLocation}
                                db={req.dbName}
                                columns={columnsToHeaders(vehiclesTableColumns)}
                                data={rowsToData(vehiclesTableColumns, formattedData, (a: any, b: any) => a.assetId > b.assetId ? 1 : -1)}
                                dbDisplayName={dbDisplayName}
                                userSettings={userSettings}
                            />
                        </Suspense>
                    </div>

                </S.TableContainer>
            </S.PageLayout>
        </>
    )
}
