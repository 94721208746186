import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import AdminTableView from "./AdminTableView";
import Loading from "../swt-loading";
import Search from "./search";
import * as S from "../../../../styles/core-styles/AdminTools-styles";

const SECS_PER_MIN = 60;
const SECS_PER_HOUR = SECS_PER_MIN * 60; // 3600 seconds per hour
const SECS_PER_DAY = SECS_PER_HOUR * 24; // 86400 seconds per day

export default function SuperAdminDatabasesTable(props){
    const PROCESS_HOURS_LIMIT = 6;
    const PERIODIC_HOURS_LIMIT = 12;
    const ANALYTCIS_HOURS_LIMIT = 25;
    const AGGREGATE_HOURS_LIMIT = 25;


    const { apiURL, user } = props;
    
    const skipPageResetRef0 = useRef(true);
    const skipPageResetRef1 = useRef(true);

    const [databases, setDatabases] = useState(null);
    const [now, setNow] = useState(new Date());
    const [refresh, setRefresh] = useState(true);
    //const [immutableDatabases, setImmutableDatabases] = useState(null);
    const [matchingDatabases, setMatchingDatabases] = useState(null);
    useEffect(() => {
        const interval = setInterval(() => {
            skipPageResetRef0.current = false
            setNow(new Date());
          }, 1000);
          return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            skipPageResetRef1.current = false
            setRefresh(true);
          }, 60000);
          return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        skipPageResetRef0.current = true
    }, [now]);

    useEffect(() => {
        skipPageResetRef1.current = true
    }, [refresh]);

    const boolSort = useMemo(() => (rowA, rowB, columnId) => {
        const a = rowA.values[columnId].props.bool;
        const b = rowB.values[columnId].props.bool;
        return a > b ? 1 : -1;
    },[]);

    const dateSort = useMemo(() => (rowA, rowB, columnId) =>{
        const a = rowA.values[columnId].props.epoch;
        const b = rowB.values[columnId].props.epoch;
        return a > b ? 1 : -1;
    }, []);

    const statusSort = useMemo(() => (rowA, rowB, columnId) =>{
        const a = rowA.values[columnId].props.status;
        const b = rowB.values[columnId].props.status;
        return a > b ? 1 : -1;
    }, []);

    const secondsToReadableString = (seconds) => {
        if(seconds > (SECS_PER_DAY - 1))return `${parseFloat(seconds / SECS_PER_DAY).toFixed(0)} Days`;
        if(seconds > (SECS_PER_HOUR - 1))return `${parseFloat(seconds / SECS_PER_HOUR).toFixed(1)} Hours`;
        if(seconds > (SECS_PER_MIN - 1))return `${parseFloat(seconds / SECS_PER_MIN).toFixed(0)} Minutes`;
        return `${parseFloat(seconds).toFixed(0)} Seconds`;
    }

    const databaseStatus = (seconds, update, trip_seconds, analytics_seconds, aggregate_sconds) => {
        if(!seconds || !update || !trip_seconds || !analytics_seconds)return(<S.CDRStatusMarker status={3} backgroundColor="#a9a9a9"></S.CDRStatusMarker>);
        if(seconds > (SECS_PER_DAY - 1))return(<S.CDRStatusMarker status={0}  backgroundColor="#e76f51"></S.CDRStatusMarker>);
        if(seconds > (SECS_PER_HOUR*PERIODIC_HOURS_LIMIT) ||
           trip_seconds > (SECS_PER_HOUR*PROCESS_HOURS_LIMIT) ||
           analytics_seconds > (SECS_PER_HOUR*ANALYTCIS_HOURS_LIMIT) ||
           aggregate_sconds > (SECS_PER_HOUR*AGGREGATE_HOURS_LIMIT)) return(<S.CDRStatusMarker status={1} backgroundColor="#e9c46a"></S.CDRStatusMarker>);
        return(<S.CDRStatusMarker status={2} backgroundColor = "#2a9d8f"></S.CDRStatusMarker>);
    }

    const tableColumns = [
        {Header: "Status", accessor: "status", sortType: statusSort, width: 100},
        {Header: "Database Id", accessor: "name", sortType: "basic", width: 200, prevColWidth: '100px'},
        {Header: "Display Name", accessor: "display_name", sortType: "basic"},
        {Header: "Controller", accessor: "controller", sortType: "basic", width: 150},
        {Header: "IP Address", accessor: "address", sortType: "basic", width: 200},
        {Header: "Integration", accessor: "integration", sortType: "basic", width: 150},
        {Header: "Periodic Updated", accessor: "periodic_updated", sortType: dateSort},
        {Header: "Telemetry Processed", accessor: "trips_updated", sortType: dateSort},
        {Header: "Analytics Updated", accessor: "analytics_updated", sortType: dateSort},
        {Header: "Aggregate Updated", accessor: "aggregate_updated", sortType: dateSort},
        {Header: "Incomplete Vehicles", accessor: "incomplete_vcls", sortType: "basic"},
        {Header: "Updating", accessor: "update", sortType: boolSort},
    ]
    
    useEffect(()=>{
        if (refresh === false ) return;
        try {
            fetch(`${apiURL}getDatabases`, {
                headers: { Authorization: `Bearer ${user.token}` },
            })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.status === "error")
                        alert("There was a server error during your request.");
                    else {
                        setDatabases(data.data);
                        setRefresh(false);
                        //setImmutableDatabases(JSON.parse(JSON.stringify(data.data)));
                    }
            });
        } catch (err) {
            console.error("error", err);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[apiURL, user, refresh]);

    //applying styled components to the input element strips custom attrs, so vin and accessor become nulls
    const BoolTableCell = useCallback((props) =>
        <input
            className="swt-admin-table-input"
            type="checkbox"
            id={props.id} 
            name={props.name}
            accessor={props.accessor} 
            style={{color: props.bool ? "black" : "transparent"}} 
            onChange={props.handleOnClick}
            checked={(props.bool === null || typeof props.bool === "undefined") ? false : props.bool}
            disabled={typeof props.bool === "undefined" ? true : false}
        />,[]);

    const DateTableCell = useCallback((props)=> 
        <div
            id={props.id}
            onClick={props.handleOnClick}
            title={props.epoch ? secondsToReadableString((now.getTime() + (now.getTimezoneOffset() * SECS_PER_MIN * 1000) - props.epoch)/1000): null}
        >
            {props.epoch ? secondsToReadableString((now.getTime() + (now.getTimezoneOffset() * SECS_PER_MIN * 1000) - props.epoch)/1000): null}
        </div>,[now]
        );

    const updateDatabaseUpdatingFlag = (db) => {
        try{
            const url = `${props.apiURL}updateDatabaseUpdatingFlag`;
            const body = {dbName: db.name, update: db.update}
            fetch(url, {
              headers: { 
                Authorization: `Bearer ${props.user.token}`,
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              method: "POST",
              body: JSON.stringify(body)
            })
              .then((resp) =>{
                return resp.json();
              })
              .then((data) => {
                if (data.status === "error") {
                    alert("There was a server error during your request.");
                    db.update = !db.update
                }
              });
          } catch (err) {
            console.error(err);
          }
    }

    const toggleUpdating = (d) => {
        let db = databases.find(e => e.name === d.name);
        db.update = !db.update;
        updateDatabaseUpdatingFlag(db);
    }

    const mappedDatabases = useMemo(()=>{
        if(!databases || !matchingDatabases)return null;
        if(databases.length < 1)return [];

        const dbs = JSON.parse(JSON.stringify(databases));
        const matchingDbs = matchingDatabases.map((d) => {return d.name});
        return dbs.filter((d) => {
            if(matchingDbs.indexOf(d.name) < 0)return null;
            d.integration = d.integration ? d.integration : "Not Assigned";
            d.update = <BoolTableCell bool={d.update} accessor={"update"} handleOnClick={() => {toggleUpdating(d)}}/>
            d.periodic_updated = <DateTableCell epoch={d.periodic_updated ? Date.parse(d.periodic_updated) : null} accessor={"periodic_updated"} handleOnClick={function(){}}/>
            d.trips_updated = <DateTableCell epoch={d.trips_updated ? Date.parse(d.trips_updated) : null} accessor={"trips_updated"} handleOnClick={function(){}}/>
            d.analytics_updated = <DateTableCell epoch={d.analytics_updated ? Date.parse(d.analytics_updated) : null} accessor={"analytics_updated"} handleOnClick={function(){}}/>
            d.aggregate_updated = <DateTableCell epoch={d.aggregate_updated ? Date.parse(d.aggregate_updated) : null} accessor={"aggregate_updated"} handleOnClick={function(){}}/>

            
            d.status = databaseStatus((now.getTime() + (now.getTimezoneOffset() * SECS_PER_MIN * 1000) - d.periodic_updated.props.epoch)/1000,  
            d.update.props.bool, 
            (now.getTime() + (now.getTimezoneOffset() * SECS_PER_MIN * 1000) - d.trips_updated.props.epoch)/1000, 
            (now.getTime() + (now.getTimezoneOffset() * SECS_PER_MIN * 1000) - d.analytics_updated.props.epoch)/1000,
            (now.getTime() + (now.getTimezoneOffset() * SECS_PER_MIN * 1000) - d.aggregate_updated.props.epoch)/1000);
            return d;
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [databases, matchingDatabases, now]);
    return(
        <>
            <S.CandidatesTableHeaderContainer>
                <div>
                    <S.AdminTableExplainTextPrimary>List of all databases.</S.AdminTableExplainTextPrimary>
                    <S.AdminTableExplainTextSub>Click a column name to sort the table.</S.AdminTableExplainTextSub> 
                    <S.SearchContainer>
                    <Search allValues={databases} setMatchingValues={setMatchingDatabases}/>
                    </S.SearchContainer>
                </div>
            </S.CandidatesTableHeaderContainer>
                    {!mappedDatabases && <Loading />}
                    {(mappedDatabases) && 
                        <AdminTableView 
                            columns={tableColumns}
                            data={mappedDatabases}
                            stickyCols={2}
                            sortBy={{id: "status", desc: false}}
                            skipPageResetRef0={skipPageResetRef0}
                        />
                    }
        </>
    )
}