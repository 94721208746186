import React, { Suspense } from "react";
import { decideVinDisplay, validateAPIResponse, vehicleIdDisplay, dateAsNonTZString } from "./UtilityFunctions";
import { processApiResponse } from "./utils/ConformUnits";

//code split imports
const TableRenderer = React.lazy(() => import('./TableRenderer'));

class TableController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVcl: "",
      vclData: [],
      reimbursementData: []
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.path !== prevProps.match.path){
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    if (this.props.match.path === "/ionev/reimbursement/:locId"){
      if (
        (this.props.match.params.locId !== prevProps.match.params.locId) ||
        prevProps.startDate !== this.props.startDate ||
        prevProps.endDate !== this.props.endDate
        ) {
        this.fetchSingleLocReimbursement(this.props.match.params.locId);
      }
    }
    if (this.props.match.path === "/ionev/driving-activity/:vin"){
      if (
        prevProps.startDate !== this.props.startDate ||
        prevProps.endDate !== this.props.endDate
      ) {
        this.fetchVclDailyActivity(this.props.match.params.vin);
      }
      // This needs to be refactored!
      if( this.state.vclData.length > 0 && this.props.match.params.vin !== this.state.vclData[0].vin){
        this.fetchVclDailyActivity(this.props.match.params.vin)
      }
    }
  }

  sortEventsForVcl(selectedVcl, data) {
    // map over events and filter on selected vehicle
    var events = [];
    // NOTE use this for map too, 'helper'
    data.forEach((e) => {
      if (e.vin === selectedVcl) events.push(e);
      return;
    });
    return events;
  }

  fetchVclDailyActivity(vin) {
    // NOTE potentially remove this, once decide where/when to get data
    let startDate = this.props.startDate
    let endDate = this.props.endDate
    var singleVclUrl = `${this.props.base_url}getTotalActivity?`;
    var queries = `clientId=${this.props.dbName}&vin=${vin}&start=${dateAsNonTZString(startDate)}&stop=${dateAsNonTZString(endDate)}`;
    fetch(`${singleVclUrl}${queries}`, {
      headers: { Authorization: `Bearer ${this.props.user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        let formattedData = data['data'].map((v) => {
          v.asset_id = vehicleIdDisplay(v)
          v.display_vin = decideVinDisplay(v)
          return v;
        })
        //conform units
        formattedData.map((i) => {return processApiResponse(this.props.user.userSettings, i)})
        this.setState({ vclData: formattedData });
        return formattedData
      })
      .catch((error) => console.error("Error: " + error));
  }

  fetchSingleLocReimbursement(locId) {
    let startDate = this.props.startDate
    let endDate = this.props.endDate
    var totalSummaryUrl = `${this.props.base_url}getReimbursementForLocation?`;
    var queries = `&clientId=${this.props.dbName}&start=${dateAsNonTZString(startDate)}&stop=${dateAsNonTZString(endDate)}&locId=${locId}`;
    fetch(`${totalSummaryUrl}${queries}`, {headers:{Authorization: `Bearer ${this.props.user.token}`}})
    .then(res => res.json())
    .then((data) => {
      let reimbursementData = validateAPIResponse(data, false, [])
      reimbursementData.map((i) => {return processApiResponse(this.props.user.userSettings, i)})
      this.setState({ reimbursementData: reimbursementData })
    })
    .catch((error) => console.error("Error: " + error));
  }

  combineSummaryData() {
    var charge = this.props.chargeSummaryByLocation;
    var missed = this.props.missedSummaryByLocation;
    var locs = []
    charge.forEach(c => {  
      // see if missed opp at charge loc
      var missedLoc = missed.filter((m) => m.charge_loc_id === c.pkid);
      if (missedLoc.length > 0) {
        // add to charge obj
        c["missed_distinct_vins"] = missedLoc[0]["missed_distinct_vins"];
        c["missed_event_count"] = missedLoc[0]["missed_event_count"];
        c["missed_avg_duration"] = missedLoc[0]["missed_avg_duration"];
        c["missed_avg_kwh"] = missedLoc[0]["missed_avg_kwh"];
        c["missed_avg_soc_start"] = missedLoc[0]["missed_avg_soc_start"];
        c["avg_missed_elec_km"] = missedLoc[0]["avg_missed_elec_km"];
        c["missed_avg_distance"] = missedLoc[0]["missed_avg_distance"];
      } else {
        c["missed_distinct_vins"] = "-";
        c["missed_event_count"] = "-";
        c["missed_avg_duration"] = "-";
        c["missed_avg_kwh"] = "-";
        c["missed_avg_soc_start"] = "-";
        c["avg_missed_elec_km"] = "-";
        c["missed_avg_distance"] = "-";
      }
      locs.push(c);
    });
    missed.forEach(m => {
      // see if missed opp at charge loc
      var chargeLoc = charge.filter(c => m.charge_loc_id === c.pkid);
      if(chargeLoc.length > 0){
        // missed opp already added in above loop
        return;
      } else {
        // add location
        m['pkid'] = m['charge_loc_id'];
        m['charge_event_count'] = '-';
        m['charge_avg_kwh'] = '-';
        m['charge_distinct_vins'] = '-';
      }
      locs.push(m);
    });
    return locs;
  }

  selectTableData = () => {
    // This needs to be refactored!
    if (this.props.match.path === "/ionev/driving-activity/:vin") {
      if(this.state.vclData.length === 0)
      this.fetchVclDailyActivity(this.props.match.params.vin);
      return this.state.vclData
    } else if (this.props.match.path === "/ionev/driving-activity") {
      return this.props.totalActivitySummary;
    } else if (this.props.match.path === "/ionev/charging-events/:vin") {
      return this.sortEventsForVcl(
        this.props.match.params.vin,
        this.props.chargeEvents
      );
    } else if (this.props.match.path === "/ionev/charging-events") {
      return this.props.chargeSummaryByVcl;
    } else if (this.props.match.path === "/ionev/missed-opportunities/:vin") {
      return this.sortEventsForVcl(
        this.props.match.params.vin,
        this.props.missedEvents
      );
    } else if (this.props.match.path === "/ionev/missed-opportunities") {
      return this.props.missedSummaryByVcl;
    } else if (this.props.match.path === "/ionev/events-by-location") {
      // discuss if we want checks like this, should all become redundant
      if (
        !this.props.missedSummaryByLocation ||
        !this.props.chargeSummaryByLocation
      )
        return;
      return this.combineSummaryData();
    } else if (this.props.match.path === "/ionev/tco-comparison") {
      return this.props.tcoDataTotal;
    }
    if (this.props.match.path === "/ionev/reimbursement/:locId"){
      return this.state.reimbursementData;
    }
    if (this.props.match.path === "/ionev/reimbursement"){
      return this.props.reimbursementSummary;
    }
  };

  render() {
    let data = this.selectTableData();
    return (
      <Suspense fallback={<></>}>
        <TableRenderer
          activity={data}
          path={this.props.match.path}
          type={this.state.tableType}
          period={[this.props.startDate, this.props.endDate]}
          fuelCost={this.props.fuelCost}
          dbName={this.props.dbName}
          dbDisplayName={this.props.dbDisplayName}
          user={this.props.user}
          formattedStartDate={this.props.formattedStartDate}
          formattedEndDate={this.props.formattedEndDate}
        />
      </Suspense>
    );
  }
}

export default TableController;
