import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import * as colors from "./ColorScheme";

const MEDIUM_VIEW_BREAKPOINT = "1200px";
const SMALL_VIEW_BREAKPOINT = "880px";

export const StatBoxContainer = styled.div`
max-width: 100vw;
`

type NoDataMessageProps = {
    show: boolean
}

type CountMismatchMessageProps = {
    show: boolean
}

type ToolTipWrapperProps = {
    cursorCoords: {x: number, y: number}
}


export const ToolTipWrapper = styled.div<ToolTipWrapperProps>`
    padding: 8px;
    border: 1px solid #979a9e;
    box-shadow: 2px 2px 4px #47484a;
    border-radius: 5px;
    width: 300px;
    z-index: 1;
    background-color: white;
    font-size: 14px;
    display: inline-block;
    color: #47484a;
    position: absolute;
    ${({ cursorCoords }:  ToolTipWrapperProps) => {
        return `left: ${cursorCoords.x}px;top: ${cursorCoords.y}px`
    }}
`;

export const ToolTipText = styled.p`
    margin: 0;`

export const CountDownText = styled.p`
    width: 300px; 
    marginLeft 35px;
`

export const NoDataMessage = styled.div<NoDataMessageProps>`
width: 100%;
height: 16px;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 0px;
text-align: center;
font-family: "Arimo";
${({ show }: NoDataMessageProps) => (
        show ? "" : "visibility: hidden;"
    )

    }
color: ${colors.ezio_dark_grey};`

export const CountMismatchMessage = styled.div< CountMismatchMessageProps>`
width: 100%;
height: 12px;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 0px;
text-align: center;
font-family: "Arimo";
${({ show }:  CountMismatchMessageProps) => (
        show ? "" : "visibility: hidden;"
    )
}
color: ${"red"};`

export const StatBoxRow = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(49px, 1fr));
column-gap: 8%;
margin: 0 auto 49px auto;
place-items: end center;
text-align: center;
width: 100%;
max-width: 1000px;
@media(max-width: ${MEDIUM_VIEW_BREAKPOINT}) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 49px;
    column-gap: 49px;
    width: 50%;
}
@media(max-width: ${SMALL_VIEW_BREAKPOINT}) {
    margin-left: 0;
}
`

export const StatBoxLink = styled(Link)`
text-decoration: none;
color: inherit;
`