import React, { useEffect, useState, useCallback } from 'react'
import { Line } from 'react-chartjs-2';
import { formatTimeString } from '../../utils/ezio-utils/ConformUnits';

const hourQuarters = ["00", "15", "30", "45"];

const peakPointBackgroundColor = "rgba(3, 94, 91, .2)";
const offPeakPointBackgroundColor = "rgba(178, 177, 175,.8)";
const peakBackgroundColor = "rgba(3, 94, 91, .2)";
const offPeakBackgroundColor = "rgba(167, 166, 164, .04)";
const peakBorderColor = "rgba(3, 94, 91, .8)";
const offPeakBorderColor = "rgba(167, 166, 164, .5)";
const peakHoverBackgroundColor = "rgba(3, 94, 91, .7)";
const offPeakHoverBackgroundColor = "rgba(178, 177, 175, 1)";

type GraphDataset = {
  data: number[],
  backgroundColor: string,
  hoverBackgroundColor: string,
  borderColor: string,
  pointBackgroundColor: string,
  borderJoinStyle: "miter",
  fill: boolean,
  lineTension: number,
  borderWidth: number,
}

type GraphDataSorted = {
  labels: string[],
  datasets: Array<GraphDataset>
}

export default function GraphMonthlyTod({ monthlyPeaks, userSettings }: any) {
  const [monthlyPeaksGraphData, setMonthlyPeaksGraphData] = useState<GraphDataSorted>();
  function markPeaks(monthlyPeaks: any) {
    let max = -1;
    for(const d in monthlyPeaks){
      max = Math.max(max, monthlyPeaks[d].peakKw);
    }
    for(const d in monthlyPeaks){
     monthlyPeaks[d].isPeak = (monthlyPeaks[d].peakKw === max) ? true : false
    }
    //data.forEach((d) => { max = Math.max(max, d.maxKw) });
    //data.forEach((d) => { d.isPeak = (d.maxKw === max) ? true : false });
    //return data;
    return monthlyPeaks;
  }

  const standardizeData = useCallback((monthlyPeaks: any) => {
    if (!monthlyPeaks) return;
    monthlyPeaks = markPeaks(monthlyPeaks);
    let labels: string[] = [];
    
    for(const d in monthlyPeaks){
      if(labels.length > 0)continue;
      labels = monthlyPeaks[d].activity.map((d: [], idx: number) => {
        return formatTimeString(userSettings, Math.floor(idx / 4), hourQuarters[idx % 4]);
      });
    }
    let newSorted: Array<GraphDataset> = [];
    for(const d in monthlyPeaks){
      //data.forEach((d: any) => {
      const s = {
        label: monthlyPeaks[d].longDate,
        data: monthlyPeaks[d].activity,
        pointRadius: 1,
        pointHitRadius: 3,
        pointBackgroundColor: (monthlyPeaks[d].isPeak) ? peakPointBackgroundColor : offPeakPointBackgroundColor,
        borderJoinStyle: "miter" as const,
        fill: true,
        lineTension: 0.3,
        borderWidth: (monthlyPeaks[d].isPeak) ? 2.5 : 1,
        backgroundColor: (monthlyPeaks[d].isPeak) ? peakBackgroundColor : offPeakBackgroundColor,
        borderColor: (monthlyPeaks[d].isPeak) ? peakBorderColor : offPeakBorderColor,
        hoverBackgroundColor: (monthlyPeaks[d].isPeak) ? peakHoverBackgroundColor : offPeakHoverBackgroundColor,
        order: (monthlyPeaks[d].isPeak) ? -1 : 1
      };
      newSorted.push(s)
    }
    setMonthlyPeaksGraphData({
      labels: labels,
      datasets: newSorted,
    })
  }, [userSettings]);

  useEffect(() => {
    if (monthlyPeaks) {
      standardizeData(monthlyPeaks);
    }
  }, [monthlyPeaks, standardizeData]);

  const options = {
    // interaction: {
    //   mode: "dataset",
    //   intersect: true,
    // },
    transitions: {
      active: {
        animation: {
          duration: 0
        }
      }
    },
    hover: {
      mode: "dataset" as const
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        // align: 'center',
        display: true,
        text: 'kW Demand Peaks'
      },
      tooltip: {
        callbacks: {

          afterTitle: (context: any) => {
            return `${context[0].raw} kW`
          },
          label: (context: any) => {
            return `${context.dataset.label}`
          }
        }
      },

    },
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: "Peak kW"
        }
      },
      x: {
        display: true,
        title: {
          display: true,
          text: "Time of Use"
        },
        ticks: {
          maxTicksLimit: 24
        },
      },
    }

  };
  return (

    monthlyPeaksGraphData && options ?
      <Line
        id="monthly-tod-graph"
        width={600}
        height={400}
        data={monthlyPeaksGraphData}
        options={options}
      />
      : <></>

  )

}
