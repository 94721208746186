import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { formatOptionLabel, scrollToSelected, Dropdown } from "../../utils/ezio-utils/SelectUtils"
import * as CS from "../../styles/ezio-styles/Controls-styles"



type GroupsDropdownProps = {

    groups: Array<any>,
    selectedGroup?: any
    onChange: Function
}




export default function GroupsDropdown({ groups, selectedGroup, onChange }: GroupsDropdownProps) {

    const [options, setOptions] = useState<Array<any>>([])
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setOptions(
            groups.map((g) => { g.value=g.id; g.label = g.name; return g }))
    }, [groups])


    //Unfortunately, adding pagination means stripping out the type checking specifically here. 
    //The data does come out of and into a variable that does check though, so this should be fine.
    const loadGroups = async (search: string, prevGroups: any) => {

        let filteredGroups
        if (!search) {
            filteredGroups = options
        } else {
            const searchLower = search.toLowerCase()
            filteredGroups = options.filter(({ label }) => label.toLowerCase().includes(searchLower))
        }
        const hasMore = filteredGroups.length > prevGroups.length + 10;
        const slicedGroups = filteredGroups.slice(
            prevGroups.length,
            prevGroups.length + 10
        );
        return {
            options: slicedGroups,
            hasMore
        }

    }

    function toggleOpen() {
        setIsOpen(!isOpen)
    }
    useEffect(() => {
        scrollToSelected()

    }, [isOpen])

    return (

        <Dropdown
            isOpen={isOpen}
            onClose={toggleOpen}
            target={
                <CS.GroupsMenuButton onClick={toggleOpen}>
                    <CS.GroupsMenuText>
                        {selectedGroup?.label ? selectedGroup.label : "No Groups Available"}
                    </CS.GroupsMenuText>
                    <CS.CustomDownChevron />
                </CS.GroupsMenuButton>
            }
        >

            <AsyncPaginate
                autoFocus
                backspaceRemovesValue={false}
                menuIsOpen
                controlShouldRenderValue={true}
                tabSelectsValue={false}
                loadOptions={
                    loadGroups
                }
                placeholder={"Search Group Names"}
                value={options.find((item) => {
                    return ((selectedGroup ?? "") === item.value)
                })}
                onChange={(event) => {
                    onChange(event.value)
                    setIsOpen(false)
                }}
                options={options}
                isSearchable={true}
                styles={CS.SelectStyles}
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: null,

                }}
                formatOptionLabel={formatOptionLabel}
            />
        </Dropdown>



    )
}





