import React from 'react';
import styled from 'styled-components';
import LogoImage from '../../images/emit-dark.png';


const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 12px;
    vertical-align: top;
`

const HeaderLogoWrapper = styled.a`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between
    height: 60px;
`

const HeaderLogo = styled.img`
    width: 100px;
`

const HeaderTextWrapper = styled.div`

`

const HeaderTextMain = styled.h1`
    margin: 0;
    padding: 0;
    font-family: "Arimo","Open Sans","HelveticaNeue","Helvetica Neue",Arial,sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.17;
    color: #47484a;
    padding-left: 20px;
`

const HeaderTextSub = styled(HeaderTextMain)`
    color: #979a9e;
`



export default function ProductHeader(props) {

    return (
        <HeaderContainer>
           <HeaderLogoWrapper href="https://sawatchlabs.com/" target="_blank"rel="noopener noreferrer">
           <HeaderLogo src={LogoImage}/>
            </HeaderLogoWrapper>
            <HeaderTextWrapper>
                <HeaderTextMain>Emissions Report</HeaderTextMain>
                <HeaderTextSub>{props.fleetDisplayName}</HeaderTextSub>
            </HeaderTextWrapper>
        </HeaderContainer>
    )
}