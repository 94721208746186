import React from 'react';
import { Link } from "react-router-dom";
import * as S from "../../styles/ezev-styles/Links-styles"

export default function LinkTable() {
  return (
    <div className="ezev-link-wrapper">
      < Link className="ezev-link" to={{ pathname: "/ezev/vehicle-results" }}>Table</Link>
    </div>
  )
}

export function LinkBackToTable() {
  return (
    <div className="ezev-link-wrapper">
      < Link className="ezev-link" to={{ pathname: "/ezev/vehicle-results" }}>Back</Link>

    </div>
  )
}

export function LinkHome(props) {
  return (
    <div className={`ezev-link-wrapper ${props.addClass}`}>
      <Link className="ezev-link" to={{ pathname: "/ezev/landing" }}>Home</Link>
    </div>
  )
}

export function LinkSelectGroupLanding(props) {
  return (
    <div className={`ezev-link-wrapper`} onClick={props.onClick}>
      < Link className="ezev-link" to={{ pathname: "/ezev/landing" }} >Select</Link>
    </div>
  )
}

export function LinkBack() {
  return (
    <div className="ezev-link-wrapper">
      < Link className="ezev-link" to={{ pathname: "/ezev/landing" }}>Back</Link>
    </div>
  )
}

export function LinkSingleVcl() {
  return (
    <div className="ezev-link-wrapper">
      < Link className="ezev-link" to={{ pathname: "/ezev/vehicle" }}>Back</Link>
    </div>
  )
}



export function LinkCandidateSelect() {
  return (
    <div id="ezev-select-candidates-button" className="ezev-link-wrapper" data-testid="selectCandidates-testid">
      <Link className="ezev-link" to={{ pathname: "/ezev/candidate-select" }}>Change Candidates</Link>
    </div>
  )
}

export function LinkAssumptions() {
  return (
    <div className="ezev-link-wrapper ezev-assumptions-button">
      <Link className="ezev-link" to={{ pathname: "/ezev/assumptions" }}>View Assumptions</Link>
    </div>
  )
}

export const LinkShowVehicles = () => (
  <S.ShowVehiclesLink to={"/ezev/vehicle-results"} className="ezev-button-2 ezev-link-wrapper ezev-button-font-2">
    Show Vehicles
  </S.ShowVehiclesLink>
)

export function LinkEditAssumptions() {
  // for admins, redirects to dashboard admin cog, settings/assumptions edit
  return (
    <div className="ezev-link-wrapper ezev-assumptions-button" data-testid="assumptionsEdit-testid">
      <Link className="ezev-link"
        to={{
          pathname: "/admin/settings",
          state: { redirectFrom: "/ezev/group-select" }
        }}>
        Edit Assumptions
      </Link>
    </div>
  )
}
