import React, { useMemo, useEffect, useState, useRef } from 'react'
import AdminTableView from './AdminTableView';
import Search from "./search";
import Loading from "../swt-loading";
import { _stringSort, _numberSort } from './TableHelpers';
import * as S from '../../../../styles/core-styles/AdminTools-styles';

export default function GroupManagementTable(props) {
    const {user, db, apiURL, dbDisplayName} = props;
    const skipPageResetRef = useRef(true);

    const [groups, setGroups] = useState([])
    const [matchingGroups, setMatchingGroups] = useState([])

    useEffect(() => {
        const url = `${apiURL}getGroups/${db}`;
        fetch(`${url}`, {
            headers: { Authorization: `Bearer ${user.token}`}
        })
            .then((resp) => {
                return resp.json()
            }).then((data) => {
                if (data.status === "error")
                    alert("There was a server error during your request.");
                else
                    setGroups(data.data)
            }).catch((error) => console.error(`Error: ${error}`))
    }, [user, db, apiURL])

    const tableColumns = [
        {Header: 'Name', accessor: 'name', width: 200, sortType: _stringSort},
        {Header: 'Vehicle Count', accessor: 'vehicle_count', width: 100, sortType: _numberSort},
        {Header: 'User Count', accessor: 'user_count', width: 100, sortType: _numberSort}
    ]

    const mappedGroups = useMemo(() => {
        if(!groups || !matchingGroups) {return null}
        const immutableGroupsList = JSON.parse(JSON.stringify(groups))
        const matchedGroups = matchingGroups.map((g) => { return g.id})
      
        return immutableGroupsList.filter((u) => {
          if(matchedGroups.indexOf(u.id) < 0) {return null;} 
          return u;
        })
      },[matchingGroups, groups])

    if (!groups || groups.length < 1) return (<Loading />)
    return (
            <>
                <S.TableHeaderContainer>
                    <S.AdminTableExplainTextPrimary>List of vehicle groups in {dbDisplayName}.</S.AdminTableExplainTextPrimary>
                    {/* <S.AdminTableExplainTextSub>Click a column name to sort the table.</S.AdminTableExplainTextSub> */}
                    <S.SearchContainer>
                        <Search allValues={groups} setMatchingValues={setMatchingGroups} />
                    </S.SearchContainer>
                </S.TableHeaderContainer>
            
                <AdminTableView
                    data={mappedGroups}
                    columns={tableColumns}
                    skipPageResetRef={skipPageResetRef}
                    noDataMessage={'No groups to display.'}
                />
        </>
    )
}
