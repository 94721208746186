import React from 'react';
import { productStackRank, FormatMonetaryValue } from './UtilityFunctions';
import Loading from './swt-loading';
import * as S from '../../../styles/core-styles/LandingPage-styles';
import * as ColorScheme from '../../../styles/core-styles/ColorScheme';

function TriangleIcon({ input }) {
  
  if (input === 0) return <></>;
  // Points for the triangles
  const pointsAscending = "50,0 100,50 0,50"; // Points for an upward-facing triangle
  const pointsDescending = "0,0 100,0 50,50"; // Points for a downward-facing triangle

  // Determine the fill color and points based on the positive prop
  const fillColor = input > 0 ? ColorScheme.arrow_green : ColorScheme.arrow_red;
  const points = input > 0 ? pointsDescending : pointsAscending;

  return (
   <S.TriangleWrapper>
      <svg width="100%" height="100%" viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg" display="block">
        <polygon points={points} fill={fillColor} />
      </svg>
   </S.TriangleWrapper>
  );
};

function generateProductIcon(p, selectItem, iconLength,wrapperLength,shadowSize,borderRadius,fontSize) {
  return (
    <S.LandingIconRow
      key={p.product}
      onClick={()=>selectItem(p)}
      wrapperLength={wrapperLength}
    >
      <S.LandingIconHoverContainer
        className='test'
        wrapperLength={wrapperLength}
      >
        <S.LandingIcon
          className='landing-icon'
          src={p.icon_url}
          alt={p.display_name}
          iconLength={iconLength}
          shadowSize={shadowSize}
          borderRadius={borderRadius}
        />
      </S.LandingIconHoverContainer>
      <S.LandingIconText
        fontSize={fontSize}
        width={iconLength}
      >
        {p.short_description}
      </S.LandingIconText>
    </S.LandingIconRow>
  )
}

function generatePurchasedSection(products,selectItem,allProductsCount) {
  const purchasedProducts = products.filter(p=> p.purchased && p.active && p.always_show).sort(productStackRank);
  const productCount = purchasedProducts.length;

  const justify = productCount >= 4 ? "space-between" : "center"; // spread the icons out to the full width if there are at least 4
  let size = "";
  if (productCount < 5 || productCount === allProductsCount) size = "large"; //if the client has all products purchased, show them in the largest size, on two rows (this logic could be expanded if this starts actually happening, currently only applicable to experimental)
  else if (productCount < 7 ) size = "medium";
  else size = "small";
  
  let iconLength = "65px";
  let wrapperLength = "70px";
  let shadowSize = "12px";
  let borderRadius = "8px";
  let fontSize = "10px";
  let flexGap = "8px";

  if (size === "medium") {
    iconLength = "90px";
    wrapperLength = "95px";
    shadowSize = "12px";
    borderRadius = "12px";
    fontSize = "12px";
    flexGap = "10px";
  } else if (size === "large") {
    iconLength = "120px";
    wrapperLength = "125px";
    shadowSize = "15px";
    borderRadius = "18px";
    fontSize = "12px";
    flexGap = "30px";
  }
  
   return (
    <S.ProductsSection>
      <S.ProductsTitle fontSize={"22px"}>
        Your Apps
      </S.ProductsTitle>
        <S.LandingIconsContainer gap={flexGap} justify={justify}>
          {purchasedProducts.length > 0 ? purchasedProducts?.map(p => {return generateProductIcon(p, selectItem, iconLength,wrapperLength,shadowSize,borderRadius,fontSize)}) : <div>You haven't purchased any apps yet.</div>}
        </S.LandingIconsContainer>
    </S.ProductsSection>
   )
}

function generateMoreSection(products,selectItem) {

  const moreProducts = products.filter(p=> !p.purchased && p.active && p.always_show).sort(productStackRank);
  if (moreProducts.length === 0) return (<></>);
  let iconLength = "65px";
  let wrapperLength = "70px";
  let shadowSize = "12px";
  let borderRadius = "8px";
  let fontSize = "10px";
  let flexGap = "8px";

  return (
    <S.ProductsSection>
      <S.ProductsTitle fontSize="18px">
        Additional Fleet Analytics Options
      </S.ProductsTitle>
        <S.LandingIconsContainer gap={flexGap} justify="center">
          {moreProducts?.map( p => {return generateProductIcon(p, selectItem,iconLength,wrapperLength,shadowSize,borderRadius,fontSize)})}
        </S.LandingIconsContainer>
    </S.ProductsSection>
  )
}

function LandingPage({products, dbDisplayName, vehiclesAnalyzed, tcoSavings,selectItem,currencySymbol,allProductsCount}) {
  return(
    <S.PageWrapper>
      {/* Don't display the landing page until all content from the database has loaded */}
      {(products.length > 0 && vehiclesAnalyzed !== null && tcoSavings !== null && dbDisplayName) ?
        <>
          <S.InfoBubbleContainer>
            {/* Don't display the vehicles analyzed bubble if there are no vehicles */}
            {(vehiclesAnalyzed > 0) && 
              <S.InfoBubble>
                <S.InfoBubbleTitle>
                  {dbDisplayName}
                </S.InfoBubbleTitle>
                <div><S.Bold>{vehiclesAnalyzed}</S.Bold> Vehicles Analyzed</div>
              </S.InfoBubble>
            }
            {/* Don't display the TCO savings bubble if the client hasn't purchased ezEV or if savings is invalid */}
            {products.some(p=>p.product === "ezev" && p.purchased) && (tcoSavings > 0) &&
              <S.InfoBubble>
                <S.InfoBubbleTitle>
                  TCO Savings
                </S.InfoBubbleTitle>
                <S.TcoValueWrapper>
                  <TriangleIcon input={tcoSavings}/>
                  <div>{FormatMonetaryValue(tcoSavings,currencySymbol)}</div>
                </S.TcoValueWrapper>
              </S.InfoBubble>
            }
          </S.InfoBubbleContainer>
          {generatePurchasedSection(products, selectItem, allProductsCount)}
          {generateMoreSection(products, selectItem)}
        </>
        :
        <Loading/>
      }
      <S.LandingFooter>
        Learn more about us at <S.Link href="https://www.sawatchlabs.com" target="_blank" rel="noreferrer">sawatchlabs.com.</S.Link>
        <br/>
        Need help? Contact <S.Link href="mailto:support@sawatchlabs.com" target="_blank" rel="noreferrer">support@sawatchlabs.com</S.Link> or call <S.Link href="tel:303-578-2465">303-578-2465</S.Link>.
      </S.LandingFooter>
    </S.PageWrapper>
  )
}



export default function LandingComponent(props) {
  const { onSelect, products, dbDisplayName,currencySymbol, vehiclesAnalyzed, tcoSavings} = props;

  const allProductsCount = products.filter(p => p.always_show && p.active).length; // Count of the total number of products we currently have available to clients, used in icon size calculation

  function selectItem(p) {
    p.purchased === true ? onSelect(p.product) : window.alert(`Please contact our team for a demo of ${p.display_name} or to add it to your dashboard.\n\nEmail: info@sawatchlabs.com\nPhone: (303) 578-2465`);
  }

  return(
    <LandingPage
      products={products}
      selectItem={selectItem}
      dbDisplayName={dbDisplayName}
      vehiclesAnalyzed={vehiclesAnalyzed}
      tcoSavings={tcoSavings}
      currencySymbol={currencySymbol}
      allProductsCount={allProductsCount}
    />
  );
}
