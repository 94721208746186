import React, {useState, useEffect, Suspense} from "react";
import * as S from '../../../styles/core-styles/AdminTools-styles';

const UpdateLocationMap= React.lazy(() => import('./UpdateLocationMap'));


export default function UpdateLocationModal(props) {
    const {setShowUpdateLocationModal, user, selectedLocation, updateLocationData} = props;

    const [nickname, setNickname] = useState('');
    const [address, setAddress] = useState('');
    const [locationType, setLocationType] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [newLatitude, setNewLatitude] = useState('');
    const [newLongitude, setNewLongitude] = useState('');


    useEffect(() => {
        setAddress(selectedLocation.address);
        setLatitude(selectedLocation.latitude)
        setLongitude(selectedLocation.longitude)
        setNickname(selectedLocation.nickname ? selectedLocation.nickname : '');
        setLocationType(selectedLocation.landuse ? selectedLocation.landuse : '');
    },[selectedLocation])

    function handleSubmit(e) {
        e.preventDefault();
        let data = {
            city: selectedLocation.city,
            region: selectedLocation.region,
            pkid: selectedLocation.pkid,
            nickname: nickname,
            address: address,
            landuse: locationType !== '' ? locationType : selectedLocation.landuse,
            longitude: newLongitude !== '' ? parseFloat(newLongitude) : longitude,
            latitude: newLatitude !== '' ? parseFloat(newLatitude) : latitude,
        }
        updateLocationData(data);
        setShowUpdateLocationModal(false);
    }

    return(
        <S.ModalDisplay>
        <S.ModalContainer>
            <S.ModalCloseButton onClick={() => setShowUpdateLocationModal(false)}>Close</S.ModalCloseButton>
            <S.ModalHeader>Edit Location</S.ModalHeader>
            <S.ModalForm onSubmit={(e) => handleSubmit(e)}>
            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>Location Nickname:</S.ModalFormInputLabel>
                  <S.ModalFormInput
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                    type='text'
                    />
            </S.ModalFormInputWrapper>
            
            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>Location Type:</S.ModalFormInputLabel>
                <S.ModalFormSelect
                  onChange={(e) => setLocationType(e.target.value)}
                  value={locationType}
                >
                <option>Unclassified</option>
                <option>Fleet</option>
                <option>Public</option>
                <option>Residential</option>
                <option>Workplace</option>
                </S.ModalFormSelect>
              </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>Update Address:</S.ModalFormInputLabel>
                  <S.ModalFormInput
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    type='text'
                />
            </S.ModalFormInputWrapper>
            <S.UpdateLocationMapContainer>
            <S.UpdateLocationMapText>Update Map Location</S.UpdateLocationMapText>
            <S.UpdateLocationMapSubText>Click on the map to select a new marker location for this address</S.UpdateLocationMapSubText>
                <Suspense fallback={<></>}>
                <UpdateLocationMap 
                    user={user} 
                    selectedLocation={selectedLocation} 
                    setNewLatitude={setNewLatitude}
                    setNewLongitude={setNewLongitude}
                />
                </Suspense>
            </S.UpdateLocationMapContainer>

            <S.ModalFormButtonWrapper>
              <S.ModalSaveButton type='submit'>Save</S.ModalSaveButton>
            </S.ModalFormButtonWrapper>
            </S.ModalForm>
        </S.ModalContainer>
      </S.ModalDisplay>
    )
}